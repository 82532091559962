import ReactGA from "react-ga";
import AppRoutes from "./routes/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  ReactGA.initialize("UA-210740501-1");

  return (
    <Router>
      <HelmetProvider>
        <AppRoutes />
        <ToastContainer pauseOnHover={true} autoClose={4000} />
      </HelmetProvider>
    </Router>
  );
}

export default App;
