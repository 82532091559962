import React from "react";
import { toast } from "react-toastify";
import { IMAGE_URL } from "config/Api";
import { QuantityButton } from "components/ui";
import ShowMoreText from "react-show-more-text";
import { XIcon } from "@heroicons/react/outline";
import useOutsideClick from "lib/hooks/useOutsideClick";
import { Addons, IngredientObject, ProductObject } from "types";

interface Props {
  onClose?: any;
  addCart?: any;
  selectedProduct: ProductObject | null;
}

const SingleProductView: React.FC<Props> = ({
  selectedProduct,
  onClose,
  addCart,
}) => {
  const ref = React.useRef<any>();
  const closeHandler = onClose;
  const addCartHandler = addCart;
  const [checkboxIngredient, setCheckboxIngredient] = React.useState<any[]>([]);
  const [radioIngredient, setRadioIngredient] = React.useState<any[]>([]);
  const [ingredientPriceForCheckbox, setIngredientPriceForCheckbox] =
    React.useState(0);
  const [ingredientPriceForRadio, setIngredientPriceForRadio] =
    React.useState(0);
  const [productQty, setProductQty] = React.useState<Number>(1);
  const [isProductViewOpen, setIsProductViewOpen] =
    React.useState<boolean>(false);

  useOutsideClick(ref, () => {
    if (isProductViewOpen) {
      closeHandler();
    }
  });

  const radioButton = (e: any, ing: IngredientObject, addOnsId: any) => {
    const body = {
      addons_id: addOnsId,
      _id: e.target.value,
      ingredient_name: ing?.ingredient_name,
      ingredient_price: ing?.ingredient_price,
    };
    const addonsId = radioIngredient.find((x) => x.addons_id === addOnsId);
    const addonsIndex = radioIngredient.findIndex(
      (x) => x.addons_id === addOnsId
    );
    if (addonsId?.addons_id === addOnsId) {
      radioIngredient.splice(addonsIndex, 1);
      setRadioIngredient([...radioIngredient, body]);
    } else {
      setRadioIngredient([...radioIngredient, body]);
    }
  };

  const checkboxButton = (e: any, ing: IngredientObject, list: Addons) => {
    if (e.target.checked) {
      if (checkboxIngredient.length == list.addon_item_selection_max) {
        toast.warn(
          `You can't add more then ${list.addon_item_selection_max} addons.`
        );
        return;
      }
      setCheckboxIngredient([
        ...checkboxIngredient,
        {
          _id: ing?._id,
          pos_addonsId: list?.pos_addonsId,
          ingredient_name: ing?.ingredient_name,
          group_name: list.addons_name,
          ingredient_price: ing?.ingredient_price,
          pos_addonItemId: ing.pos_addonItemId,
          quantity: "1",
        },
      ]);
    } else {
      setCheckboxIngredient(
        checkboxIngredient.filter((ingredient) => ingredient._id !== ing?._id)
      );
    }
  };

  const onProductQty = (qty: Number) => {
    setProductQty(qty);
  };

  React.useEffect(() => {
    const checkboxPrice = checkboxIngredient.reduce(
      (total, currentValue) =>
        (total = total + parseInt(currentValue.ingredient_price)),
      0
    );
    setIngredientPriceForCheckbox(checkboxPrice);

    const radioPrice = radioIngredient.reduce(
      (total, currentValue) =>
        (total = total + parseInt(currentValue.ingredient_price)),
      0
    );
    setIngredientPriceForRadio(radioPrice);
    setTimeout(() => {
      setIsProductViewOpen(true);
    }, 200);
  }, [checkboxIngredient, radioIngredient]);

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-20 h-full w-full bg-[#322f2fa6]">
        <div className="absolute bottom-0 right-0 h-5/6 w-full overflow-auto scrollbar-hide">
          <div className="mx-auto h-full w-full">
            <div
              // ref={ref}
              className="relative mx-auto h-full w-full rounded-tr-2xl rounded-tl-2xl bg-white scrollbar-hide sm:shadow-2xl md:w-[36rem] lg:w-[48rem]"
            >
              <div
                className="absolute right-2 top-2 cursor-pointer rounded-full bg-black/20 p-2 shadow-lg"
                onClick={() => closeHandler()}
              >
                <XIcon className="h-4 w-4 font-bold" />
              </div>
              <div className="h-full overflow-x-hidden overflow-y-scroll scrollbar-hide">
                <img
                  className="h-56 w-full rounded-tr-2xl rounded-tl-2xl object-cover"
                  src={`${IMAGE_URL}${selectedProduct?.image[0]}`}
                  alt={`${selectedProduct?.name}`}
                />
                <div className="rounded-tr-2xl rounded-tl-2xl p-4">
                  <div className="mb-5 border-b-2 border-gray/20 pb-4">
                    <h2 className="mb-1 text-xl font-bold">
                      {selectedProduct?.name}
                    </h2>
                    <h2>
                      <ShowMoreText
                        lines={3}
                        more="Show more"
                        less="Show less"
                        expanded={false}
                        className="text-sm content-css"
                        truncatedEndingComponent={"... "}
                      >
                        {selectedProduct?.description}
                      </ShowMoreText>
                    </h2>
                  </div>
                  <div className="mb-14">
                    {selectedProduct?.addons
                      ?.filter((filt) => filt.is_multiple === false)
                      .map((list, index) => {
                        return (
                          <div
                            key={index}
                            className="border-b-2 border-gray/20 pb-4"
                          >
                            <h2 className="my-3 text-xl font-bold">
                              {list?.addons_name}
                            </h2>
                            <>
                              {list?.ingredient?.map((ing, index2) => {
                                return (
                                  <div
                                    key={index2}
                                    className="flex items-center justify-between"
                                  >
                                    <label
                                      className="my-1 font-medium"
                                      htmlFor={`${ing?._id}`}
                                    >
                                      {ing?.ingredient_name}
                                    </label>
                                    <div className="flex items-center">
                                      <h3 className="my-1 font-medium">
                                        ₹ {ing?.ingredient_price}
                                      </h3>
                                      <input
                                        className="ml-3"
                                        type="radio"
                                        name={`ingredient_${index}`}
                                        id={`${ing?._id}`}
                                        value={`${ing?._id}`}
                                        onChange={(e) =>
                                          radioButton(e, ing, list._id)
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          </div>
                        );
                      })}
                    {selectedProduct?.addons
                      ?.filter((filt) => filt.is_multiple === true)
                      .map((list, index) => {
                        return (
                          <div
                            key={index}
                            className="border-b-2 border-gray/20 pb-4"
                          >
                            <h2 className="my-3 text-xl font-bold">
                              {list?.addons_name}
                            </h2>
                            <>
                              {list?.ingredient?.map((ing, index2) => {
                                return (
                                  <div
                                    key={index2}
                                    className="flex items-center justify-between"
                                  >
                                    <label
                                      className="my-1 font-medium"
                                      htmlFor={`${ing?._id}`}
                                    >
                                      {ing?.ingredient_name}
                                    </label>
                                    <div className="flex items-center">
                                      <h3 className="my-1 font-medium">
                                        ₹ {ing?.ingredient_price}
                                      </h3>
                                      <input
                                        className="ml-3"
                                        type="checkbox"
                                        name={`ingredient_${index}`}
                                        id={`${ing?._id}`}
                                        checked={checkboxIngredient.some(
                                          (fil) => fil._id === ing._id
                                        )}
                                        onChange={(e) =>
                                          checkboxButton(e, ing, list)
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="fixed bottom-0  w-full bg-white md:w-[36rem] lg:w-[48rem]">
                <div className="mx-auto my-2 flex w-11/12 justify-between">
                  <QuantityButton
                    small={false}
                    color="bg-cultured"
                    onChange={onProductQty}
                    qty={1}
                  />
                  {selectedProduct?.isInStock ? (
                    <button
                      className="w-fit rounded-md border-0 bg-gold px-4 font-bold capitalize"
                      onClick={() =>
                        addCartHandler(
                          selectedProduct,
                          radioIngredient,
                          checkboxIngredient,
                          productQty
                        )
                      }
                    >
                      add to cart ₹{" "}
                      {(ingredientPriceForRadio +
                        ingredientPriceForCheckbox +
                        Number(selectedProduct?.price)) *
                        Number(productQty)}
                    </button>
                  ) : (
                    <button className="w-fit rounded-md border-0 bg-gold px-4 font-bold capitalize">
                      Stock Out
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProductView;
