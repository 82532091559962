import React from "react";
import { Button } from "components/ui";
import { useNavigate } from "react-router-dom";
import OrderRejectModel from "./OrderRejectModel";
import OrderAcceptModel from "./OrderAcceptModel";
import { ShopOrderObject, ShopOrders } from "types";
import { EllipsisVerticalIcon } from "components/icons";
import { CheckIcon, PencilIcon, XIcon } from "@heroicons/react/outline";

interface Props {
  inDetail: boolean;
  selectOrder?: any;
  ordersList: ShopOrders | [];
}

const KotRequest: React.FC<Props> = ({ ordersList, inDetail, selectOrder }) => {
  let navigate = useNavigate();
  const selectOrderHandler = selectOrder;
  const [isOrderAccept, setIsOrderAccept] = React.useState<boolean>(false);
  const [isOrderReject, setIsOrderReject] = React.useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] =
    React.useState<ShopOrderObject | null>(null);

  //SET SELECTED ORDER IN STATE
  const onViewDetails = (list: ShopOrderObject) => {
    selectOrderHandler(list);
  };

  return (
    <>
      <h2 className="mb-4 hidden text-xl font-bold md:block">Order Request</h2>
      <div className="mb-10 w-full rounded border border-dashed border-black/10 bg-cultured p-3 shadow-xl">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {ordersList
            ?.filter((active) => !active?.orderAcceptStatus)
            ?.map((list, index) => {
              return (
                <div
                  key={index}
                  className="h-fit w-full select-none rounded-lg bg-white p-2 shadow"
                >
                  <div className="my-2 flex items-center justify-between rounded-lg bg-cultured p-2">
                    <h2 className="text-sm text-gray">
                      {list?.userName}({list?.userNumber})
                    </h2>
                    <p className="font-bold text-raisin-black">
                      {list?.tableNumber}
                    </p>
                  </div>
                  <div className="my-2 grid h-36 grid-rows-3 gap-2 rounded-lg bg-cultured p-2 font-medium">
                    {list.product.filter((status) => !status.isOrderAccept)?.slice(0, 2).map((list, index2) => {
                      return (
                        <div
                          key={index2}
                          className="h-fit border-b border-gray/30 py-1 text-sm"
                        >
                          <h3>
                            {list?.productQty}x {list?.productId?.name}
                          </h3>
                        </div>
                      );
                    })}
                    {list.product.length > 2 && (
                      <div className="h-fit py-1 text-right text-sm">
                        <p>+{list.product.length - 2} more</p>
                      </div>
                    )}
                  </div>
                  <div className="my-2 flex items-center justify-between rounded-lg bg-cultured p-2">
                    {inDetail ? (
                      <Button onClick={() => onViewDetails(list)}>
                        <PencilIcon className="h-6 w-6" />
                      </Button>
                    ) : (
                      <EllipsisVerticalIcon
                        className="h-6 w-6 cursor-pointer"
                        onClick={() => navigate(`/shop/order_request`)}
                      />
                    )}
                    <Button
                      onClick={() => {
                        setIsOrderReject(true);
                        setSelectedOrder(list);
                      }}
                    >
                      <XIcon className="h-6 w-6" />
                    </Button>
                    <Button
                      onClick={() => {
                        setIsOrderAccept(true);
                        setSelectedOrder(list);
                      }}
                    >
                      <CheckIcon className="h-6 w-6" />
                    </Button>
                    {!inDetail && (
                      <h2 className="font-bold">₹ {list?.totalPrice}</h2>
                    )}
                  </div>
                  {inDetail && (
                    <h2 className="pr-3 text-right font-bold">
                      Total ₹ {list?.totalPrice}
                    </h2>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      <OrderAcceptModel
        onOpen={isOrderAccept}
        orderDetails={selectedOrder}
        orderAccept={selectOrderHandler}
        onClose={() => setIsOrderAccept(false)}
      />
      <OrderRejectModel
        onOpen={isOrderReject}
        orderDetails={selectedOrder}
        onClose={() => setIsOrderReject(false)}
      />
    </>
  );
};

export default KotRequest;
