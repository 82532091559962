import React from "react";

export interface TextareaProps {
  className?: string;
  title: string;
  placeholder: string;
  value: string;
  onChange?: (...args: any[]) => any;
}

const Textarea: React.FC<TextareaProps> = (props) => {
  const { className, children, title, placeholder, value, onChange, ...rest } =
    props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };
  return (
    <div className="w-full rounded-md bg-cultured py-2.5 px-4">
      <p className="pb-1 text-left font-medium text-raisin-black">{title}</p>
      <textarea
        className="flex w-full justify-center rounded-md border-0 border-none bg-cultured p-0 text-base font-medium text-raisin-black placeholder:font-normal placeholder:text-philippine-gray focus:outline-none focus:ring-0 focus:ring-transparent"
        placeholder={placeholder}
        defaultValue={value}
        onChange={handleOnChange}
        {...rest}
      />
    </div>
  );
};

export default Textarea;
