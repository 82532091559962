import _ from "lodash";
import React from "react";
import ProductBox from "../product/ProductBox";
import BottonNavigation from "./BottonNavigation";
import { ProductObject, ShopProduct, StoreTableOblect } from "types";

interface Props {
  tableDeatils: StoreTableOblect | null;
  productsList: ShopProduct | [];
}

const ProductSelectionForOrder: React.FC<Props> = ({
  productsList,
  tableDeatils,
}) => {
  const [localCartData, setLocalCartData] = React.useState<any>(null);

  //USE LODASH FOR GROUP PRODUCTS IN CATEGORY WISE
  const ProductDisplay = _.groupBy(
    productsList
      ?.sort((a: any, b: any) =>
        a?.category_id?.priority > b?.category_id?.priority ? 1 : -1
      )
      ?.filter((active) => active.isActive),
    "category_id.name"
  );
  let addons_array = [] as any[];

  //FUNCTON FOR ADD PRODUCTS TO CART(LOCALSTORAGE)
  const onAddToCart = (
    list: ProductObject,
    ing_radio: any,
    ing_checkbox: any,
    productQty: Number
  ) => {
    //GET PRODUCTS FROM LOCALSTORAGE
    const jode_cart = JSON.parse(
      localStorage.getItem("jode_staff_caption") as any
    );

    // setOrderCartLocalCount(jode_cart?.product?.length || 0);
    //PUSH INGREDIENT IN ARRAY
    addons_array.push(...ing_checkbox);
    addons_array.push(...ing_radio);
    //SUM FOR INGREDIENT PRICE
    const totalAddonsPrice = addons_array.reduce(
      (total, currentValue) =>
        (total = total + parseInt(currentValue.ingredient_price)),
      0
    );
    //ORDER OBJECT BODY FIRST TIME
    const orderBody = {
      storeId: list?.store_id,
      userNumber: "",
      tableNumber: tableDeatils?.table_name,
      totalOrder: 1,
      totalPrice: (list?.price + totalAddonsPrice) * Number(productQty),
      orderType: "dine_in",
      product: [
        {
          productId: list,
          productQty: productQty,
          productPrice: list?.price,
          adonsPrice: totalAddonsPrice,
          totalPrice: (list?.price + totalAddonsPrice) * Number(productQty),
          addonsList: addons_array,
        },
      ],
    };
    //PRODUCT INDEX IF IT'S EXIST
    const productExistId = jode_cart?.product?.find(
      (x: any) => x?.productId?._id === list?._id
    );
    //CHECK IF CART EXISTS THE UPDATE OR ADD
    if (jode_cart === null) {
      //SET OBJECT IN LOCALSTORAGE
      localStorage.setItem("jode_staff_caption", JSON.stringify(orderBody));
      //setOrderCartLocalCount(orderBody?.product?.length || 0);
      setLocalCartData(orderBody);
    } else if (productExistId !== undefined) {
      //IF PRODUCT EXIST IN CART
      const addonsIndex = jode_cart?.product?.findIndex(
        (x: any) => x.productId._id === list._id
      );
      //ORDER OBJECT BODY
      const updateProduct = {
        productId: productExistId.productId,
        productQty: productExistId.productQty + productQty,
        productPrice: productExistId.productPrice,
        adonsPrice: totalAddonsPrice,
        totalPrice:
          (totalAddonsPrice + productExistId.productPrice) *
          (productExistId.productQty + productQty),
        addonsList: addons_array,
      };
      //ADD ORDER BODY IN LOCALSTORAGE
      jode_cart.product[addonsIndex] = updateProduct;
      //SUM UP PRODUCT PRICE
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      const updatOrder = {
        storeId: list?.store_id,
        userNumber: "",
        tableNumber: tableDeatils?.table_name,
        totalOrder: jode_cart?.totalOrder,
        totalPrice: totalPrice,
        orderType: "dine_in",
        product: [...jode_cart?.product],
      };
      //SET OBJECT IN LOCALSTORAGE WITH MULTIPLE PRODUCTS
      localStorage.setItem("jode_staff_caption", JSON.stringify(updatOrder));
      setLocalCartData(updatOrder);
    } else {
      //PRODUCT INCREMENT OBJECT
      const addProduct = {
        productId: list,
        productQty: productQty,
        productPrice: list?.price,
        adonsPrice: totalAddonsPrice,
        totalPrice: (list?.price + totalAddonsPrice) * Number(productQty),
        addonsList: addons_array,
      };
      //ORDER BODU INCREMENT OBJECT
      const orderBody2 = {
        storeId: list?.store_id,
        userNumber: "",
        tableNumber: tableDeatils?.table_name,
        totalOrder: jode_cart?.totalOrder + 1,
        totalPrice:
          jode_cart?.totalPrice +
          (list?.price + totalAddonsPrice) * Number(productQty),
        orderType: "dine_in",
        product: [...jode_cart?.product, addProduct],
      };
      //SET OBJECT IN LOCALSTORAGE WITH MULTIPLE PRODUCTS
      localStorage.setItem("jode_staff_caption", JSON.stringify(orderBody2));
      //setOrderCartLocalCount(orderBody2?.product?.length || 0);
      setLocalCartData(orderBody2);
    }
  };

  //UPDATE CART PRODUCT QUANTITY(LOCALSTORAGE)
  const onProductUpdateInCart = async (quantity: any, index: any) => {
    //GET CART PRODUCT FROM LOCALSTORAGE
    const jode_cart = JSON.parse(
      localStorage.getItem("jode_staff_caption") as any
    );
    if (quantity === 0) {
      jode_cart.product.splice(index, 1);
      //CALCUTATE TOTAL PRICE OF PRODUCT AFTER REMOVE
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      //UPDATE IN MAIN BODY
      const updateOrder = {
        storeId: jode_cart?.storeId,
        userNumber: "",
        tableNumber: jode_cart?.tableNumber,
        totalOrder: jode_cart?.totalOrder - 1,
        totalPrice: totalPrice,
        orderType: "dine_in",
        product: [...jode_cart?.product],
      };
      //SET IN LOCALSTORAGE
      localStorage.setItem("jode_staff_caption", JSON.stringify(updateOrder));
      //   setOrderCartLocalCount(updateOrder?.product?.length);
      //SET IN USESTATE FOR VIEW UPDATE
      //SEND FINAL ORDER IN STATE->
      setLocalCartData(updateOrder);
    } else {
      //FIND PRODUCT IN LOCALSTORAGE WITH INDEX
      const addonsIndex = jode_cart.product.findIndex(
        (x: any) => x.productId?._id === jode_cart.product[index].productId?._id
      );
      //CRATE UPDATED PRODUCT BODY WITH INDEX
      const addProduct = {
        productId: jode_cart.product[index].productId,
        productQty: quantity,
        productPrice: jode_cart.product[index].productPrice,
        adonsPrice: jode_cart.product[index].adonsPrice,
        totalPrice:
          (jode_cart.product[index].productPrice +
            jode_cart.product[index].adonsPrice) *
          quantity,
        addonsList: jode_cart.product[index].addonsList,
      };
      //SET UPDATED PRODUCT ON SAME INDEX
      jode_cart.product[addonsIndex] = addProduct;
      //CALCUTATE TOTAL PRICE OF PRODUCT
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      //UPDATE IN MAIN BODY
      const updateOrder = {
        storeId: jode_cart?.storeId,
        userNumber: "",
        tableNumber: jode_cart?.tableNumber,
        totalOrder: jode_cart?.totalOrder,
        totalPrice: totalPrice,
        orderType: "dine_in",
        product: [...jode_cart?.product],
      };
      //SET IN LOCALSTORAGE
      localStorage.setItem("jode_staff_caption", JSON.stringify(updateOrder));
      //SET IN USESTATE FOR VIEW UPDATE
      setLocalCartData(updateOrder);
    }
  };

  //INITIAL VALUE
  React.useEffect(() => {
    const jode_cart = JSON.parse(
      localStorage.getItem("jode_staff_caption") as any
    );
    setLocalCartData(jode_cart);
  }, []);

  return (
    <>
      {Object.keys(ProductDisplay)?.map((title, index) => {
        return (
          <div key={index} className="mb-10">
            <h3
              id={`${title}`}
              className="my-4 mt-6 text-xl font-bold uppercase"
            >
              {title}{" "}
              <span className="text-sm">({ProductDisplay[title]?.length})</span>
            </h3>
            <ul className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {ProductDisplay[title]
                ?.sort((a: any, b: any) => a?.name.localeCompare(b?.name))
                ?.map((items, index2) => {
                  return (
                    <li key={index2} className="cursor-pointer">
                      <ProductBox
                        cart={true}
                        key={index2}
                        icon={false}
                        product={items}
                        onLoading={false}
                        addProduct={onAddToCart}
                        quantityControl={onProductUpdateInCart}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        );
      })}
      <BottonNavigation localCartData={localCartData} />
    </>
  );
};

export default ProductSelectionForOrder;
