import React from "react";
import axios from "axios";
import moment from "moment";
import API from "config/Api";
import { toast } from "react-toastify";
import { Button } from "components/ui";
import { CheckIcon } from "@heroicons/react/solid";

interface Props {
  onModalOpen: any;
  storeDetails: any;
  onLoading: Boolean;
}

const PetpoojaIntegration: React.FC<Props> = ({
  onLoading,
  onModalOpen,
  storeDetails,
}) => {
  const openHandle = onModalOpen;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
  const [posAddedName, setPosAddedName] = React.useState<null | string>(null);

  const onFetchDetailFromPetpooja = () => {
    setIsLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const url = API + `fetchPetpooja`;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          toast.success("Successfully fetch details from petpooja account.");
        } else {
          toast.warn(`Error while fetch details from petpooja account.`);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onFetchDetailFromSquare = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const onDeletePosAccount = () => {
    setIsDeleteLoading(true);
    setTimeout(() => {
      setIsDeleteLoading(false);
    }, 3000);
  };

  React.useEffect(() => {
    if (storeDetails?.posIntegration_id?.petpooja_key?.access_token !== null) {
      setPosAddedName("Petpooja");
    } else if (
      storeDetails?.posIntegration_id?.square_key?.access_token !== null
    ) {
      setPosAddedName("Square");
    }
  }, [
    storeDetails?.posIntegration_id?.petpooja_key?.access_token,
    storeDetails?.posIntegration_id?.square_key?.access_token,
  ]);

  if (onLoading) {
    return (
      <>
        <div className="w-full animate-pulse">
          <div className="mb-5 h-fit w-full rounded-2xl bg-cultured p-5">
            <div className="mb-2 flex w-full justify-between">
              <div className="h-5 w-40 rounded bg-black/20" />
              <div className="h-5 w-32 rounded bg-black/20" />
            </div>
            <div className="my-7 w-full">
              <div className="my-2 h-5 w-32 rounded bg-black/20" />
              <div className="w-full rounded-full bg-black/20 p-0.5">
                <div className="h-3 w-2/3 rounded-full bg-black/50" />
              </div>
            </div>
            <div className="flex w-full justify-between">
              <div className="mr-5 mb-5 h-32 w-full space-y-1 rounded-md bg-black/20 p-3 md:mb-0 md:w-80 "></div>
              <div className="mb-5 h-32 w-full space-y-1 rounded-md bg-black/20 p-3 md:mb-0"></div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="mb-5 rounded-2xl bg-cultured p-5">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-xl font-bold capitalize">
              {posAddedName} Integration
            </h2>
          </div>
          <div className="flex items-center">
            <h2 className="mr-3 hidden text-gray/50 md:block">
              {moment(storeDetails?.createdAt).format("MMM Do YYYY")}
            </h2>
            <button
              className="w-fit select-none rounded-md border border-gold bg-gold py-2 px-5 font-medium"
              onClick={() => openHandle(true)}
            >
              Clarify Details
            </button>
          </div>
        </div>
        <div className="my-5 w-full">
          <div className="my-2">
            <h2 className="text-xl font-medium text-[#1190EE]">
              100% Complete
            </h2>
          </div>
          <div className="w-full rounded-full bg-white p-0.5">
            <div
              className="h-3 rounded-full bg-gold"
              style={{ width: `100%` }}
            ></div>
          </div>
        </div>
        <div className="justify-between md:flex">
          {posAddedName === "Petpooja" && (
            <div className="mr-5 mb-5 w-full space-y-1 rounded-md bg-white p-3 font-medium text-gray md:mb-0 md:w-80">
              <p>{posAddedName} Keys:</p>
              <div className="flex items-center">
                <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                <p className="text-black">app_key</p>
              </div>
              <div className="flex w-max items-center">
                <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                <p className="text-black">app_secret</p>
              </div>
              <div className="flex items-center">
                <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                <p className="text-black">access_token</p>
              </div>
              <div className="flex items-center">
                <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                <p className="text-black">restID</p>
              </div>
            </div>
          )}
          {posAddedName === "Square" && (
            <div className="mr-5 mb-5 w-full space-y-1 rounded-md bg-white p-3 font-medium text-gray md:mb-0 md:w-80">
              <p>{posAddedName} Keys:</p>
              <div className="flex items-center">
                <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                <p className="text-black">application_iD</p>
              </div>
              <div className="flex w-max items-center">
                <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                <p className="text-black">access_token</p>
              </div>
              <div className="flex items-center">
                <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                <p className="text-black">api_version</p>
              </div>
            </div>
          )}
          <div className="w-full rounded-md bg-white p-3 text-gray">
            <h2 className="text-2xl font-normal text-[#40AE2B]">
              Clarification completed
            </h2>
            <p className="">
              Your {posAddedName} Integration details clarification was
              conducted, and you got a mail with the {posAddedName} dashboard link.
              You can also visit by clicking the below button.
            </p>

            {posAddedName === "Petpooja" && (
              <div className="mt-4 flex items-center justify-between gap-10">
                <div className="flex gap-10">
                  <Button
                    onClick={() => window.open("https://billing.petpooja.com")}
                  >
                    View Account
                  </Button>
                  <Button
                    loading={isLoading}
                    onClick={() => onFetchDetailFromPetpooja()}
                  >
                    Fetch Petpooja
                  </Button>
                </div>
                <Button
                  loading={isDeleteLoading}
                  onClick={() => onDeletePosAccount()}
                >
                  Delete POS
                </Button>
              </div>
            )}
            {posAddedName === "Square" && (
              <div className="mt-4 flex items-center justify-between gap-10">
                <div className="flex gap-10">
                  <Button
                    onClick={() =>
                      window.open("https://squareup.com/dashboard")
                    }
                  >
                    View Account
                  </Button>
                  <Button
                    loading={isLoading}
                    onClick={() => onFetchDetailFromSquare()}
                  >
                    Fetch Square
                  </Button>
                </div>
                <Button
                  loading={isDeleteLoading}
                  onClick={() => onDeletePosAccount()}
                >
                  Delete POS
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default PetpoojaIntegration;
