import React from "react";
import { toast } from "react-toastify";
import { Separate } from "components/ui";
import { TableSelectionObject } from "types";
import { useNavigate } from "react-router-dom";
import ShopViewHeader from "components/shops/shopView/ShopViewHeader";
import OrderingType from "components/shops/tableSelection/OrderingType";
import PickupSelection from "components/shops/tableSelection/PickupSelection";
import DineInSelection from "components/shops/tableSelection/DineInSelection";
import DeliverySelection from "components/shops/tableSelection/DeliverySelection";

const TableSelection = () => {
  let navigate = useNavigate();
  const [isOrderType, setIsOrderType] = React.useState<boolean>(true);
  const [isPickup, setIsPickup] = React.useState<boolean>(false);
  const [isDineIn, setIsDineIn] = React.useState<boolean>(false);
  const [isDelivery, setIsDelivery] = React.useState<boolean>(false);
  const [tableSelectionlist, setTableSelectionlist] =
    React.useState<TableSelectionObject | null>(null);
  const closeHandler = null;

  const onSelectTableType = (list: TableSelectionObject) => {
    setTableSelectionlist(list);
    if (list?.type === "pickup") {
      setIsPickup(true);
      setIsDineIn(false);
      setIsDelivery(false);
      setIsOrderType(false);
    } else if (list?.type === "dine_in") {
      setIsDineIn(true);
      setIsPickup(false);
      setIsDelivery(false);
      setIsOrderType(false);
    } else if (list?.type === "delivery") {
      setIsDelivery(true);
      setIsPickup(false);
      setIsDineIn(false);
      setIsOrderType(false);
    }
  };

  const onSelectedTableNo = (tableNo: string) => {
    if (tableNo === null) {
      toast.warn("Please Select Table Number.");
    } else {
      const body = {
        tableNumber: tableNo,
        orderType: tableSelectionlist?.type,
      };
      localStorage.setItem("jod-e_table_selection", JSON.stringify(body));
      navigate(-1);
    }
  };

  const onPreviousButton = () => {
    setIsOrderType(true);
    setIsPickup(false);
    setIsDineIn(false);
    setIsDelivery(false);
  };

  return (
    <>
      <section className="fixed inset-0 mx-auto max-w-xl md:max-w-xl lg:max-w-5xl 2xl:max-w-7xl">
        <div className="mx-auto h-full overflow-auto bg-white px-3 scrollbar-hide sm:max-w-3xl sm:shadow-2xl">
          <ShopViewHeader search={false} back={true} toggle={true} />
          <div className="px-4">
            <div className="text-gray-900 pb-4 text-center text-xl font-medium leading-6">
              {isPickup
                ? "Pick Up"
                : isDineIn
                ? "Dine In"
                : isDelivery
                ? "Delivery"
                : "Order Type"}
            </div>
            <Separate dark={true} />
            {isOrderType && (
              <OrderingType onClose={closeHandler} onNext={onSelectTableType} />
            )}
            {isPickup && (
              <PickupSelection
                tableSelectionList={tableSelectionlist}
                onPrevious={onPreviousButton}
              />
            )}
            {isDineIn && (
              <DineInSelection
                tableSelectionList={tableSelectionlist}
                onPrevious={onPreviousButton}
                onSelectTable={onSelectedTableNo}
              />
            )}
            {isDelivery && (
              <DeliverySelection
                tableSelectionList={tableSelectionlist}
                onPrevious={onPreviousButton}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TableSelection;
