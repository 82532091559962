import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  type: string;
  title: string;
  placeholder: string;
  value: string;
  disable?: Boolean;
  white?: Boolean;
  onChange?: (...args: any[]) => any;
}

const InputStatus: React.FC<InputProps> = (props) => {
  const {
    className,
    type,
    children,
    title,
    placeholder,
    value,
    onChange,
    disable,
    white,
    ...rest
  } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };
  return (
    <div
      className={`${
        white
          ? "w-full flex items-center justify-between py-2.5 px-4 rounded-md bg-white mb-4"
          : "w-full flex items-center justify-between py-2.5 px-4 rounded-md bg-cultured mb-4"
      }`}
    >
      <p className="capitalize text-philippine-gray truncate">{title}</p>
      <input
        type={type}
        className={`${
          white
            ? "w-half flex items-center justify-center text-raisin-black text-base font-medium placeholder:text-raisin-black placeholder:font-normal focus:outline-none rounded-md bg-white text-right border-0 focus:ring-transparent"
            : "w-half flex items-center justify-center text-raisin-black text-base font-medium placeholder:text-raisin-black placeholder:font-normal focus:outline-none rounded-md bg-cultured text-right border-0 focus:ring-transparent"
        }`}
        placeholder={placeholder}
        defaultValue={value}
        value={value}
        disabled={disable === true ? true : false}
        onChange={handleOnChange}
        {...rest}
      />
    </div>
  );
};

export default InputStatus;
