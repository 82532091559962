import React from "react";
import {
  TrashIcon,
  BookmarkIcon,
  PencilAltIcon,
  BookmarkAltIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { StoreTableOblect } from "types";

interface Props {
  icon: boolean;
  onDelete?: any;
  onUpdate?: any;
  onActive?: any;
  onLoading: boolean;
  tableList: StoreTableOblect;
}

const TableBox: React.FC<Props> = ({
  onLoading,
  tableList,
  onDelete,
  onUpdate,
  onActive,
  icon,
}) => {
  const activeHandler = onActive;
  const updateHandler = onUpdate;
  const deleteHandler = onDelete;
  return (
    <>
      <div className="h-fit w-full select-none rounded-2xl bg-cultured p-5">
        <div className=" px-5 pb-3">
          <div className="flex justify-evenly">
            <div
              className={classNames(
                !tableList?.availability
                  ? "h-3 w-14 rounded-full bg-gold"
                  : "h-3 w-14 rounded-full bg-gray"
              )}
            ></div>
            <div
              className={classNames(
                !tableList?.availability
                  ? "h-3 w-14 rounded-full bg-gold"
                  : "h-3 w-14 rounded-full bg-gray"
              )}
            ></div>
          </div>
          <div className="flex justify-between">
            <div className="grid items-center">
              <div
                className={classNames(
                  !tableList?.availability
                    ? "h-9 w-3 rounded-full bg-gold"
                    : "h-9 w-3 rounded-full bg-gray"
                )}
              ></div>
              <div
                className={classNames(
                  !tableList?.availability
                    ? "h-9 w-3 rounded-full bg-gold"
                    : "h-9 w-3 rounded-full bg-gray"
                )}
              ></div>
            </div>
            <div
              className={classNames(
                !tableList?.availability
                  ? "m-2 mx-3 grid h-24 w-full items-center rounded-xl border-l-8 border-gold bg-white p-2 px-3 shadow-xl"
                  : "m-2 mx-3 grid h-24 w-full items-center rounded-xl border-l-8 border-gray bg-white p-2 px-3 shadow-xl"
              )}
            >
              <div className="flex items-baseline justify-between">
                <h2 className="mb-0 text-xl font-semibold">
                  {tableList?.table_name}
                </h2>
                {tableList?.storeOrderId !== null && (
                  <h2 className="mb-0 text-xl font-semibold">
                    ₹ {tableList?.storeOrderId?.totalPrice}
                  </h2>
                )}
              </div>
              <div className="flex items-baseline justify-between font-medium">
                <h3>{!tableList?.availability ? "Reserved" : "Free"}</h3>
                <h2 className="text-xs uppercase">
                  {tableList.table_location}
                </h2>
              </div>
            </div>
            <div className="grid items-center">
              <div
                className={classNames(
                  !tableList?.availability
                    ? "h-9 w-3 rounded-full bg-gold"
                    : "h-9 w-3 rounded-full bg-gray"
                )}
              ></div>
              <div
                className={classNames(
                  !tableList?.availability
                    ? "h-9 w-3 rounded-full bg-gold"
                    : "h-9 w-3 rounded-full bg-gray"
                )}
              ></div>
            </div>
          </div>
          <div className="flex justify-evenly">
            <div
              className={classNames(
                !tableList?.availability
                  ? "h-3 w-14 rounded-full bg-gold"
                  : "h-3 w-14 rounded-full bg-gray"
              )}
            ></div>
            <div
              className={classNames(
                !tableList?.availability
                  ? "h-3 w-14 rounded-full bg-gold"
                  : "h-3 w-14 rounded-full bg-gray"
              )}
            ></div>
          </div>
        </div>
        {icon && (
          <div className="flex justify-between border-t border-gray/20 px-5 pt-3 text-philippine-gray">
            {tableList?.availability ? (
              <>
                <div className="group relative">
                  <BookmarkIcon
                    className="mr-3 h-5 w-5 cursor-pointer"
                    onClick={() =>
                      activeHandler(tableList?._id, tableList?.availability)
                    }
                  />
                  <p className="absolute -top-6 -left-3 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                    Free
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="group relative">
                  <BookmarkAltIcon
                    className="mr-3 h-5 w-5 cursor-pointer"
                    onClick={() =>
                      activeHandler(tableList?._id, tableList?.availability)
                    }
                  />
                  <p className="absolute -top-6 -left-5 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                    Reserved
                  </p>
                </div>
              </>
            )}
            <div className="group relative">
              <PencilAltIcon
                className="mr-3 h-5 w-5 cursor-pointer"
                onClick={() => updateHandler(tableList)}
              />
              <p className="absolute -top-6 -left-4 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                Update
              </p>
            </div>
            <div className="group relative">
              <TrashIcon
                className="mr-3 h-5 w-5 cursor-pointer"
                onClick={() => deleteHandler(tableList?._id)}
              />
              <p className="absolute -top-6 -left-4 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                Delete
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TableBox;
