import React from "react";
import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import { ShopOrderObject, ShopType } from "types";
import { CheckIcon } from "@heroicons/react/outline";
import Razorpay from "components/shops/payment/Razorpay";
import ShopViewHeader from "components/shops/shopView/ShopViewHeader";
import OrderDisplayBox from "components/shops/shopView/OrderDisplayBox";
import { Button } from "components/ui";

const amountCalculation = (initalPrice: string, handling: number) => {
  // IA =  INITAL AMOUNT FROM API
  //T = TAX AMOUNT (5%)
  //TAWX = TOAL AMOUNT WITH TAX
  //HC = HANDLING CHARGE (0.50%)
  //HT = HANDLING TAX
  //HCG = HANDLING CHARGE GST(18%)
  //FHC = FINAL HANDLING CHARGE

  const IA = parseFloat(initalPrice);
  const T = (IA * 5) / 100;
  const TAWX = IA + T;
  const HC = handling;
  const HT = TAWX * (HC / 100);
  const HCG = (HT * 18) / 100;
  const FHC = HT + HCG;

  return (TAWX + FHC).toFixed(2);
};

const ViewOrders = () => {
  const [ordersDetails, setOrdersDetails] =
    React.useState<ShopOrderObject | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cartUser, setCartUser] = React.useState(null);
  const [storeDetails, setStoreDetails] = React.useState<ShopType | null>(null);
  const [totalPayAmount, setTotalPayAmount] = React.useState<Number | 0>(0);
  const [EstimatesTax, setEstimatesTax] = React.useState<number>(0);
  const [promoCode, setPromoCode] = React.useState("");
  const [promoCodeDeatils, setpromoCodeDeatils] = React.useState<any>(null);
  const [promoId, setPromoId] = React.useState(null);
  const [isPromoInRupee, setIsPromoInRupee] = React.useState(true);

  const fetchOrderDetails = React.useCallback(() => {
    setIsLoading(true);
    const jode_cart_user = JSON.parse(
      localStorage.getItem("jod-e_shop_cart_user") as any
    );
    setCartUser(jode_cart_user);
    const config = {
      params: {
        userNumber: jode_cart_user?.userNumber,
      },
    };
    const url = API + `getorders`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setOrdersDetails(response?.data?.data);
          setStoreDetails(response?.data?.data?.storeId);
          setEstimatesTax(parseFloat("0.50")); //response?.data?.data?.storeId?.commission_value));
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onPromoCodeCheck = () => {
    if (promoCode === "") {
      toast.warn("Please Enter Promo Code Before Applying.");
    } else {
      const body = {
        storeId: storeDetails?._id,
        promoId: "",
        promoCode: promoCode,
        price: (parseFloat(ordersDetails?.totalPrice) + EstimatesTax).toFixed(
          2
        ),
      };
      const authData = JSON.parse(localStorage.getItem("jd-u")!);
      const config = {
        headers: {
          Authorization: `Bearer ${authData?.token}`,
        },
      };
      let url = API + `promoShop`;
      axios
        .post(url, body, config)
        .then((response) => {
          if (response?.data?.success) {
            setPromoId(response?.data?.data?.promoId);
            setTotalPayAmount(response?.data?.data?.finalDiscount);
            setpromoCodeDeatils(response?.data?.data?.promoPrice);
            setIsPromoInRupee(response?.data?.data?.isInRupee);
          } else {
            toast.warn(`Coupon Code Is Invalid.`);
            setPromoId(null);
          }
        })
        .catch(function (error) {
          console.log("Error", error.message);
        });
    }
  };

  React.useEffect(() => {
    fetchOrderDetails();
    const e = amountCalculation(ordersDetails?.totalPrice, EstimatesTax);
    setTotalPayAmount(Number(e));
  }, [EstimatesTax, fetchOrderDetails, ordersDetails?.totalPrice]);

  return (
    <>
      <section className="fixed inset-0 mx-auto max-w-xl md:max-w-xl lg:max-w-5xl 2xl:max-w-7xl">
        <div className="relative mx-auto h-full overflow-auto bg-white px-3 scrollbar-hide sm:max-w-3xl sm:shadow-2xl">
          <ShopViewHeader search={false} back={true} toggle={true} />
          {ordersDetails === null && (
            <div>
              <img
                className="mx-auto"
                src="/assets/shop/bag_empty.png"
                alt="bag_empty"
              />
              <div className="text-center capitalize">
                <h2 className="mb-2 text-2xl font-bold">Your bag is empty</h2>
                <p>
                  Looks like you haven’t made <br /> your choice yet
                </p>
              </div>
            </div>
          )}
          <OrderDisplayBox
            ordersDetails={ordersDetails}
            onLoading={isLoading}
          />
        </div>
        {ordersDetails !== null && (
          <div className="mx-auto h-fit w-full md:w-[36rem] lg:w-[48rem]">
            <div className="mx-auto h-fit w-[48rem] rounded-tr-2xl rounded-tl-2xl bg-white scrollbar-hide sm:shadow-2xl">
              <div className="absolute bottom-0 h-fit w-full rounded-tr-2xl rounded-tl-2xl bg-cultured p-3 md:w-[36rem] lg:w-[48rem]">
                <div className="flex items-center justify-between rounded-md border-2 border-dashed border-gray/50 pr-0.5">
                  <input
                    type="text"
                    className="w-full border-0 bg-transparent focus:outline-none focus:outline-0"
                    name="promo_code_shop"
                    id="promo_code_shop"
                    placeholder="Apply Promo code"
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                  <button
                    className="rounded-md border-0 bg-gold p-2 px-4 font-bold"
                    onClick={onPromoCodeCheck}
                  >
                    <CheckIcon className="h-5 w-5" />
                  </button>
                </div>
                <div className="my-3 flex items-center justify-between border-t border-gray/50 pt-3 text-xs font-medium text-gray">
                  <div className="space-y-1">
                    <h2>Subtotal</h2>
                    <h2>Tax (CGST + SGST)</h2>
                    <h2>Handling Charges</h2>
                    {promoCodeDeatils !== null && <h2>Promo Code</h2>}
                    <h2 className="text-sm text-black">To Pay</h2>
                  </div>
                  <div className="space-y-1 text-right">
                    <h2>₹ {ordersDetails?.totalPrice || "00.00"}</h2>
                    <h2>5 %</h2>
                    <h2>{EstimatesTax.toFixed(2) || "00.00"}% + 18%</h2>
                    {promoCodeDeatils !== null && (
                      <h2 className="text-[#FF0000]">
                        -{promoCodeDeatils.toFixed(2)}{" "}
                        {isPromoInRupee ? "₹" : "%"}
                      </h2>
                    )}
                    <h2 className="text-sm text-black">
                      ₹ {totalPayAmount || "00.00"}
                    </h2>
                  </div>
                </div>
                <div>
                  <Button width={"100%"} loading={false}>
                    PAY ON DESK
                  </Button>
                  {/* <Razorpay
                    promo={promoId}
                    tax={EstimatesTax}
                    userData={cartUser}
                    price={totalPayAmount}
                    shopData={storeDetails}
                    orderId={ordersDetails?._id}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ViewOrders;
