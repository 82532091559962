import React from "react";

const MessageIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M65.034 77.8916C48.2705 79.6808 31.4562 79.6808 14.6927 77.8916C8.00831 77.1782 2.72864 71.8985 2.01523 65.2136C0.226042 48.4501 0.226042 31.6363 2.01523 14.8728C2.72864 8.18848 8.00831 2.90881 14.6927 2.19541C31.4562 0.406218 48.2699 0.406218 65.0334 2.19541C71.7184 2.90881 76.998 8.18848 77.7114 14.8728C79.5006 31.6363 79.5006 48.4501 77.7114 65.2136C76.998 71.8985 71.719 77.1782 65.034 77.8916Z"
        fill="url(#paint0_linear_467_8837)"
      />
      <path
        d="M39.8635 17.2417C33.2493 17.2417 26.906 19.5112 22.229 23.5509C17.552 27.5905 14.9245 33.0695 14.9244 38.7824C14.9305 42.4984 16.0493 46.1499 18.1723 49.3819C20.2953 52.6139 23.3503 55.3167 27.0402 57.2274C26.0574 59.5089 24.5835 61.6483 22.6794 63.5572C26.3719 62.885 29.8381 61.4729 32.8082 59.4309C35.0984 60.0187 37.4744 60.3192 39.8635 60.3232C46.4778 60.3231 52.8211 58.0537 57.4981 54.014C62.1751 49.9743 64.8026 44.4954 64.8026 38.7824C64.8026 33.0695 62.1751 27.5905 57.4981 23.5509C52.8211 19.5112 46.4778 17.2417 39.8635 17.2417Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_467_8837"
          x1="40.4854"
          y1="74.0306"
          x2="40.4854"
          y2="10.5808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0CBD2A" />
          <stop offset="1" stopColor="#5BF675" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MessageIcon;
