import React from "react";
import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Separate } from "components/ui";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  isCoupon: boolean;
  onCoupon: any;
  onNewCoupon: any;
}

const CouponModel: React.FC<Props> = ({ isCoupon, onCoupon, onNewCoupon }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRupee, setIsRupee] = React.useState<any>(false);
  const onCloseCoupon = onCoupon;
  const onNewCouponAdded = onNewCoupon;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onIsRupeeHandel = (e: boolean) => {
    setIsRupee(e);
  };

  const onSubmit = handleSubmit((data: any) => {
    setIsLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const body = {
      storeId: authData?.storeId,
      promoCode: data?.coupon_name,
      promoAmount: parseFloat(data?.coupon_amount),
      promoAttempt: parseFloat(data?.coupon_attempted),
      type: "SHOP",
      isInRupee: isRupee,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `promo/create`;
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success) {
          reset();
          toast.success(`Copuon Created Successfully.`);
          onNewCouponAdded(!isCoupon);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return (
    <>
      <Transition appear show={isCoupon} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => onCloseCoupon(!isCoupon)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 pb-4 text-center text-xl font-medium leading-6"
                  >
                    Add Coupon
                  </Dialog.Title>
                  <Separate dark={true} />

                  <div className="p-5 md:px-10">
                    <form onSubmit={onSubmit}>
                      <div className="grid grid-cols-1 space-y-4 md:grid-cols-2">
                        <div className="grid md:mx-5">
                          <label
                            className="text-md font-bold"
                            htmlFor="coupon_name"
                          >
                            Coupon Name
                          </label>
                          <input
                            className="mt-2 rounded-md border-0 bg-cultured"
                            type="text"
                            id="coupon_name"
                            placeholder="Coupon Name"
                            {...register("coupon_name", { required: true })}
                          />
                          {errors.coupon_name && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>

                        <div className="grid md:mx-5">
                          <label
                            className="text-md font-bold"
                            htmlFor="coupon_attempted"
                          >
                            No of limit
                          </label>
                          <input
                            className="mt-2 rounded-md border-0 bg-cultured"
                            type="number"
                            id="coupon_attempted"
                            placeholder="Coupon Attempted"
                            {...register("coupon_attempted", {
                              required: true,
                            })}
                          />
                          {errors.coupon_attempted && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>

                        <div className="grid md:mx-5">
                          <label
                            className="text-md font-bold"
                            htmlFor="coupon_amount"
                          >
                            Discount Amount
                          </label>
                          <input
                            className="mt-2 rounded-md border-0 bg-cultured"
                            type="number"
                            id="coupon_amount"
                            placeholder="Coupon Amount"
                            {...register("coupon_amount", { required: true })}
                          />
                          {errors.coupon_amount && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>
                        <div>
                          <div className="flex md:mx-5">
                            <div className="mr-5 grid">
                              <label
                                className="text-md font-bold"
                                htmlFor="coupon_rupee"
                              >
                                ₹
                              </label>
                              <input
                                className="mt-2 h-9 w-9 rounded-md border-0 bg-cultured"
                                type="radio"
                                id="coupon_rupee"
                                placeholder="Coupon ₹"
                                checked={isRupee === true ? true : false}
                                onChange={(e) =>
                                  onIsRupeeHandel(e.target.checked)
                                }
                              />
                            </div>
                            <div className="grid">
                              <label
                                className="text-md font-bold"
                                htmlFor="coupon_per"
                              >
                                %
                              </label>
                              <input
                                className="mt-2 h-9 w-9 rounded-md border-0 bg-cultured"
                                type="radio"
                                id="coupon_per"
                                placeholder="Coupon %"
                                checked={isRupee === false ? true : false}
                                // value={isRupee === false ? "true" : "false"}
                                onChange={(e) =>
                                  onIsRupeeHandel(!e.target.checked)
                                }
                              />
                            </div>
                          </div>
                          {errors.isInRupee && (
                            <p className="flex justify-end text-sm text-[#CC3300]">
                              This field is required.
                            </p>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="mx-auto mt-10 grid max-w-xs grid-cols-2">
                    <Button
                      variant="outlined"
                      onClick={() => onCloseCoupon(!isCoupon)}
                    >
                      Cancel
                    </Button>
                    <Button loading={isLoading} onClick={onSubmit}>
                      Apply
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CouponModel;
