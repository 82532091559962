import React from "react";

const YoutubeIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M65.034 77.9873C48.2705 79.7765 31.4562 79.7765 14.6927 77.9873C8.00831 77.2739 2.72864 71.9942 2.01523 65.3093C0.226042 48.5458 0.226042 31.732 2.01523 14.9685C2.72864 8.28419 8.00831 3.00452 14.6927 2.29111C31.4562 0.501921 48.2699 0.501921 65.0334 2.29111C71.7184 3.00452 76.998 8.28419 77.7114 14.9685C79.5006 31.732 79.5006 48.5458 77.7114 65.3093C76.998 71.9942 71.719 77.2739 65.034 77.9873Z"
        fill="white"
      />
      <path
        d="M64.6505 28.7287C64.3528 27.6287 63.7722 26.626 62.9665 25.8202C62.1608 25.0145 61.158 24.4339 60.0581 24.1363C56.0314 23.0381 39.8249 23.0381 39.8249 23.0381C39.8249 23.0381 23.6184 23.0714 19.5917 24.1695C18.4918 24.4672 17.4891 25.0478 16.6833 25.8535C15.8776 26.6593 15.297 27.662 14.9993 28.7619C13.7814 35.9168 13.3088 46.8187 15.0326 53.6873C15.3303 54.7873 15.9109 55.79 16.7166 56.5958C17.5223 57.4015 18.5251 57.9821 19.625 58.2797C23.6517 59.3779 39.8582 59.3779 39.8582 59.3779C39.8582 59.3779 56.0647 59.3779 60.0913 58.2797C61.1913 57.9821 62.194 57.4015 62.9998 56.5958C63.8055 55.79 64.3861 54.7873 64.6837 53.6873C65.9683 46.5225 66.3643 35.6272 64.6505 28.7287Z"
        fill="#FF0000"
      />
      <path
        d="M34.667 48.9946L48.1114 41.2075L34.667 33.4204V48.9946Z"
        fill="white"
      />
    </svg>
  );
};

export default YoutubeIcon;
