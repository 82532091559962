import React from "react";
import { Button, Separate } from "components/ui";
import { Dialog, Transition } from "@headlessui/react";
import ProductOrderBox from "./ProductOrderBox";

interface Props {
  onOpen: any;
  onClose: any;
  productList: any;
}

const OrderProductListModel = (props: Props) => {
  const closeHandler = props.onClose;

  return (
    <>
      <Transition appear show={props.onOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeHandler}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 pb-4 text-center text-xl font-medium leading-6"
                  >
                    Ordered Products
                  </Dialog.Title>
                  <Separate dark={true} />

                  <div className="my-10">
                    {props.productList?.map((orderList: any, index: number) => {
                      return (
                        <span key={index}>
                          <ProductOrderBox
                            orderProduct={orderList}
                            sequence={index}
                          />
                        </span>
                      );
                    })}
                  </div>

                  <div className="flex items-center justify-center gap-5">
                    <Button variant="outlined" onClick={closeHandler}>
                      Close
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default OrderProductListModel;
