import React from "react";

const FacebookIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M65.1859 77.2933C48.4224 79.0664 31.608 79.0664 14.8445 77.2933C8.16016 76.5863 2.88049 71.3542 2.16709 64.7295C0.377897 48.117 0.377897 31.4547 2.16709 14.8422C2.88049 8.21811 8.16016 2.986 14.8445 2.27902C31.608 0.50595 48.4218 0.50595 65.1853 2.27902C71.8702 2.986 77.1499 8.21811 77.8633 14.8422C79.6525 31.4547 79.6525 48.117 77.8633 64.7295C77.1499 71.3542 71.8708 76.5863 65.1859 77.2933Z"
        fill="url(#paint0_linear_467_8769)"
      />
      <path
        d="M53.6354 53.7549L55.2586 43.3533H45.0965V36.6043C45.0965 33.761 46.5136 30.9836 51.0691 30.9836H55.6915V22.13C55.6915 22.13 51.4968 21.4268 47.4876 21.4268C39.1188 21.4268 33.6461 26.405 33.6461 35.4255V43.3533H24.3395V53.7549H33.6461V78.894C35.5116 79.1824 37.4234 79.3291 39.3713 79.3291C41.3192 79.3291 43.2311 79.1773 45.0965 78.894V53.7549H53.6354Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_467_8769"
          x1="40.0152"
          y1="0.949219"
          x2="40.0152"
          y2="78.6231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18AEFF" />
          <stop offset="1" stopColor="#0165E2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
