import React from "react";
import {
  DocumentAddIcon,
  DocumentRemoveIcon,
  EyeIcon,
  EyeOffIcon,
  PencilAltIcon,
  ShoppingCartIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import ShowMoreText from "react-show-more-text";
import { IngredientObject, ProductObject } from "types";
import { QuantityButton } from "components/ui";
import ProductAddonModel from "../orderCreate/ProductAddonModel";
import { ChevronUpDownIcon } from "components/icons";
import { IMAGE_URL } from "config/Api";

interface Props {
  product: ProductObject;
  onDelete?: any;
  onUpdate?: any;
  onActive?: any;
  onStock?: any;
  onLoading?: Boolean | false;
  icon: Boolean;
  cart: Boolean;
  addProduct?: any;
  quantityControl?: any;
}

const ProductBox: React.FC<Props> = ({
  product,
  onDelete,
  onUpdate,
  onActive,
  onStock,
  onLoading,
  icon,
  cart,
  addProduct,
  quantityControl,
}) => {
  const [imageLoading, setImageLoading] = React.useState(true);
  const deleteHandler = onDelete;
  const updateHandler = onUpdate;
  const acticeHandler = onActive;
  const stockHandler = onStock;
  //CART
  const addCartHandler = addProduct;
  const [radioIngredient, setRadioIngredient] = React.useState<any[]>([]);
  const [checkboxIngredient, setCheckboxIngredient] = React.useState<any[]>([]);
  const captionOrder = JSON.parse(
    localStorage.getItem("jode_staff_caption") as any
  );
  const quantityHandler = quantityControl;
  const [isProductAddons, setIsProductAddons] = React.useState(false);
  const [selectedProduct, setSelectedProduct] =
    React.useState<ProductObject | null>(null);

  const onAddProductInLocalCart = (product: ProductObject) => {
    addCartHandler(product, radioIngredient, checkboxIngredient, 1);
    if (product?.addons?.length) {
      setSelectedProduct(product);
      setIsProductAddons(true);
    }
  };

  const onRadioButton = (e: any, ing: IngredientObject, addOnsId: any) => {
    const body = {
      addons_id: addOnsId,
      _id: e.target.value,
      ingredient_name: ing?.ingredient_name,
      ingredient_price: ing?.ingredient_price,
    };
    const addonsId = radioIngredient.find((x) => x.addons_id === addOnsId);
    const addonsIndex = radioIngredient.findIndex(
      (x) => x.addons_id === addOnsId
    );
    if (addonsId?.addons_id === addOnsId) {
      radioIngredient.splice(addonsIndex, 1);
      setRadioIngredient([...radioIngredient, body]);
    } else {
      setRadioIngredient([...radioIngredient, body]);
    }
  };

  const onCheckboxButton = (e: any, ing: IngredientObject) => {
    if (e.target.checked) {
      setCheckboxIngredient([
        ...checkboxIngredient,
        {
          _id: ing?._id,
          ingredient_name: ing?.ingredient_name,
          ingredient_price: ing?.ingredient_price,
        },
      ]);
    } else {
      setCheckboxIngredient(
        checkboxIngredient.filter((ingredient) => ingredient._id !== ing?._id)
      );
    }
  };

  const onUpdateAddedProductWithAddons = () => {
    addCartHandler(selectedProduct, radioIngredient, checkboxIngredient, 0);
    setTimeout(() => {
      setRadioIngredient([]);
      setCheckboxIngredient([]);
    }, 100);
  };

  if (onLoading) {
    return (
      <>
        <div className="w-full animate-pulse">
          <div className="h-fit xl:w-80 2xl:w-96"></div>
          <div className="flex h-44 w-full rounded-2xl bg-cultured p-2">
            <div className="mr-4 h-auto w-32">
              <div className="h-full w-full rounded-xl bg-black/20 object-cover" />
            </div>
            <div className="mt-4 flex-1 space-y-5">
              <div className="h-2 w-2/4 rounded bg-black/20"></div>
              <div className="space-y-3">
                <div className="grid space-y-1">
                  <div className="h-2 rounded bg-black/20"></div>
                  <div className="h-2 w-11/12 rounded bg-black/20"></div>
                  <div className="h-2 rounded bg-black/20"></div>
                  <div className="h-2 w-11/12 rounded bg-black/20"></div>
                  <div className="h-2 w-2/3 rounded bg-black/20"></div>
                </div>
              </div>
              <div className="grid grid-cols-4 space-x-3">
                <div className="h-5 w-5  rounded bg-black/20"></div>
                <div className="h-5 w-5  rounded bg-black/20"></div>
                <div className="h-5 w-5  rounded bg-black/20"></div>
                <div className="h-5 w-5  rounded bg-black/20"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="h-fit rounded-2xl bg-cultured lg:w-full">
          <div className="flex h-48 w-full p-2">
            <div
              className={`${
                imageLoading ? `block h-auto w-52 animate-pulse` : `hidden`
              }`}
            >
              <div className="h-full w-full rounded-xl bg-black/20 object-cover" />
            </div>
            <div className={`${imageLoading ? `hidden` : `block h-auto w-52`}`}>
              <img
                className="h-full w-full rounded-xl object-cover"
                src={`${IMAGE_URL}${product?.image[0]}`}
                alt={`${product?.name}`}
                onLoad={() => setImageLoading(false)}
              />
            </div>
            <div className="ml-3 grid w-full">
              <h2 className="text-xl font-bold capitalize">
                <ShowMoreText
                  lines={2}
                  more=""
                  less=""
                  className=""
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {product?.name}
                </ShowMoreText>
              </h2>
              {!cart && (
                <div className="mb-1 text-sm text-[#9D9D9D]">
                  <ShowMoreText
                    lines={3}
                    more=""
                    less=""
                    className="text-sm"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    {product?.description}
                  </ShowMoreText>
                </div>
              )}

              <div className="mb-2 flex items-center">
                {product?.isVeg ? (
                  <img
                    className="h-6 w-6"
                    src="https://img.icons8.com/color/48/000000/vegetarian-food-symbol.png"
                    alt="non-veg"
                  />
                ) : (
                  <img
                    className="h-6 w-6"
                    src="https://img.icons8.com/color/48/000000/non-vegetarian-food-symbol.png"
                    alt="veg"
                  />
                )}
                <p className="ml-2 text-xl font-bold">₹ {product?.price}</p>
              </div>
              {icon && (
                <div className="flex justify-between text-philippine-gray">
                  {product?.isActive ? (
                    <>
                      <div className="group relative">
                        <EyeIcon
                          className="mr-3 h-5 w-5 cursor-pointer"
                          onClick={() =>
                            acticeHandler(product?._id, product?.isActive)
                          }
                        />
                        <p className="absolute -top-6 -left-3 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                          Active
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="group relative">
                        <EyeOffIcon
                          className="mr-3 h-5 w-5 cursor-pointer"
                          onClick={() =>
                            acticeHandler(product?._id, product?.isActive)
                          }
                        />
                        <p className="absolute -top-6 -left-3 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                          Inactive
                        </p>
                      </div>
                    </>
                  )}
                  <div className="group relative">
                    <PencilAltIcon
                      className="mr-3 h-5 w-5 cursor-pointer"
                      onClick={() => updateHandler(product?._id)}
                    />
                    <p className="absolute -top-6 -left-4 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                      Update
                    </p>
                  </div>
                  <div className="group relative">
                    <TrashIcon
                      className="mr-3 h-5 w-5 cursor-pointer	"
                      onClick={() => deleteHandler(product?._id)}
                    />
                    <p className="absolute -top-6 -left-4 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                      Delete
                    </p>
                  </div>
                  {product?.isInStock ? (
                    <>
                      <div className="group relative">
                        <DocumentAddIcon
                          className="mr-3 h-5 w-5 cursor-pointer"
                          onClick={() =>
                            stockHandler(product?._id, product?.isInStock)
                          }
                        />
                        <p className="absolute -top-6 -left-6 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                          Stock_In
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="group relative">
                        <DocumentRemoveIcon
                          className="mr-3 h-5 w-5 cursor-pointer"
                          onClick={() =>
                            stockHandler(product?._id, product?.isInStock)
                          }
                        />
                        <p className="absolute -top-6 -left-6 z-10 hidden rounded-sm bg-gray/50 px-2 py-0.5 text-sm font-medium text-black group-hover:block">
                          Stock_Out
                        </p>
                      </div>
                    </>
                  )}
                </div>
              )}
              {cart && (
                <>
                  {captionOrder?.product.some(
                    (nItem: any) => nItem?.productId?._id === product?._id
                  ) ? (
                    <>
                      <div
                        onClick={() => {
                          setSelectedProduct(product);
                          setIsProductAddons(true);
                        }}
                      >
                        <h3 className="flex items-center gap-1 text-sm text-black/50">
                          Addons <ChevronUpDownIcon className="h-3 w-3" />{" "}
                        </h3>
                      </div>
                      <QuantityButton
                        small={true}
                        color="bg-white full-h-w"
                        qty={
                          captionOrder?.product?.find(
                            (nItem: any) =>
                              nItem?.productId?._id === product?._id
                          )?.productQty
                        }
                        onChange={(e) =>
                          quantityHandler(
                            e,
                            captionOrder?.product?.findIndex(
                              (nItem: any) =>
                                nItem?.productId?._id === product?._id
                            )
                          )
                        }
                      />
                    </>
                  ) : (
                    <>
                      {product?.isInStock ? (
                        <button
                          className="flex h-full w-full items-center justify-between rounded-md border-0 bg-gold px-5 py-2 font-bold capitalize"
                          onClick={() => onAddProductInLocalCart(product)}
                        >
                          <ShoppingCartIcon className="mr-2 h-5 w-5 text-black" />
                          <p>₹ {product?.price}</p>
                        </button>
                      ) : (
                        <button className="h-full w-full rounded-md border-0 bg-gold px-5 py-2 font-bold capitalize">
                          Stock Out
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <ProductAddonModel
          onOpen={isProductAddons}
          product={selectedProduct}
          radioButton={onRadioButton}
          checkboxButton={onCheckboxButton}
          onClose={() => setIsProductAddons(false)}
          updateWithAddons={onUpdateAddedProductWithAddons}
        />
      </>
    );
  }
};

export default ProductBox;
