import * as React from "react";
import { SetStateAction } from "react";
import { IUserSignIn, UserLinks, userDetailType } from "types/index";

interface CtxState {
  signInView: boolean;
  setSignInView: React.Dispatch<React.SetStateAction<boolean>>;
  otpView: boolean;
  setOtpInView: React.Dispatch<React.SetStateAction<boolean>>;
  signUpView: boolean;
  setSignUpView: React.Dispatch<React.SetStateAction<boolean>>;
  onBoardingView: boolean;
  setOnBoardingView: React.Dispatch<React.SetStateAction<boolean>>;
  isAuth: boolean;
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  user: any | null;
  setUser: React.Dispatch<React.SetStateAction<any | null>>;
  userSignin: IUserSignIn | null;
  setUserSignin: React.Dispatch<React.SetStateAction<IUserSignIn | null>>;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  customModal: boolean;
  setCustomModal: React.Dispatch<React.SetStateAction<boolean>>;
  cropImage: string;
  setCropImage: React.Dispatch<React.SetStateAction<string>>;
  openSettingsModal: boolean;
  setOpenSettingsModal: React.Dispatch<React.SetStateAction<boolean>>;
  openThemeModal: boolean;
  setOpenThemeModal: React.Dispatch<React.SetStateAction<boolean>>;
  openAddProfileModal: boolean;
  setOpenAddProfileModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedImage: any;
  setSelectedImage: React.Dispatch<any>;
  showProfileView: boolean;
  setShowProfileView: React.Dispatch<React.SetStateAction<boolean>>;
  showAddLinksView: boolean;
  setShowAddLinksView: React.Dispatch<React.SetStateAction<boolean>>;
  showAddThemeView: boolean;
  setShowAddThemeView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEmpl: Object[];
  setSelectedEmpl: React.Dispatch<React.SetStateAction<Object[]>>;
  showBillingView: boolean;
  setShowBillingView: React.Dispatch<React.SetStateAction<boolean>>;
  showPaymentDetailsView: boolean;
  setShowPaymentDetailsView: React.Dispatch<React.SetStateAction<boolean>>;
  directStatus: boolean;
  setDirectStatus: React.Dispatch<React.SetStateAction<boolean>>;
  openDirect: boolean;
  setOpenDirect: React.Dispatch<React.SetStateAction<boolean>>;
  selecedDirectLink: UserLinks | [];
  setSelecedDirectLink: React.Dispatch<React.SetStateAction<UserLinks | []>>;
  connectWithMeUser: userDetailType | null;
  setConnectWithMeUser: React.Dispatch<
    React.SetStateAction<userDetailType | null>
  >;
  profileCropModal: boolean;
  setProfileCropModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  firstStep: boolean;
  setFirstStep: React.Dispatch<React.SetStateAction<boolean>>;
  secondStep: boolean;
  setSecondStep: React.Dispatch<React.SetStateAction<boolean>>;
  thirdStep: boolean;
  setThirdStep: React.Dispatch<React.SetStateAction<boolean>>;
  type: any;
  setType: React.Dispatch<any>;
  date: any;
  setDate: React.Dispatch<any>;
  time: any;
  setTime: React.Dispatch<any>;
  quantity: any;
  setQuantity: React.Dispatch<any>;
  tel: any;
  setTel: React.Dispatch<any>;
  profileTab: boolean;
  setProfileTab: React.Dispatch<React.SetStateAction<boolean>>;
  shareTab: boolean;
  setShareTab: React.Dispatch<React.SetStateAction<boolean>>;
  directTab: boolean;
  setDirectTab: React.Dispatch<React.SetStateAction<boolean>>;
  linksTab: boolean;
  setLinksTab: React.Dispatch<React.SetStateAction<boolean>>;
  shopUserDataGet: boolean;
  setShopUserDataGet: React.Dispatch<React.SetStateAction<boolean>>;
  orderCartLocalCount: number;
  setOrderCartLocalCount: React.Dispatch<React.SetStateAction<number>>;
  isStaffOrderView: boolean;
  setIsStaffOrderView: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: CtxState = {
  signInView: true,
  setSignInView: () => {},
  otpView: false,
  setOtpInView: () => {},
  signUpView: false,
  setSignUpView: () => {},
  onBoardingView: false,
  setOnBoardingView: () => {},
  isAuth: false,
  setIsAuth: () => {},
  loading: false,
  setLoading: () => {},
  disabled: false,
  setDisabled: () => {},
  user: null,
  setUser: function (value: SetStateAction<any | null>): void {
    throw new Error("Function not implemented.");
  },
  userSignin: null,
  setUserSignin: function (value: SetStateAction<IUserSignIn | null>): void {
    throw new Error("Function not implemented.");
  },
  openModal: false,
  setOpenModal: () => {},
  customModal: false,
  setCustomModal: () => {},
  cropImage: "",
  setCropImage: () => {},
  openSettingsModal: false,
  setOpenSettingsModal: () => {},
  openThemeModal: false,
  setOpenThemeModal: () => {},
  openAddProfileModal: false,
  setOpenAddProfileModal: () => {},
  selectedImage: null,
  setSelectedImage: () => {},
  showProfileView: false,
  setShowProfileView: () => {},
  showAddLinksView: false,
  setShowAddLinksView: () => {},
  showAddThemeView: false,
  setShowAddThemeView: () => {},
  selectedEmpl: [],
  setSelectedEmpl: () => {},
  showBillingView: false,
  setShowBillingView: () => {},
  showPaymentDetailsView: false,
  setShowPaymentDetailsView: () => {},
  directStatus: false,
  setDirectStatus: () => {},
  openDirect: false,
  setOpenDirect: () => {},
  selecedDirectLink: [],
  setSelecedDirectLink: function (value: SetStateAction<UserLinks | []>): void {
    throw new Error("Function not implemented.");
  },
  connectWithMeUser: null,
  setConnectWithMeUser: function (
    value: SetStateAction<userDetailType | null>
  ): void {
    throw new Error("Function not implemented.");
  },
  profileCropModal: false,
  setProfileCropModal: () => {},
  isOpen: false,
  setIsOpen: () => {},
  firstStep: false,
  setFirstStep: () => {},
  secondStep: false,
  setSecondStep: () => {},
  thirdStep: false,
  setThirdStep: () => {},
  type: null,
  setType: () => {},
  date: null,
  setDate: () => {},
  time: null,
  setTime: () => {},
  quantity: null,
  setQuantity: () => {},
  tel: null,
  setTel: () => {},
  profileTab: false,
  setProfileTab: () => {},
  shareTab: false,
  setShareTab: () => {},
  directTab: false,
  setDirectTab: () => {},
  linksTab: false,
  setLinksTab: () => {},
  shopUserDataGet: false,
  setShopUserDataGet: () => {},
  orderCartLocalCount: 0,
  setOrderCartLocalCount: () => {},
  isStaffOrderView: false,
  setIsStaffOrderView: () => {},
};

export const AppCtx = React.createContext<CtxState>(initialState);

const CtxProvider: React.FC = ({ children }) => {
  const [signInView, setSignInView] = React.useState<boolean>(true);
  const [otpView, setOtpInView] = React.useState<boolean>(false);
  const [signUpView, setSignUpView] = React.useState<boolean>(false);
  const [onBoardingView, setOnBoardingView] = React.useState<boolean>(false);
  const [isAuth, setIsAuth] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<any | null>(null);
  const [userSignin, setUserSignin] = React.useState<IUserSignIn | null>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [customModal, setCustomModal] = React.useState<boolean>(false);
  const [cropImage, setCropImage] = React.useState("");
  const [openSettingsModal, setOpenSettingsModal] =
    React.useState<boolean>(false);
  const [openThemeModal, setOpenThemeModal] = React.useState<boolean>(false);
  const [openAddProfileModal, setOpenAddProfileModal] =
    React.useState<boolean>(false);
  const [selectedImage, setSelectedImage] = React.useState<any>(null);
  const [showProfileView, setShowProfileView] = React.useState<boolean>(true);
  const [showAddLinksView, setShowAddLinksView] =
    React.useState<boolean>(false);
  const [showAddThemeView, setShowAddThemeView] =
    React.useState<boolean>(false);
  const [selectedEmpl, setSelectedEmpl] = React.useState<Object[]>([]);
  const [showBillingView, setShowBillingView] = React.useState<boolean>(true);
  const [showPaymentDetailsView, setShowPaymentDetailsView] =
    React.useState<boolean>(false);
  //new
  const [directStatus, setDirectStatus] = React.useState(false);
  const [openDirect, setOpenDirect] = React.useState(false);
  const [selecedDirectLink, setSelecedDirectLink] = React.useState<
    UserLinks | []
  >([]);
  const [connectWithMeUser, setConnectWithMeUser] =
    React.useState<userDetailType | null>(null);
  const [profileCropModal, setProfileCropModal] =
    React.useState<boolean>(false);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [firstStep, setFirstStep] = React.useState<boolean>(true);
  const [secondStep, setSecondStep] = React.useState<boolean>(false);
  const [thirdStep, setThirdStep] = React.useState<boolean>(false);
  const [type, setType] = React.useState<any>(null);
  const [date, setDate] = React.useState<any>(null);
  const [time, setTime] = React.useState<any>(null);
  const [quantity, setQuantity] = React.useState<any>(1);
  const [tel, setTel] = React.useState<any>(null);
  const [profileTab, setProfileTab] = React.useState<boolean>(true);
  const [shareTab, setShareTab] = React.useState<boolean>(false);
  const [directTab, setDirectTab] = React.useState<boolean>(false);
  const [linksTab, setLinksTab] = React.useState<boolean>(false);
  const [shopUserDataGet, setShopUserDataGet] = React.useState<boolean>(false);
  const [orderCartLocalCount, setOrderCartLocalCount] =
    React.useState<number>(0);
  //STAFF PORTAL
  const [isStaffOrderView, setIsStaffOrderView] =
    React.useState<boolean>(false);

  return (
    <AppCtx.Provider
      value={{
        signInView,
        setSignInView,
        otpView,
        setOtpInView,
        signUpView,
        setSignUpView,
        onBoardingView,
        setOnBoardingView,
        isAuth,
        setIsAuth,
        loading,
        setLoading,
        disabled,
        setDisabled,
        user,
        setUser,
        userSignin,
        setUserSignin,
        openModal,
        setOpenModal,
        customModal,
        setCustomModal,
        cropImage,
        setCropImage,
        openSettingsModal,
        setOpenSettingsModal,
        openThemeModal,
        setOpenThemeModal,
        openAddProfileModal,
        setOpenAddProfileModal,
        selectedImage,
        setSelectedImage,
        showProfileView,
        setShowProfileView,
        showAddLinksView,
        setShowAddLinksView,
        showAddThemeView,
        setShowAddThemeView,
        selectedEmpl,
        setSelectedEmpl,
        showBillingView,
        setShowBillingView,
        showPaymentDetailsView,
        setShowPaymentDetailsView,
        directStatus,
        setDirectStatus,
        openDirect,
        setOpenDirect,
        selecedDirectLink,
        setSelecedDirectLink,
        connectWithMeUser,
        setConnectWithMeUser,
        profileCropModal,
        setProfileCropModal,
        isOpen,
        setIsOpen,
        firstStep,
        setFirstStep,
        secondStep,
        setSecondStep,
        thirdStep,
        setThirdStep,
        type,
        setType,
        date,
        setDate,
        time,
        setTime,
        quantity,
        setQuantity,
        tel,
        setTel,
        profileTab,
        setProfileTab,
        shareTab,
        setShareTab,
        directTab,
        setDirectTab,
        linksTab,
        setLinksTab,
        shopUserDataGet,
        setShopUserDataGet,
        orderCartLocalCount,
        setOrderCartLocalCount,
        isStaffOrderView,
        setIsStaffOrderView,
      }}
    >
      {children}
    </AppCtx.Provider>
  );
};

export default CtxProvider;
