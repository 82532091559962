import React from "react";
import { Button } from "components/ui";
import { TableSelection, TableSelectionObject } from "types";

const orderType = [
  // {
  //   name: "Pick Up",
  //   type: "pickup",
  //   img: "/assets/shop/select_table/1.png",
  //   select: true,
  // },
  {
    name: "Dine In",
    type: "dine_in",
    img: "/assets/shop/select_table/3.png",
    select: false,
  },
  // {
  //   name: "Delivery",
  //   type: "delivery",
  //   img: "/assets/shop/select_table/2.png",
  //   select: false,
  // },
] as unknown as TableSelection;

interface Props {
  onClose: any;
  onNext: any;
}

const OrderingType: React.FC<Props> = ({ onClose, onNext }) => {
  const closeHandler = onClose;
  const [orderTypeSelection, setOrderTypeSelection] = React.useState<
    TableSelection | []
  >([]);
  const [selectType, setSelectType] =
    React.useState<TableSelectionObject | null>(null);

  const onChangeing = (index: number, list: TableSelectionObject) => {
    for (let i = 0; i < orderTypeSelection.length; i++) {
      const element = orderTypeSelection[i];
      if (element.select === true) {
        orderTypeSelection[i].select = false;
      }
    }
    let newObject = {
      name: list.name,
      type: list.type,
      img: list.img,
      select: true,
    };
    setSelectType(newObject);

    orderTypeSelection[index] = newObject;
    setOrderTypeSelection([...orderTypeSelection]);
  };

  React.useEffect(() => {
    setOrderTypeSelection(orderType);
    setSelectType(orderType[0]);
  }, []);

  return (
    <>
      <div className="mx-auto my-10 max-w-sm">
        <div className="grid grid-cols-2">
          {orderTypeSelection.map((list, index) => {
            return (
              <div
                key={index}
                className={
                  list?.select
                    ? "mx-auto mb-4 w-fit cursor-pointer rounded-md bg-gold/50 p-4"
                    : "mx-auto mb-4 w-fit cursor-pointer rounded-md bg-cultured p-4"
                }
                onClick={() => onChangeing(index, list)}
              >
                <img
                  className="h-24 w-24"
                  src={`${list?.img}`}
                  alt={`${list?.name}`}
                />
                <h2 className="text-center font-bebas tracking-widest">
                  {list?.name}
                </h2>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mx-auto flex w-full justify-between md:w-3/4">
        <Button variant="outlined" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={() => onNext(selectType)}>Next</Button>
      </div>
    </>
  );
};

export default OrderingType;
