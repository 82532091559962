import axios from "axios";
import React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {AppCtx} from "data/context";
import ProtectedRoute from "./ProtectedRoute";
import {Loading} from "components/ui";
import QRScan from "pages/qrScan";
import DashboardLayout from "components/common/DashboardLayout";
import Banner from "pages/shop/AdminSide/Banners";
import Categorie from "pages/shop/AdminSide/Categories";
import Product from "pages/shop/AdminSide/Products";
import Order from "pages/shop/AdminSide/Orders";
import ShopProfile from "pages/shop/AdminSide/ShopProfile";
import ProductAdd from "pages/shop/AdminSide/ProductAdd";
import UpdateProduct from "pages/shop/AdminSide/UpdateProduct";
import ShopProtectedRoutes from "./ShopProtectedRoutes";
import ViewShop from "pages/shop/UserSide/ViewShop";
import ViewOrders from "pages/shop/UserSide/ViewOrders";
import Coupon from "pages/shop/AdminSide/Coupon";
import TableSelection from "pages/shop/UserSide/TableSelection";
import OrderList from "pages/shop/AdminSide/OrderList";
import SearchProduct from "pages/shop/UserSide/SearchProduct";
import PageNotFound from "pages/PageNotFound";
import TableManagement from "pages/shop/AdminSide/TableManagement";
import CreateOrder from "pages/shop/AdminSide/CreateOrder";
import OrderRequest from "pages/shop/AdminSide/OrderRequest";
import StoreDisplay from "pages/shop/StoreDisplay";
import Landing from "pages/Landing";

const Home = React.lazy(() => import("pages"));
const Auth = React.lazy(() => import("pages/auth"));
const ConnectWithMe = React.lazy(() => import("pages/connectWithMe"));
const Ticket = React.lazy(() => import("pages/ticket"));
const Booking = React.lazy(() => import("pages/booking"));
const Profile = React.lazy(() => import("pages/profile"));
const Shop = React.lazy(() => import("pages/shop/AdminSide"));

const AppRoutes = () => {
    let navigate = useNavigate();
    const {setIsAuth} = React.useContext(AppCtx);

    React.useEffect(() => {
        const authData = JSON.parse(localStorage.getItem("jd-u")!);
        if (authData !== null) {
            setIsAuth(true);
        }
    }, [navigate, setIsAuth]);

    return (
        <Routes>
            <Route
                path="*"
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <PageNotFound/>
                    </React.Suspense>
                }
            />
            <Route
                path="/"
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <Auth/>
                    </React.Suspense>
                }
            />
            <Route
                path="/connect"
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <ConnectWithMe/>
                    </React.Suspense>
                }
            />

            <Route
                path="/qr/:qrstring"
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <QRScan/>
                    </React.Suspense>
                }
            />

            <Route
                path="/ticket/:registerId"
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <Ticket/>
                    </React.Suspense>
                }
            />
            <Route
                path="/booking"
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <Booking/>
                    </React.Suspense>
                }
            />
            <Route path="/profile" element={<ProtectedRoute/>}>
                <Route
                    path="/profile"
                    element={
                        <React.Suspense fallback={<Loading/>}>
                            <Profile/>
                        </React.Suspense>
                    }
                />
            </Route>

            {/* SHOP PAGES ROUTES */}
            <Route
                path={`/shop/:id`}
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <ViewShop/>
                    </React.Suspense>
                }
            />
            <Route
                path={`/shop/view_order`}
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <ViewOrders/>
                    </React.Suspense>
                }
            />
            <Route
                path={`/shop/table_selection`}
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <TableSelection/>
                    </React.Suspense>
                }
            />
            <Route
                path={`/shop/search/:id`}
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <SearchProduct/>
                    </React.Suspense>
                }
            />
            <Route path="shop" element={<ShopProtectedRoutes/>}>
                <Route element={<DashboardLayout/>}>
                    <Route
                        path="/shop/dashboard"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <Shop/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/banner"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <Banner/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/categorie"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <Categorie/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/product"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <Product/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/addProduct"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <ProductAdd/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/updateProduct/:id"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <UpdateProduct/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/create_order"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <CreateOrder/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/order_request"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <OrderRequest/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/order"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <Order/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/orderList"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <OrderList/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/coupon"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <Coupon/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/table"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <TableManagement/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path="/shop/profile"
                        element={
                            <React.Suspense fallback={<Loading/>}>
                                <ShopProfile/>
                            </React.Suspense>
                        }
                    />
                </Route>
            </Route>

            {/* <Route path="/:username" element={<Home />} /> */}
            <Route
                path="/store_display"
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <StoreDisplay/>
                    </React.Suspense>
                }
            />
            <Route
                path="/landing"
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <Landing/>
                    </React.Suspense>
                }
            />

            <Route
                path={`/:userName`}
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <Home/>
                    </React.Suspense>
                }
            />

            <Route
                path={`/viewprofile/:userName`}
                element={
                    <React.Suspense fallback={<Loading/>}>
                        <Home/>
                    </React.Suspense>
                }
            />
        </Routes>
    );
};

export default AppRoutes;
