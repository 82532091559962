import React from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { IMAGE_URL } from "config/Api";

interface Props {
  banner: any;
  onDelete?: any;
  icon?: boolean;
}
const BannerBox: React.FC<Props> = ({ banner, onDelete, icon }) => {
  const [imageLoading, setImageLoading] = React.useState(true);
  const deleteHandler = onDelete;
  return (
    <>
      <div className="relative h-48 w-80 rounded-2xl md:w-80 lg:w-64 xl:w-80 2xl:h-48 2xl:w-96">
        {icon && (
          <div
            className="absolute right-[18px] top-2 h-fit w-fit cursor-pointer rounded-md bg-gold/70 p-3"
            onClick={() => deleteHandler(banner?._id)}
          >
            <TrashIcon className="h-4 w-4" />
          </div>
        )}
        <div
          className={`${
            imageLoading ? `block h-full w-full animate-pulse` : `hidden`
          }`}
        >
          <div className="h-full w-full rounded-lg bg-black/20 object-cover" />
        </div>
        <div className={`${imageLoading ? `hidden` : `block h-full w-full`}`}>
          <img
            className="h-full w-full rounded-lg object-cover"
            src={`${IMAGE_URL}${banner?.image}`}
            alt="banner content"
            onLoad={() => setImageLoading(false)}
          />
        </div>
      </div>
    </>
  );
};

export default BannerBox;
