import React from "react";

const EventIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.8604 77.6973C48.0969 79.4865 31.2826 79.4865 14.5191 77.6973C7.83472 76.9839 2.55505 71.7042 1.84165 65.0193C0.0524579 48.2557 0.0524579 31.442 1.84165 14.6785C2.55505 7.99415 7.83472 2.71448 14.5191 2.00107C31.2826 0.211882 48.0963 0.211882 64.8598 2.00107C71.5448 2.71448 76.8245 7.99415 77.5379 14.6785C79.3271 31.442 79.3271 48.2557 77.5379 65.0193C76.8245 71.7042 71.5454 76.9839 64.8604 77.6973Z"
        fill="white"
      />
      <circle cx="39.5544" cy="39.8284" r="29.1865" fill="#FFCD71" />
      <mask
        id="mask0_2320_921"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="8"
        width="66"
        height="63"
      >
        <path
          d="M68.741 39.8283C68.741 55.9476 55.6737 69.0149 39.5545 69.0149C23.4352 69.0149 2.84967 79.0285 3.86224 40.6633C9.71265 -2.08975 23.4352 10.6418 39.5545 10.6418C55.6737 10.6418 68.741 23.7091 68.741 39.8283Z"
          fill="#FFCD71"
        />
      </mask>
      <g mask="url(#mask0_2320_921)">
        <path
          d="M22.3433 14.8144C23.1289 12.1671 25.9119 10.6579 28.5592 11.4435L53.4511 18.8304L44.3105 49.6315L19.4186 42.2446C16.7713 41.459 15.2621 38.676 16.0477 36.0287L22.3433 14.8144Z"
          fill="#51489A"
        />
        <path
          d="M25.7014 15.8107C26.487 13.1634 29.2699 11.6542 31.9172 12.4398L53.4512 18.8303L44.3106 49.6314L22.7767 43.2409C20.1294 42.4553 18.6202 39.6724 19.4058 37.0251L25.7014 15.8107Z"
          fill="#5F55AF"
        />
        <circle cx="32.8421" cy="29.6302" r="10.1202" fill="#76B6FA" />
        <path
          d="M53.4512 18.9082L81.3046 27.1746L72.1634 57.9755L44.31 49.7091L53.4512 18.9082Z"
          fill="#55A4F9"
        />
        <path
          d="M55.4728 28.0979C56.1014 25.98 58.3277 24.7727 60.4456 25.4013L80.4199 31.3293L73.6322 54.2003L53.6579 48.2723C51.54 47.6437 50.3327 45.4173 50.9612 43.2995L55.4728 28.0979Z"
          fill="#1380E2"
        />
        <path
          d="M32.6141 24.275L39.127 24.2394C39.4388 24.2402 39.664 24.5378 39.5802 24.8381L37.1926 33.3865C36.8316 34.6251 35.6663 34.8263 35.1288 34.7721C32.8066 34.4519 32.4188 33.097 32.5152 32.4595C32.9894 30.7079 34.9249 31.0467 35.8334 31.4351L37.2139 26.4926L32.3208 26.6162L30.9192 31.6344C30.7952 32.3337 30.5149 32.8502 30.1954 33.2204C29.5699 33.9451 28.5222 34.1176 27.5796 33.9503C26.787 33.8095 26.1998 33.4842 25.7765 33.0975C24.8538 32.2545 24.6535 30.5733 25.6877 29.8716C26.726 29.167 28.4635 29.6675 29.4631 30.161L30.6987 25.737C30.939 24.8766 31.7208 24.2799 32.6141 24.275Z"
          fill="#453D83"
        />
        <path
          d="M35.8334 31.4351L37.2139 26.4927L35.6806 26.5367C34.9748 26.557 34.4229 25.9332 34.529 25.2351C34.6124 24.6861 35.0818 24.2787 35.6371 24.2733L39.1271 24.2395C39.4388 24.2403 39.6641 24.5379 39.5802 24.8381L37.1926 33.3866C36.8316 34.6252 35.6663 34.8264 35.1288 34.7722C32.8066 34.452 32.4189 33.097 32.5153 32.4596C32.9894 30.7079 34.9249 31.0468 35.8334 31.4351Z"
          fill="#51489A"
        />
        <circle
          cx="53.0882"
          cy="19.3562"
          r="2.56674"
          transform="rotate(16.53 53.0882 19.3562)"
          fill="#FFCD71"
        />
        <circle
          cx="48.7862"
          cy="33.9668"
          r="2.56674"
          transform="rotate(16.53 48.7862 33.9668)"
          fill="#FFCD71"
        />
        <circle
          cx="50.9151"
          cy="26.7561"
          r="2.56674"
          transform="rotate(16.53 50.9151 26.7561)"
          fill="#FFCD71"
        />
        <path
          d="M10.3315 37.6045C10.3315 34.8431 12.5701 32.6045 15.3315 32.6045H40.1212V64.7333H15.3315C12.5701 64.7333 10.3315 62.4947 10.3315 59.7333V37.6045Z"
          fill="#FF2EA6"
        />
        <path
          d="M13.7012 37.6045C13.7012 34.8431 15.9398 32.6045 18.7012 32.6045H40.1212V64.7333H18.7012C15.9398 64.7333 13.7012 62.4947 13.7012 59.7333V37.6045Z"
          fill="#FF50B5"
        />
        <circle cx="23.8214" cy="48.6964" r="10.1202" fill="#D12898" />
        <path
          d="M40.1212 32.6045H69.1754V64.7333H40.1212V32.6045Z"
          fill="#C8E2FD"
        />
        <path
          d="M46.0741 40.8591C46.0741 38.65 47.865 36.8591 50.0741 36.8591H70.9095V60.7161H50.0741C47.865 60.7161 46.0741 58.9253 46.0741 56.7161V40.8591Z"
          fill="#86BEFB"
        />
        <path
          d="M22.2481 43.6654L28.5114 41.879C28.8119 41.796 29.1089 42.022 29.1089 42.3338V51.2094C29.0944 52.4994 28.0262 53.0067 27.4939 53.099C25.1712 53.4154 24.4332 52.2147 24.3546 51.5748C24.3401 49.7602 26.2954 49.5658 27.2749 49.6955V44.8344C27.2749 44.6999 27.1448 44.6037 27.0162 44.6432L22.6661 45.9774C22.6241 45.9903 22.5955 46.0291 22.5955 46.073V51.2094C22.6641 51.9163 22.5331 52.4892 22.325 52.9316C21.9175 53.7979 20.9548 54.2459 20.002 54.3383C19.2006 54.416 18.5476 54.2606 18.0359 54.002C16.9205 53.4383 16.2753 51.873 17.0825 50.9189C17.8931 49.9609 19.7011 49.9757 20.7966 50.1821V45.5887C20.7966 44.6954 21.389 43.9104 22.2481 43.6654Z"
          fill="#FFE1AA"
        />
        <path
          d="M27.2748 49.6955V44.5638L25.81 45.0188C25.1356 45.2282 24.4362 44.7758 24.3506 44.0749C24.2833 43.5237 24.6258 43.005 25.1592 42.8505L28.5114 41.879C28.8118 41.796 29.1088 42.022 29.1088 42.3338V51.2094C29.0943 52.4994 28.0261 53.0067 27.4938 53.099C25.1711 53.4154 24.4332 52.2147 24.3546 51.5748C24.34 49.7602 26.2954 49.5658 27.2748 49.6955Z"
          fill="#FFF5F5"
        />
        <path
          d="M39.9455 35.1712C41.363 35.1712 42.5122 34.0221 42.5122 32.6045H37.3787C37.3787 34.0221 38.5279 35.1712 39.9455 35.1712Z"
          fill="#FFCD71"
        />
        <circle cx="39.9455" cy="48.6744" r="2.56674" fill="#FFCD71" />
        <circle cx="39.9455" cy="41.0086" r="2.56674" fill="#FFCD71" />
        <circle cx="39.9455" cy="56.3402" r="2.56674" fill="#FFCD71" />
        <circle cx="39.9455" cy="64.006" r="2.56674" fill="#FFCD71" />
        <rect
          x="48.481"
          y="43.2537"
          width="10.661"
          height="1.82276"
          rx="0.911378"
          fill="#3493F8"
        />
        <rect
          x="48.481"
          y="49.8088"
          width="7.08726"
          height="1.82276"
          rx="0.911378"
          fill="#3493F8"
        />
        <rect
          x="48.481"
          y="56.3633"
          width="4.66409"
          height="1.82276"
          rx="0.911378"
          fill="#3493F8"
        />
        <rect
          x="54.4257"
          y="56.3633"
          width="4.66409"
          height="1.82276"
          rx="0.911378"
          fill="#3493F8"
        />
        <rect
          x="48.481"
          y="39.6978"
          width="16.6405"
          height="1.82276"
          rx="0.911378"
          fill="#3493F8"
        />
      </g>
    </svg>
  );
};

export default EventIcon;
