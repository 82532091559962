import React from "react";

const PaypalIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.4804 77.5576C47.7169 79.3468 30.9026 79.3468 14.1391 77.5576C7.45472 76.8442 2.17505 71.5646 1.46164 64.8796C-0.327547 48.1161 -0.327547 31.3024 1.46164 14.5389C2.17505 7.8545 7.45472 2.57483 14.1391 1.86143C30.9026 0.0722333 47.7163 0.0722333 64.4798 1.86143C71.1648 2.57483 76.4445 7.8545 77.1579 14.5389C78.947 31.3024 78.947 48.1161 77.1579 64.8796C76.4445 71.5646 71.1654 76.8442 64.4804 77.5576Z"
        fill="url(#paint0_linear_2307_973)"
      />
      <path
        d="M54.6352 28.2373C54.5918 28.5145 54.5423 28.7978 54.4865 29.0889C52.5712 38.9224 46.0187 42.3194 37.6499 42.3194H33.3889C32.3655 42.3194 31.503 43.0626 31.3436 44.0721L29.162 57.908L28.5442 61.83C28.4404 62.4926 28.9514 63.0903 29.6203 63.0903H37.1777C38.0726 63.0903 38.8329 62.44 38.9738 61.5575L39.0481 61.1735L40.471 52.1436L40.5623 51.6481C40.7017 50.7625 41.4635 50.1122 42.3584 50.1122H43.4887C50.8108 50.1122 56.5427 47.1394 58.218 38.5368C58.9178 34.9431 58.5555 31.9425 56.7037 29.8321C56.1432 29.1957 55.448 28.6677 54.6352 28.2373Z"
        fill="#ADC9E3"
      />
      <path
        d="M52.6306 27.4384C52.3379 27.3533 52.036 27.2759 51.7263 27.2062C51.4151 27.1381 51.0962 27.0777 50.7679 27.025C49.6191 26.8392 48.3603 26.751 47.0117 26.751H35.6283C35.3481 26.751 35.0818 26.8145 34.8433 26.929C34.3184 27.1814 33.9282 27.6784 33.8338 28.2869L31.4122 43.6247L31.3425 44.0722C31.502 43.0627 32.3644 42.3195 33.3879 42.3195H37.6489C46.0176 42.3195 52.5702 38.9209 54.4855 29.089C54.5428 28.7979 54.5908 28.5145 54.6341 28.2374C54.1495 27.9804 53.6246 27.7605 53.0595 27.5731C52.9201 27.5267 52.7761 27.4818 52.6306 27.4384Z"
        fill="#E6EFF6"
      />
      <path
        d="M33.8345 28.2876C33.929 27.6791 34.3191 27.1821 34.844 26.9312C35.084 26.8166 35.3488 26.7532 35.629 26.7532H47.0124C48.361 26.7532 49.6198 26.8414 50.7686 27.0272C51.0969 27.0799 51.4159 27.1403 51.7271 27.2084C52.0367 27.2781 52.3387 27.3555 52.6313 27.4406C52.7768 27.484 52.9208 27.5289 53.0617 27.5738C53.6269 27.7611 54.1518 27.9825 54.6364 28.238C55.2062 24.6041 54.6317 22.1298 52.6669 19.8894C50.5008 17.4229 46.5912 16.3669 41.5886 16.3669H27.0652C26.0433 16.3669 25.1716 17.1101 25.0137 18.1212L18.9643 56.4657C18.8451 57.2244 19.4304 57.9087 20.1952 57.9087H29.1616L31.4129 43.6254L33.8345 28.2876Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2307_973"
          x1="0.119752"
          y1="-1.26183"
          x2="78.4997"
          y2="81.0372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0099D9" />
          <stop offset="1" stopColor="#003087" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PaypalIcon;
