import React from "react";

interface Props {
  items: any;
}

const CategorieBox: React.FC<Props> = (props) => {
  const { items, children } = props;
  return (
    <>
      <div className="h-24 w-40 rounded-xl bg-cultured p-2 md:w-48">
        <div className="mb-1 flex h-14 w-full items-center justify-center rounded-2xl bg-gold">
          <h2 className="text-lg font-bold capitalize">
            {items?.name?.split(" ").slice(0, 2).join(" ")}{" "}
            {items?.name?.split(" ")?.length > 2 ? "..." : ""}
          </h2>
        </div>
        {children}
      </div>
    </>
  );
};

export default CategorieBox;
