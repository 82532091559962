import React from "react";
import axios from "axios";
import API from "config/Api";
import { Input } from "components/ui";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { useParams } from "react-router-dom";
import { useCountDown } from "lib/useCountDown";
import { XIcon } from "@heroicons/react/outline";
import { Controller, useForm } from "react-hook-form";
import useOutsideClick from "lib/hooks/useOutsideClick";

interface Props {
  onClose?: any;
  isOpen?: Boolean;
}

const CheckUser: React.FC<Props> = ({ onClose, isOpen }) => {
  const ref = React.useRef<any>();
  const closeHandler = onClose;
  const { id } = useParams();
  const [userName, setUserName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [isOtpView, setIsOtpView] = React.useState(false);
  const [counter, start, reset] = useCountDown(20, 1000);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useOutsideClick(ref, () => {
    if (isOpen && ref?.current?.id === 'checkUser') {
      closeHandler();
    }
  });

  const onPhoneNumberSignIn = (value: any, data: any) => {
    setNumber(value.slice(data.dialCode.length));
    setCountryCode(`+${data.dialCode}`);
  };

  const OnVerifyUser = () => {
    if (number === "" || number.length !== 10) {
      toast.warning("Please Enter Phone Number.");
    } else {
      const body = {
        phone: number,
        countryCode: countryCode,
      };
      const authData = JSON.parse(localStorage.getItem("jd-u")!);
      const config = {
        headers: {
          Authorization: `Bearer ${authData?.token}`,
        },
      };
      const url = API + `order/checkUser`;
      axios
        .post(url, body, config)
        .then((response) => {
          if (response?.data?.success) {
            reset();
            start();
            toast.success(
              `Your OTP has been sent to ${countryCode} ${number}.`
            );
            setIsOtpView(true);
          }
        })
        .catch((err) => {
          console.log("error here", err);
        });
    }
  };

  const onSubmit = handleSubmit((data) => {
    const body = {
      phone: number,
      countryCode: countryCode,
      otp: data?.otp,
    };
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `order/verifyUser`;
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success) {
          toast.success("User Verify Successfully.");
          const body = {
            userName: userName,
            userNumber: response?.data?.data?.data?.userNumber,
            storeId: id,
          };
          localStorage.setItem("jod-e_shop_cart_user", JSON.stringify(body));
          closeHandler();
        } else {
          toast.warn("Server down try later");
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  });

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-20 h-full w-full bg-[#322f2fa6]">
        <div className="absolute bottom-0 right-0 h-fit w-full overflow-auto scrollbar-hide">
          <div className="mx-auto h-fit w-full md:w-[36rem] lg:w-[64rem] 2xl:w-[80rem]">
            <div ref={ref} id="checkUser" className="relative mx-auto h-fit rounded-tr-2xl rounded-tl-2xl bg-white scrollbar-hide sm:w-[48rem] sm:shadow-2xl">
              <div className="absolute right-2 top-2 rounded-full bg-white/20 p-2">
                <XIcon
                  className="h-4 w-4 font-bold"
                  onClick={() => closeHandler()}
                />
              </div>
              <div className="p-4">
                <div>
                  <h2 className="mb-1 text-2xl font-bold capitalize">
                    Confirm your <br /> identity.
                  </h2>
                  <p className="font-medium capitalize text-gray">
                    to place order
                  </p>
                </div>
                <div className="px-2 sm:px-8">
                  <div className="mt-3">
                    <Input
                      type={"text"}
                      placeholder="User Name"
                      id="user_name"
                      value={userName}
                      onChange={(e) => setUserName(e)}
                    />
                  </div>
                  <div className="mt-3">
                    <PhoneInput
                      country={"in"}
                      onChange={onPhoneNumberSignIn}
                      inputStyle={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "none",
                        borderRadius: "0.375rem",
                        background: "#f4f4f4",
                        padding: "15px",
                        fontSize: "16px",
                      }}
                    />
                  </div>
                  {isOtpView && (
                    <div className="mt-3">
                      <form onSubmit={onSubmit}>
                        <Controller
                          control={control}
                          name="otp"
                          render={({ field }) => (
                            <OtpInput
                              {...field}
                              className="flex w-full"
                              numInputs={4}
                              isInputNum={true}
                              shouldAutoFocus={true}
                              inputStyle={{
                                width: "3rem",
                                height: "3rem",
                                margin: "0 1rem",
                                fontSize: "1.5rem",
                                borderRadius: "6px",
                                border: "none",
                                background: "#f4f4f4",
                              }}
                            />
                          )}
                          rules={{ required: true }}
                        />
                        {errors.otp && (
                          <p className="pt-2 text-[#FF0000]">
                            This field is required.
                          </p>
                        )}
                      </form>
                      {counter === 0 ? (
                        <h2
                          className="float-right my-4 w-fit cursor-pointer text-right font-medium"
                          onClick={OnVerifyUser}
                        >
                          Resend OTP
                        </h2>
                      ) : (
                        <h2 className="float-right my-4 w-fit text-right">
                          Resend OTP{" "}
                          <span className="text-[12px]">({counter} Sec)</span>
                        </h2>
                      )}
                    </div>
                  )}
                  <div className="mt-3">
                    {isOtpView ? (
                      <button
                        className="h-12 w-full rounded-md border-0 bg-gold font-bold"
                        onClick={onSubmit}
                      >
                        Verify
                      </button>
                    ) : (
                      <button
                        className="h-12 w-full rounded-md border-0 bg-gold font-bold"
                        onClick={OnVerifyUser}
                      >
                        Continue
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckUser;
