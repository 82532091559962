import React from "react";
import axios from "axios";
import API from "config/Api";
import { Button } from "components/ui";
import { useParams } from "react-router-dom";
import { StoreTableList, TableSelectionObject } from "types";

interface Props {
  onPrevious: any;
  onSelectTable: any;
  tableSelectionList: TableSelectionObject | null;
}

const tableNumbers = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];

const DineInSelection: React.FC<Props> = ({
  onPrevious,
  onSelectTable,
  tableSelectionList,
}) => {
  let { id } = useParams();
  const onPreviousHandel = onPrevious;
  const onSelectTableHandel = onSelectTable;
  const [selectedNo, setSelectedNo] = React.useState<string | null>(null);
  const [tableList, setTableList] = React.useState<StoreTableList | []>([]);

  const fetchProductList = React.useCallback(() => {
    const url = API + `table/list/${id}`;
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.success) {
          setTableList(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  }, [id]);

  const onSelectNo = (e: string) => {
    setSelectedNo(e);
  };

  React.useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  return (
    <>
      <div className="mx-auto my-10 max-w-sm">
        <img
          className="mx-auto h-36 w-36"
          src={`${tableSelectionList?.img}`}
          alt={`${tableSelectionList?.name}`}
        />
        <div>
          <label className="text-md mb-1 font-bold" htmlFor="select_table">
            Select Table No.
          </label>
          <select
            name="table_selection"
            id="select_table"
            className="mt-1 w-full rounded-md border-0 bg-cultured"
            onChange={(e) => onSelectNo(e.target.value)}
          >
            <option defaultValue="-----" selected>
              ----
            </option>
            {tableList?.length > 0 ? (
              tableList
                ?.filter((fil) => fil.availability)
                ?.map((list, index) => {
                  return (
                    <option key={index} value={list?.priority}>
                      {list?.table_name}
                    </option>
                  );
                })
            ) : (
              <>
                {tableNumbers.map((list, index) => {
                  return (
                    <option key={index} value={list}>
                      {list}
                    </option>
                  );
                })}
              </>
            )}
          </select>
        </div>
      </div>
      <div className="mx-auto flex w-full justify-between md:w-3/4">
        <Button variant="outlined" onClick={onPreviousHandel}>
          Previous
        </Button>
        <Button onClick={() => onSelectTableHandel(selectedNo, id)}>
          Next
        </Button>
      </div>
    </>
  );
};

export default DineInSelection;
