import React from "react";
import ShowMoreText from "react-show-more-text";
import { ClipboardListIcon, CurrencyRupeeIcon } from "@heroicons/react/outline";
import { IMAGE_URL } from "config/Api";

interface Props {
  orderProduct: any;
  sequence: number;
}

const ProductOrderBox: React.FC<Props> = ({ orderProduct, sequence }) => {
  return (
    <>
      <div className="mt-2 grid h-fit w-full select-none grid-rows-1 rounded-lg bg-cultured p-2 md:h-64">
        <div className="flex justify-between">
          <div className="relative h-full w-2/6">
            <div className="absolute -top-2 -left-2 flex h-5 w-5 items-center justify-center rounded-full bg-black text-xs text-white">
              {sequence + 1}
            </div>
            <img
              className="h-full w-full rounded-xl object-cover"
              src={`${IMAGE_URL}${orderProduct?.productId?.image[0]}`}
              alt={orderProduct?.productId?.name}
            />
          </div>
          <div className="ml-3 h-full w-2/3">
            <h2 className="mb-1 text-lg font-bold capitalize md:text-2xl">
              <ShowMoreText
                lines={1}
                more=""
                less=""
                className=""
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                {orderProduct?.productId?.name}
              </ShowMoreText>
            </h2>
            <div className="relative h-4/5 rounded-lg bg-white p-2">
              <h3 className="mb-1 border-b-2 border-gray/20 text-lg font-medium">
                <span className="text-sm">Quantity:</span>{" "}
                {orderProduct?.productQty}
              </h3>
              {orderProduct?.productNote !== null && (
                <p className="text-sm text-gray">{orderProduct?.productNote}</p>
              )}
              <ClipboardListIcon className="absolute right-3 mr-1 h-5 w-5" />
              {orderProduct?.addonsList?.length < 1 ? (
                <div className="w-4/5 text-sm capitalize">No Addons added.</div>
              ) : (
                orderProduct?.addonsList?.map((list: any, index: any) => {
                  return (
                    <h2
                      className="w-4/5 border-b border-gray/20 pb-0.5 text-sm"
                      key={index}
                    >
                      {list?.ingredient_name} ₹ {list?.ingredient_price}
                    </h2>
                  );
                })
              )}
            </div>
          </div>
        </div>
        <div className="mt-2 flex h-fit items-center justify-between rounded-lg bg-white p-2">
          <div className="flex items-center">
            <CurrencyRupeeIcon className="mr-1 h-5 w-5" />
            <h2>{orderProduct?.productPrice}</h2>
          </div>
          <div className="flex items-center">
            <ClipboardListIcon className="mr-1 h-5 w-5" />
            <h2>₹ {orderProduct?.adonsPrice}</h2>
          </div>
          <div className="flex items-center">
            <CurrencyRupeeIcon className="mr-1 h-5 w-5" />
            <h2>{orderProduct?.totalPrice}</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductOrderBox;
