import React from "react";

const SpotiifyIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.4804 77.3174C47.7169 79.1066 30.9026 79.1066 14.1391 77.3174C7.45472 76.604 2.17505 71.3243 1.46164 64.6394C-0.327547 47.8759 -0.327547 31.0621 1.46164 14.2986C2.17505 7.61427 7.45472 2.33459 14.1391 1.62119C30.9026 -0.168001 47.7163 -0.168001 64.4798 1.62119C71.1648 2.33459 76.4445 7.61427 77.1579 14.2986C78.947 31.0621 78.947 47.8759 77.1579 64.6394C76.4445 71.3243 71.1654 76.604 64.4804 77.3174Z"
        fill="white"
      />
      <path
        d="M39.3086 13.8181C24.7485 13.8181 12.9446 25.622 12.9446 40.1825C12.9446 54.7436 24.7485 66.5465 39.3086 66.5465C53.8703 66.5465 65.6729 54.7436 65.6729 40.1825C65.6729 25.6229 53.8703 13.8194 39.3083 13.8194L39.3086 13.8181ZM51.399 51.8431C50.9267 52.6175 49.913 52.8631 49.1386 52.3877C42.9486 48.6066 35.1561 47.7503 25.9791 49.847C25.0948 50.0485 24.2133 49.4944 24.0118 48.6098C23.8094 47.7251 24.3612 46.8436 25.2478 46.6421C35.2905 44.3467 43.905 45.3356 50.8543 49.5826C51.6288 50.058 51.8744 51.0686 51.399 51.8431ZM54.6259 44.6634C54.0309 45.6315 52.7653 45.9369 51.7988 45.3419C44.7122 40.9849 33.9097 39.7234 25.5276 42.2679C24.4406 42.5962 23.2924 41.9836 22.9625 40.8984C22.6351 39.8113 23.248 38.6653 24.3332 38.3347C33.9079 35.4295 45.8109 36.8368 53.949 41.8378C54.9155 42.4328 55.2209 43.6985 54.6259 44.6637V44.6634ZM54.9029 37.1881C46.4059 32.1411 32.387 31.6771 24.2743 34.1393C22.9716 34.5344 21.594 33.799 21.1992 32.4962C20.8044 31.1929 21.5392 29.8161 22.8429 29.4201C32.1556 26.5929 47.6369 27.1391 57.4196 32.9468C58.5939 33.6422 58.978 35.1556 58.2822 36.3258C57.5896 37.4976 56.0722 37.8839 54.9042 37.1881H54.9029Z"
        fill="#1ED760"
      />
    </svg>
  );
};

export default SpotiifyIcon;
