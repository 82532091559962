import React from "react";
import { Button } from "components/ui";
import { TableSelectionObject } from "types";

interface Props {
  tableSelectionList: TableSelectionObject | null;
  onPrevious: any;
}

const PickupSelection: React.FC<Props> = ({
  tableSelectionList,
  onPrevious,
}) => {
  const onPreviousHandel = onPrevious;
  return (
    <>
      <div className="mx-auto my-10 max-w-sm">
        <img
          className="mx-auto h-36 w-36"
          src={`${tableSelectionList?.img}`}
          alt={`${tableSelectionList?.name}`}
        />
        <h2 className="mt-2 text-center font-bebas text-3xl tracking-widest">
          Comming Soon
        </h2>
      </div>
      <div className="mx-auto flex w-full justify-between md:w-3/4">
        <Button variant="outlined" onClick={onPreviousHandel}>
          Previous
        </Button>
        <Button>Next</Button>
      </div>
    </>
  );
};

export default PickupSelection;
