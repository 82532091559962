import React from "react";
import cn from "classnames";
import s from "./Separate.module.css";

export interface SeparateProps {
  className?: string;
  dark: Boolean;
}

const Separater: React.FC<SeparateProps> = (props) => {
  const { className, dark } = props;
  const SepWhite = cn(s.separater_white, className);
  const SepBlack = cn(s.separater_black, className);
  return (
    <>{dark ? <hr className={SepBlack} /> : <hr className={SepWhite} />}</>
  );
};

export default Separater;
