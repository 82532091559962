import React from "react";
import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import { Separate } from "components/ui";
import OrderingType from "./OrderingType";
import { TableSelectionObject } from "types";
import PickupSelection from "./PickupSelection";
import DineInSelection from "./DineInSelection";
import DeliverySelection from "./DeliverySelection";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  onClose: any;
  onOpen: boolean;
  onStoreData: any;
}
const TableSelection: React.FC<Props> = ({ onOpen, onClose, onStoreData }) => {
  const closeHandler = onClose;
  const [isOrderType, setIsOrderType] = React.useState<boolean>(true);
  const [isPickup, setIsPickup] = React.useState<boolean>(false);
  const [isDineIn, setIsDineIn] = React.useState<boolean>(false);
  const [isDelivery, setIsDelivery] = React.useState<boolean>(false);
  const [tableSelectionlist, setTableSelectionlist] =
    React.useState<TableSelectionObject | null>(null);

  const onSelectTableType = (list: TableSelectionObject) => {
    setTableSelectionlist(list);
    if (list?.type === "pickup") {
      setIsPickup(true);
      setIsDineIn(false);
      setIsDelivery(false);
      setIsOrderType(false);
    } else if (list?.type === "dine_in") {
      setIsDineIn(true);
      setIsPickup(false);
      setIsDelivery(false);
      setIsOrderType(false);
    } else if (list?.type === "delivery") {
      setIsDelivery(true);
      setIsPickup(false);
      setIsDineIn(false);
      setIsOrderType(false);
    }
  };

  //TABLE SELECTION
  const onSelectedTableNo = (tableNo: string, id: any) => {
    if (tableNo === null) {
      toast.warn("Please Select Table Number.");
    } else {
      const url = API + `table/join`;
      const body = {
        storeId: id,
        priority: tableNo,
      };
      axios
        .post(url, body)
        .then((response) => {
          if (response?.data?.success) {
            if (
              response?.data?.message ===
              "The table is available for reservation."
            ) {
              toast.success(response?.data?.message);
              const body = {
                tableNumber: response?.data?.data?.table_name,
                orderType: "dine_in",
                _id: response?.data?.data?._id,
                priority: response?.data?.data?.priority,
              };
              localStorage.setItem(
                "jod-e_table_selection",
                JSON.stringify(body)
              );
              closeHandler();
            } else if (response.data.data === null) {
              const body = {
                tableNumber: tableNo,
                orderType: "dine_in",
                priority: tableNo,
              };
              localStorage.setItem(
                "jod-e_table_selection",
                JSON.stringify(body)
              );
              closeHandler();
            } else {
              toast.warn(response?.data?.message);
            }
          }
        })
        .catch((err) => {
          console.log("error here", err);
        })
        .finally(() => {});
    }
  };

  const onPreviousButton = () => {
    setIsOrderType(true);
    setIsPickup(false);
    setIsDineIn(false);
    setIsDelivery(false);
  };

  return (
    <>
      <Transition appear show={onOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeHandler}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 pb-4 text-center text-xl font-medium leading-6"
                  >
                    {isPickup
                      ? "Pick Up"
                      : isDineIn
                      ? "Dine In"
                      : isDelivery
                      ? "Delivery"
                      : "Order Type"}
                  </Dialog.Title>
                  <Separate dark={true} />
                  {isOrderType && (
                    <OrderingType
                      onClose={closeHandler}
                      onNext={onSelectTableType}
                    />
                  )}
                  {isPickup && (
                    <PickupSelection
                      tableSelectionList={tableSelectionlist}
                      onPrevious={onPreviousButton}
                    />
                  )}
                  {isDineIn && (
                    <DineInSelection
                      tableSelectionList={tableSelectionlist}
                      onPrevious={onPreviousButton}
                      onSelectTable={onSelectedTableNo}
                    />
                  )}
                  {isDelivery && (
                    <DeliverySelection
                      tableSelectionList={tableSelectionlist}
                      onPrevious={onPreviousButton}
                    />
                  )}
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default TableSelection;
