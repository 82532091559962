import React from "react";
import axios from "axios";
import API from "config/Api";
import ReactGA from "react-ga";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function QRScan() {
  const { qrstring } = useParams();
  const [searchParams] = useSearchParams({
    tableNo: "",
  });
  const activeView = searchParams.get("tableNo");
  let navigate = useNavigate();

  React.useEffect(() => {
    axios
      .get(API + `viewprofile/qrstring?initial_qr_string=${qrstring}`)
      .then(function (response) {
        /** Google Analytics Card QR Code scan*/
        ReactGA.initialize("UA-210740501-1");
        ReactGA.event({
          category: "Card QRCode Scan",
          action: "Card QRCode Scan",
          label: "Card QRCode Scan",
          value: 1,
        });
        if (response.data.success) {
          if (activeView !== "" || null || undefined) {
            navigate(`/${response.data.data.username}?tableNo=${activeView}`);
          } else {
            navigate(`/${response.data.data.username}`);
          }
        } else {
          window.open("https://jod-e.com/");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [navigate, qrstring, activeView]);

  return <div></div>;
}
