import { IMAGE_URL } from "config/Api";
import React from "react";
import { ShopType } from "types";

interface Props {
  shopDetails: ShopType | null;
}

const BannerView: React.FC<Props> = ({ shopDetails }) => {
  const [imageLoading, setImageLoading] = React.useState(true);
  return (
    <>
      <div className="my-5 flex h-fit space-x-4 overflow-y-auto scrollbar-hide">
        {shopDetails?.banner_id?.bannerImage.map((items, index) => {
          return (
            <div key={index} className="h-44 w-72 rounded-2xl">
              <div
                className={`${
                  imageLoading
                    ? `block h-44 w-72 animate-pulse object-cover`
                    : `hidden animate-pulse`
                }`}
              >
                <div className="h-full w-full rounded-lg bg-black/20 object-cover"></div>
              </div>
              <div
                className={`${
                  imageLoading ? `hidden` : `block h-44 w-72 object-cover`
                }`}
              >
                <img
                  className="h-full w-full rounded-lg object-cover"
                  src={`${IMAGE_URL}${items?.image}`}
                  alt="banner content"
                  onLoad={() => setImageLoading(false)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BannerView;
