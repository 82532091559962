import React from "react";

const ShopIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.8604 77.0772C48.0969 78.8664 31.2826 78.8664 14.5191 77.0772C7.83472 76.3638 2.55505 71.0841 1.84165 64.3991C0.0524579 47.6356 0.0524579 30.8219 1.84165 14.0584C2.55505 7.37403 7.83472 2.09436 14.5191 1.38096C31.2826 -0.408235 48.0963 -0.408235 64.8598 1.38096C71.5448 2.09436 76.8245 7.37403 77.5379 14.0584C79.327 30.8219 79.327 47.6356 77.5379 64.3991C76.8245 71.0841 71.5454 76.3638 64.8604 77.0772Z"
        fill="url(#paint0_linear_2320_662)"
      />
      <path
        d="M11.1001 37.5675C11.2468 36.4855 11.3466 35.3941 11.546 34.3197C13.1361 25.7711 17.686 19.2171 25.0755 14.6878C29.8166 11.7612 35.3417 10.3602 40.9044 10.6741C48.9958 11.0674 55.7699 14.3284 61.1648 20.38C65.2236 24.9356 67.5965 30.2984 68.1497 36.3745C68.9701 45.3916 66.1588 53.1894 59.7535 59.6023C55.021 64.3235 49.2366 66.9579 42.5904 67.7106L41.3786 67.8479H38.0254C37.8839 67.808 37.74 67.7772 37.5945 67.7557C33.1761 67.4766 28.8922 66.1255 25.1131 63.8192C17.6992 59.2786 13.1417 52.7284 11.5385 44.1629C11.3372 43.0903 11.2393 41.9989 11.0925 40.9169L11.1001 37.5675ZM39.7566 12.7647C25.1978 12.712 13.2791 24.5837 13.2245 39.1895C13.1699 53.7464 25.0435 65.667 39.6512 65.7216C54.2062 65.7781 66.1306 53.9007 66.1833 39.2968C66.2341 24.738 54.3586 12.8155 39.7566 12.7647Z"
        fill="white"
      />
      <path
        d="M51.7998 60.0203C51.6398 56.9907 51.4818 54.0157 51.3275 51.0407C51.2767 50.0754 51.2503 49.1063 51.1788 48.141C51.1525 47.7816 51.2409 47.6104 51.606 47.5144C52.9345 47.1644 54.0259 46.4136 55.0062 45.4634C55.8304 44.6655 56.2745 43.7115 56.2237 42.5749C56.0826 39.3591 55.9659 36.1414 55.7288 32.9331C55.5406 30.3551 55.185 27.7922 54.899 25.1879H53.7267V41.3255H52.2514V25.1842H50.5579V41.3292H49.0676V25.1842H47.4493V41.333H45.959V25.1842H44.4066C44.2278 26.7253 44.0152 28.2514 43.8834 29.785C43.5805 33.3075 43.2907 36.832 43.0574 40.3602C43.0168 41.42 43.0589 42.4813 43.1835 43.5346C43.2354 43.9386 43.3896 44.3226 43.6313 44.6505C44.6171 46.0474 46.0536 47.0627 47.6996 47.5257C47.8237 47.5615 47.9423 47.6123 48.0872 47.665C48.0872 47.8174 48.0872 47.9641 48.0872 48.1109C47.8576 52.6477 47.6318 57.1864 47.3815 61.7232C47.359 61.8272 47.3121 61.9245 47.2447 62.0069C47.1774 62.0893 47.0914 62.1546 46.9939 62.1974C42.9005 63.5345 38.5183 63.7206 34.3263 62.7356C33.9988 62.6584 33.9123 62.5173 33.8972 62.2124C33.6752 57.7139 33.4469 53.2135 33.2123 48.7112C33.1746 47.9698 33.1332 47.2265 33.0919 46.4418C33.3095 46.3423 33.5325 46.255 33.7599 46.1803C35.9897 45.5556 37.5383 44.0841 38.5168 42.0706C40.137 38.7362 40.5867 35.2476 39.3354 31.678C38.5338 29.4049 37.1356 27.5382 35.0695 26.2492C32.5612 24.678 29.9889 24.7796 27.6255 26.5654C24.7766 28.7199 23.2976 31.6234 23.1658 35.1779C23.0755 37.8274 23.6005 40.3583 24.9892 42.6634C26.0072 44.3569 27.4016 45.5744 29.3171 46.1539C29.5636 46.2292 29.8045 46.3252 30.0698 46.4193C29.8196 51.2534 29.5693 56.0668 29.3171 60.8934C22.3548 57.9221 15.0576 49.3942 15.5995 38.0833C16.1903 25.8936 25.7212 16.3627 37.2993 15.2356C43.0817 14.6553 48.8792 16.1823 53.6254 19.5359C58.3715 22.8895 61.7472 27.844 63.1314 33.4882C65.9954 45.3034 59.6992 55.698 51.7998 60.0203Z"
        fill="white"
      />
      <path
        d="M26.7618 32.4124C26.6319 33.3099 26.4588 34.2019 26.376 35.1032C26.073 38.3435 27.1475 41.1604 29.0763 43.7064C29.1828 43.894 29.2632 44.0953 29.3152 44.3048C29.0913 44.2765 28.8316 44.3048 28.6529 44.2107C27.0572 43.2924 26.0373 41.915 25.3618 40.2083C24.2572 37.4196 24.7276 34.8266 26.1784 32.3258C26.2687 32.1696 26.5096 32.1038 26.6808 31.9946L26.9048 32.3936L26.7618 32.4124Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2320_662"
          x1="39.6898"
          y1="0.0390625"
          x2="39.6898"
          y2="78.4191"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#60C600" />
          <stop offset="1" stopColor="#478C07" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ShopIcon;
