import React from "react";
import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { Button, HeroIcon, Separate } from "components/ui";

interface Props {
  onOpen: any;
  onClose: any;
  storeData: any;
  updatePaymrntQr: any;
}

const PaymentQrModel: React.FC<Props> = ({
  onOpen,
  onClose,
  storeData,
  updatePaymrntQr,
}) => {
  const closeHandler = onClose;
  const updatePaymrntQrHandler = updatePaymrntQr;
  const [imgPath, setImgPath] = React.useState<any>([]);
  const [selectedImage, setSelectedImage] = React.useState<any>([]);
  const formData = new FormData();

  //ADD IMAGE ARRAY FORM
  const addImage = (event: any) => {
    const reader = new FileReader(),
      filesToDisplay = event.target.files;
    reader.onload = function () {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(filesToDisplay[0]);
    event.preventDefault();
    const files = event.target.files;
    const tempImages = [...imgPath] as any[];

    for (let index = 0; index < files.length; index++) {
      const file = files[index] as any[];
      const duplicate = false;
      if (!duplicate) tempImages.push(file);
    }
    setImgPath([...tempImages]);
  };

  //CLEAR SELECT IMAGE
  const onClearImage = () => {
    setSelectedImage([]);
    setImgPath([]);
  };

  //UPLOAD IMAGE AND PROCESS TO UPDATE STORE WITH PAYMENT QR IMAGE
  const onSubmit = () => {
    imgPath.forEach((file: string | Blob) => {
      formData.append("image", file);
    });

    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config2 = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
      params: {
        //IT'S CREATE INDIVIDUAL FOLDER IN AWS FOR SHOP TO ADD PHOTOS.
        directory: storeData?.shop_name,
      },
    };

    let imgUrl = API + `common/image`;
    axios
      .post(imgUrl, formData, config2)
      .then(async (response) => {
        if (response?.data?.success) {
          closeHandler();
          onClearImage();
          setSelectedImage(response?.data?.data?.files[0]?.key);
          updatePaymrntQrHandler(response?.data?.data?.files[0]?.key);
        } else {
          toast.warn(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  };

  //ADD PAYMENT QR IMAGE IN STATE
  React.useEffect(() => {
    const paymentQrImage =
      storeData?.paymentQrImage === null ? [] : storeData?.paymentQrImage;
    setSelectedImage(paymentQrImage);
  }, [storeData]);

  return (
    <>
      <Transition appear show={onOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeHandler}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 pb-4 text-center text-xl font-medium leading-6"
                  >
                    Shop Payment QR
                  </Dialog.Title>
                  <Separate dark={true} />

                  <div className="my-10">
                    {selectedImage?.length === 0 ? (
                      <div className="border-sky-500 mx-auto mb-7 flex h-40 w-40 items-center justify-center border-2 border-dashed border-gray/40">
                        <label
                          htmlFor="product_image"
                          className="cursor-pointer bg-white"
                        >
                          <HeroIcon
                            className="h-10 w-10 p-2 text-philippine-gray"
                            icon="PlusIcon"
                          />
                          <input
                            className="hidden"
                            type="file"
                            id="product_image"
                            accept="image/*"
                            onChange={addImage}
                          />
                        </label>
                      </div>
                    ) : (
                      <div className="relative mx-auto h-40 w-40">
                        <div
                          className="absolute -bottom-3 -right-3 cursor-pointer rounded-full border border-gray bg-white p-2"
                          onClick={onClearImage}
                        >
                          <PencilIcon className="h-4 w-4 text-gray" />
                        </div>
                        {selectedImage === "shop.png" ? (
                          <img
                            src={`/assets/icons/social/${`v1`}/${`default`}/${
                              storeData?.image
                            }`.replace(".png", ".svg")}
                            alt={`${storeData?.shop_name}`}
                            className="mr-2 h-full w-full object-cover"
                          />
                        ) : (
                          <img
                            className="h-full w-full object-cover"
                            src={selectedImage}
                            alt=""
                          />
                        )}
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-center gap-5">
                    <Button variant="outlined" onClick={closeHandler}>
                      Cancel
                    </Button>
                    <Button loading={false} onClick={onSubmit}>
                      Submit
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PaymentQrModel;
