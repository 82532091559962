import React from "react";

const DribbleIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M65.1004 77.9375C48.3369 79.7267 31.5226 79.7267 14.7591 77.9375C8.07471 77.2241 2.79504 71.9444 2.08164 65.2595C0.292448 48.496 0.292448 31.6822 2.08164 14.9187C2.79504 8.23438 8.07471 2.95471 14.7591 2.24131C31.5226 0.452116 48.3363 0.452116 65.0998 2.24131C71.7848 2.95471 77.0644 8.23438 77.7778 14.9187C79.567 31.6822 79.567 48.496 77.7778 65.2595C77.0644 71.9444 71.7854 77.2241 65.1004 77.9375Z"
        fill="#DD3E7B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0343 64.3154C26.7304 64.3154 15.9077 53.4919 15.9077 40.1904C15.9077 26.8913 26.7304 16.0679 40.0343 16.0679C53.3381 16.0679 64.1609 26.8913 64.1609 40.1904C64.1609 53.4919 53.3381 64.3154 40.0343 64.3154ZM59.3161 43.3207C58.6487 43.1126 53.2715 41.5062 47.1524 42.485C49.7066 49.5027 50.7466 55.2224 50.946 56.4078C55.3269 53.4458 58.4468 48.7544 59.3161 43.3207ZM47.6639 58.197C47.3716 56.4841 46.2378 50.5118 43.4959 43.3891C43.4546 43.4035 43.4117 43.4161 43.3672 43.432C32.3538 47.2671 28.3997 54.9094 28.0478 55.6276C31.3576 58.2081 35.52 59.7486 40.0343 59.7486C42.7388 59.7485 45.3185 59.194 47.6639 58.197ZM25.526 53.2775C25.9677 52.5195 31.3282 43.6464 41.3993 40.3907C41.6543 40.308 41.9109 40.2317 42.1683 40.1602C41.6789 39.0488 41.1459 37.9411 40.5851 36.8448C30.8333 39.7638 21.37 39.643 20.5142 39.624C20.5086 39.8226 20.5055 40.022 20.5055 40.2206C20.5055 45.2371 22.4067 49.8158 25.526 53.2775ZM20.9178 36.2219C21.7919 36.2329 29.837 36.2664 38.9722 33.8431C35.737 28.0902 32.2467 23.254 31.731 22.5502C26.268 25.1258 22.1835 30.1622 20.9178 36.2219ZM35.4581 21.2384C35.9983 21.9606 39.5448 26.7897 42.7451 32.6697C49.6905 30.0638 52.6286 26.1159 52.9791 25.6169C49.531 22.555 44.9958 20.6966 40.0342 20.6966C38.4581 20.6966 36.9263 20.8841 35.4581 21.2384ZM55.1504 27.8725C54.7397 28.4304 51.4656 32.6252 44.2458 35.5704C44.7004 36.5032 45.1356 37.4502 45.5425 38.4005C45.6848 38.739 45.8253 39.0743 45.962 39.4079C52.4602 38.5911 58.9164 39.8997 59.5623 40.0379C59.5186 35.429 57.8716 31.1966 55.1504 27.8725Z"
        fill="white"
      />
    </svg>
  );
};

export default DribbleIcon;
