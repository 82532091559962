import React from "react";
import classNames from "lib/class-names";
import { useTableProps } from "lib/hooks/useTableProps";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import LoadingCircle from "../LoadingCircle";

export interface TableProps {
  columns: any;
  data: any;
  loading: boolean;
}

const Table: React.FC<TableProps> = (props) => {
  const { columns, data, loading } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    // setGlobalFilter,
    pageSize,
    // globalFilter,
  } = useTableProps({ columns, data });
  return (
    <>
      <div className="overflow-x-auto">
        {loading ? (
          <>
            <div className="mx-auto mt-5 mb-1 flex w-full items-center justify-center">
              <LoadingCircle />
              <h2 className="ml-3 text-sm">Loading Data</h2>
            </div>
          </>
        ) : (
          <>
            <table className="w-full table-auto" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        className="select-none whitespace-nowrap p-4 text-left font-bold capitalize text-black"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white text-raisin-black"
                {...getTableBodyProps()}
              >
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="hover:bg-cultured/50">
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            className="whitespace-nowrap border-b border-gray/20 px-4 py-4"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              {/* <span>Hmmm, we're not getting any results. Try another search.</span> */}
            </table>
            {data.length === 0 && (
              <div className="mx-auto mt-5 mb-1 flex w-full items-center justify-center">
                <LoadingCircle />
                <h2 className="ml-3 text-sm">
                  Hmm, we're not getting any results. Try another search.
                </h2>
              </div>
            )}
          </>
        )}
      </div>
      <div className="mt-4 flex justify-between">
        <div className="flex items-center space-x-4">
          <div className="space-x-2">
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded bg-[#EBEEF5]"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <ChevronLeftIcon
                className={classNames(
                  !canPreviousPage ? "text-cadet-blue" : "text-independence",
                  "h-3 w-3"
                )}
              />
            </button>
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded bg-[#EBEEF5]"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <ChevronRightIcon
                className={classNames(
                  !canNextPage ? "text-cadet-blue" : "text-independence",
                  "h-3 w-3"
                )}
              />
            </button>
          </div>

          <div className="flex items-center text-[#6E7191]">
            1-10 / {rows.length}&nbsp;
            <span className="hidden sm:block">tasks</span>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <div className="hidden text-[#6E7191] sm:block">Show on page</div>
          <select
            className="text-independence rounded border-none bg-[#EBEEF5] focus:ring-0"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} rows
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default Table;
