import _ from "lodash";
import React from "react";
import { StoreTableList, StoreTableOblect } from "types";
import TableBox from "../table/TableBox";

interface Props {
  tableData: any;
  tableList: StoreTableList | [];
}

const TableSelectionForOrder: React.FC<Props> = ({ tableList, tableData }) => {
  const tableDataHandler = tableData;

  //USE LODASH FOR CREATE GROUP BY TABLE TYPE
  const tablesDisplay = _.groupBy(
    tableList?.sort((a: any, b: any) => (a?.priority > b?.priority ? 1 : -1)),
    "table_location"
  );

  //FUNCTION FOR PASS SELECTED TABLE DETAILS
  const onSelectTable = (list: StoreTableOblect) => {
    tableDataHandler(list);
  };

  return (
    <>
      {Object.keys(tablesDisplay)?.map((title, index) => {
        return (
          <div key={index}>
            <h3 className="my-4 mt-6 text-xl font-bold uppercase">
              {title}{" "}
              <span className="text-sm">({tablesDisplay[title]?.length})</span>
            </h3>
            <ul className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {tablesDisplay[title]?.map((list, index2) => {
                return (
                  <li
                    key={index2}
                    className="cursor-pointer"
                    onClick={() => onSelectTable(list)}
                  >
                    <TableBox
                      key={index}
                      icon={false}
                      tableList={list}
                      onLoading={false}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
      {tableList === null && (
        <div className="w-fit rounded bg-cultured p-2">
          <p>You have to create table first for creating order.</p>
        </div>
      )}
    </>
  );
};

export default TableSelectionForOrder;
