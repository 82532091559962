import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import React, { Fragment } from "react";
import { CouponObject, Coupons } from "types";
import { Dialog, Transition } from "@headlessui/react";
import CouponBox from "components/shops/coupon/CouponBox";
import { stringToBoolean } from "lib/hooks/StringToBoolean";
import CouponModel from "components/shops/coupon/CouponModel";
import AddCouponBox from "components/shops/coupon/AddCouponBox";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";
import UpdateCouponModel from "components/shops/coupon/UpdateCouponModel";

interface Props {}

const Coupon: React.FC<Props> = () => {
  const [couponAddModel, setCouponAddModel] = React.useState<boolean>(false);
  const [couponUpdateModel, setCouponUpdateModel] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [couponUpdateLoading, setCouponUpdateLoading] =
    React.useState<boolean>(false);
  const [couponsList, setCouponsList] = React.useState<Coupons | []>([]);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [couponDeleteId, setCouponDeleteId] = React.useState("");
  const [selectedCoupon, setSelectedCoupon] =
    React.useState<CouponObject | null>(null);

  const fetchCouponList = React.useCallback(() => {
    setIsLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `promoShop/${authData?.storeId}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setCouponsList(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onCouponModel = (status: boolean) => {
    setCouponAddModel(status);
  };

  const onCouponUpdateModel = (status: boolean) => {
    setCouponUpdateModel(status);
  };

  const onNewCouponAdded = (status: boolean) => {
    onCouponModel(status);
    fetchCouponList();
  };

  const openDeleteModal = (id: any) => {
    setIsDeleteModal(true);
    setCouponDeleteId(id);
  };

  const closeDeleteModal = () => {
    setIsDeleteModal(false);
  };

  const onDeleteProduct = () => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `promo/${couponDeleteId}`;
    axios
      .delete(url, config)
      .then((response) => {
        if (response?.data?.success) {
          fetchCouponList();
          closeDeleteModal();
          setCouponDeleteId("");
          toast.success(`Coupon Deleted Successfully.`);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  const onUpdateCouponList = (list: CouponObject) => {
    setCouponUpdateModel(true);
    setSelectedCoupon(list);
  };

  const onUpdateCoupon = (detail: any) => {
    setCouponUpdateLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const body = {
      promoCode: detail?.coupon_name,
      promoAmount: parseFloat(detail?.coupon_amount),
      promoAttempt: parseFloat(detail?.coupon_attempted),
      isInRupee:
        typeof detail?.isInRupee === "boolean"
          ? detail?.isInRupee
          : stringToBoolean(detail?.isInRupee),
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `promo/${selectedCoupon?._id}`;
    axios
      .patch(url, body, config)
      .then((response) => {
        if (response?.data?.success) {
          toast.success(`Copuon Updated Successfully.`);
          fetchCouponList();
          setCouponUpdateModel(false);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setCouponUpdateLoading(false);
      });
  };

  const onActiveCoupon = (id: any, status: any) => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const body = {
      id: id,
      isBlocked: !status,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `promo/block`;
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success) {
          fetchCouponList();
          toast.success(
            `Copuon ${!status ? "Is Active" : "Is Inactive"} Successfully.`
          );
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  React.useEffect(() => {
    fetchCouponList();
  }, [fetchCouponList]);

  return (
    <>
      <DashboardHeader title={"Coupons"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <h2 className="mb-3 hidden text-xl font-bold md:block">Coupons</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <AddCouponBox isCoupon={couponAddModel} onCoupon={onCouponModel} />
          <CouponBox
            onLoading={isLoading}
            couponsList={couponsList}
            onDelete={openDeleteModal}
            onUpdate={onUpdateCouponList}
            onActive={onActiveCoupon}
          />
        </div>
      </section>

      <Transition appear show={isDeleteModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeDeleteModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 text-lg font-medium leading-6"
                  >
                    Confirm Delete
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-gray-500 text-sm">
                      Are you sure you want to Delete?
                    </p>
                  </div>

                  <div className="mt-4 flex space-x-3">
                    <button
                      type="button"
                      className="inline-flex w-24 select-none items-center justify-center rounded-md border border-gold bg-gold py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                      onClick={onDeleteProduct}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-24 select-none items-center justify-center rounded-md border border-raisin-black bg-transparent py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                      onClick={closeDeleteModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <CouponModel
        isCoupon={couponAddModel}
        onCoupon={onCouponModel}
        onNewCoupon={onNewCouponAdded}
      />

      <UpdateCouponModel
        isCoupon={couponUpdateModel}
        onCoupon={onCouponUpdateModel}
        selectedCoupon={selectedCoupon}
        onUpdateDetails={onUpdateCoupon}
        onLoading={couponUpdateLoading}
      />
    </>
  );
};

export default Coupon;
