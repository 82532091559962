import React from "react";

const StoreDisplay = () => {
  return (
    <>
      <section className="fixed inset-0 bg-raisin-black text-white">
        <div className="mx-auto h-full overflow-auto border-x border-x-white/20 bg-white/10 backdrop-blur-md scrollbar-hide sm:max-w-3xl">
          StoreDisplay
        </div>
      </section>
    </>
  );
};

export default StoreDisplay;
