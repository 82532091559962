import React from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const authData = JSON.parse(localStorage.getItem("jd-u")!);
  const [isAllowed, setisAllowed] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (authData === null) {
      setisAllowed(false);
    } else {
      const decoded = jwt_decode(authData?.token) as any;      
      if (decoded?.data?.role === "user") {
        if (decoded?.exp < moment().unix()) {
          setisAllowed(false);
          localStorage.removeItem("jd-u");
        } else {
          setisAllowed(true);
        }
      } else {
        setisAllowed(false);
        localStorage.removeItem("jd-u");
      }
    }
  }, [authData, authData?.token]);

  return isAllowed ? <Outlet /> : <Navigate to={"/"} />;
};

export default ProtectedRoutes;
