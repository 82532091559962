import React from "react";

const MailIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.8604 77.7979C48.0969 79.5871 31.2826 79.5871 14.5191 77.7979C7.83472 77.0845 2.55505 71.8048 1.84165 65.1198C0.0524579 48.3563 0.0524579 31.5426 1.84165 14.7791C2.55505 8.09473 7.83472 2.81506 14.5191 2.10166C31.2826 0.312468 48.0963 0.312468 64.8598 2.10166C71.5448 2.81506 76.8245 8.09473 77.5379 14.7791C79.327 31.5426 79.327 48.3563 77.5379 65.1198C76.8245 71.8048 71.5454 77.0845 64.8604 77.7979Z"
        fill="url(#paint0_linear_2307_933)"
      />
      <path
        d="M18.7524 24.9863C18.3684 24.9863 18.0067 25.0508 17.6644 25.1834L24.5212 32.0555L31.4539 39.0507L31.5804 39.1985L31.7828 39.3956L31.9853 39.5926L32.3901 40.0114L38.336 45.9475C38.435 46.0074 38.7219 46.2661 38.9461 46.3752C39.2348 46.5156 39.5477 46.6451 39.8703 46.6563C40.2184 46.6685 40.5741 46.5714 40.8874 46.4231C41.1219 46.312 41.2262 46.1528 41.4988 45.9475L48.3809 39.0261L55.3389 32.0555L62.0439 25.3312C61.6134 25.1041 61.1367 24.9863 60.627 24.9863H18.7524ZM16.6523 25.8238C15.9211 26.4983 15.4631 27.5122 15.4631 28.6564V51.2186C15.4631 52.145 15.7688 52.9865 16.2728 53.6324L17.2343 52.7457L24.3947 45.9721L30.7455 39.9867L30.619 39.8389L23.6609 32.8683L16.7029 25.8731L16.6523 25.8238ZM62.9548 26.0455L56.1739 32.8683L49.2411 39.8389L49.1146 39.9621L55.7184 46.1938L62.8788 52.9674L63.309 53.3615C63.6945 52.7593 63.9162 52.0175 63.9162 51.2186V28.6564C63.9162 27.6362 63.5531 26.7111 62.9548 26.0455ZM31.5551 40.7996L25.2297 46.7849L18.0439 53.5585L17.1331 54.4206C17.6133 54.7217 18.1597 54.9132 18.7524 54.9132H60.627C61.3394 54.9132 61.9857 54.6464 62.5246 54.2236L62.0692 53.7802L54.8835 47.0066L48.2797 40.7996L42.3337 46.7603C42.0119 46.968 41.7969 47.1984 41.4826 47.3398C40.9769 47.5675 40.4227 47.7601 39.8655 47.7518C39.3069 47.7434 38.7592 47.5306 38.2579 47.2906C38.0063 47.1701 37.8721 47.0503 37.577 46.8096L31.5551 40.7996Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2307_933"
          x1="40.2385"
          y1="78.8252"
          x2="40.3148"
          y2="1.706"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70EFFF" />
          <stop offset="1" stopColor="#5770FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MailIcon;
