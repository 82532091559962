import React from "react";
import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { XIcon } from "@heroicons/react/solid";
import { Button, Separate } from "components/ui";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  onStoreData: any;
  onOpen: any;
  onClose: any;
}

const BankDetail: React.FC<Props> = ({ onOpen, onClose, onStoreData }) => {
  const closeHandler = onClose;
  const [isLoading, setIsLoading] = React.useState(false);
  const [stepOne, setStepOne] = React.useState(true);
  const [bankDetailId, setBankDetailId] = React.useState(null);
  const [stepOneDisable, setStepOneDisable] = React.useState(false);
  const [stepTwoDisable, setStepTwoDisable] = React.useState(false);
  const [bankStatus, setBankStatus] = React.useState<number>(0);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const account_number = React.useRef({});
  account_number.current = watch("account_number", "");

  const fetchStoreBankDetails = React.useCallback(() => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `bankDetails/${authData?.storeId}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          if (response?.data?.data !== null) {
            setBankDetailId(response?.data?.data?._id || null);
            setBankStatus(response?.data?.data?.status);
            setValue("account_name", response?.data?.data?.name);
            setValue("account_email", response?.data?.data?.email);
            setValue("business_type", response?.data?.data?.business_type);
            setValue("branch_code", response?.data?.data?.ifcs_code);
            setValue("account_number", response?.data?.data?.account_number);
            setValue("re_account_number", response?.data?.data?.account_number);
            setValue(
              "beneficiary_name",
              response?.data?.data?.beneficiary_name
            );
            setStepOneDisable(response?.data?.data?.name !== undefined && true);
            setStepTwoDisable(
              response?.data?.data?.ifcs_code !== undefined && true
            );
          } else {
            setBankDetailId(null);
            setStepOneDisable(false);
            setStepTwoDisable(false);
          }
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  }, [setValue]);

  const onCreateBankDetails = handleSubmit((data: any) => {
    if (bankDetailId !== null) {
      setStepOne(false);
    } else {
      setIsLoading(true);
      const authData = JSON.parse(localStorage.getItem("jd-u")!);
      const config = {
        headers: {
          Authorization: `Bearer ${authData?.token}`,
        },
      };
      const body = {
        storeId: onStoreData?._id,
        name: data?.account_name,
        email: data?.account_email,
        business_type: data?.business_type,
      };
      let URL = API + `addBank`;
      axios
        .post(URL, body, config)
        .then((response) => {
          if (response?.data?.success) {
            setBankDetailId(response?.data?.data?._id);
            fetchStoreBankDetails();
            setStepOne(false);
          } else {
            toast.warn(`Error while adding bank account.`);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  });

  const onCreateBankDetailsTwo = handleSubmit((data: any) => {
    setIsLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const body = {
      ifcs_code: data?.branch_code,
      account_number: data?.account_number,
      beneficiary_name: data?.beneficiary_name,
    };
    let URL = API + `compleBankDetail/${bankDetailId}`;
    axios
      .patch(URL, body, config)
      .then((response) => {
        if (response?.data?.success) {
          fetchStoreBankDetails();
          closeHandler();
        } else {
          toast.warn(`Error while adding bank account.`);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  React.useEffect(() => {
    fetchStoreBankDetails();
  }, [fetchStoreBankDetails]);

  return (
    <>
      <Transition appear show={onOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeHandler}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 flex justify-between pb-4 text-xl font-medium leading-6"
                  >
                    Shop Bank Details
                    <XIcon
                      className="h-5 w-5 cursor-pointer"
                      onClick={closeHandler}
                    />
                  </Dialog.Title>
                  <Separate dark={true} />
                  <div className="my-10 grid md:flex">
                    <div className="mb-5 w-full border-b-2 border-gray/60 md:mb-0 md:w-40 md:border-b-0 md:border-r-2 md:pr-3 ">
                      <h2
                        className={
                          stepOne
                            ? "mb-3 cursor-pointer rounded-md bg-gold p-2 font-bold capitalize"
                            : "mb-3 cursor-pointer font-bold capitalize"
                        }
                        onClick={() => setStepOne(true)}
                      >
                        Bank details
                      </h2>
                      <h2
                        className={
                          stepOne
                            ? "mb-3 cursor-pointer font-bold capitalize"
                            : "mb-3 cursor-pointer rounded-md bg-gold p-2 font-bold capitalize"
                        }
                        onClick={() => setStepOne(false)}
                      >
                        Account Details
                      </h2>
                    </div>
                    {stepOne ? (
                      <>
                        <form onSubmit={onCreateBankDetails}>
                          <div className="md:pl-10">
                            <h2 className="pb-3 font-bold capitalize">
                              Bank details
                            </h2>
                            <div>
                              <div className="my-5">
                                <label
                                  className="text-md font-medium"
                                  htmlFor="account_name"
                                >
                                  Name
                                </label>
                                <input
                                  className="mt-1 w-full rounded-md border-0 bg-cultured"
                                  type="text"
                                  id="account_name"
                                  placeholder="Name"
                                  disabled={stepOneDisable}
                                  {...register("account_name", {
                                    required: true,
                                  })}
                                />
                                {errors.account_name && (
                                  <p className="flex justify-end text-sm text-[#CC3300]">
                                    This field is required.
                                  </p>
                                )}
                              </div>
                              <div className="my-5">
                                <label
                                  className="text-md font-medium"
                                  htmlFor="account_email"
                                >
                                  Email
                                </label>
                                <input
                                  className="mt-1 w-full rounded-md border-0 bg-cultured"
                                  type="text"
                                  id="account_email"
                                  placeholder="Email"
                                  disabled={stepOneDisable}
                                  {...register("account_email", {
                                    required: true,
                                  })}
                                />
                                {errors.account_email && (
                                  <p className="flex justify-end text-sm text-[#CC3300]">
                                    This field is required.
                                  </p>
                                )}
                              </div>
                              <div className="my-5">
                                <label
                                  className="text-md font-medium"
                                  htmlFor="account_email"
                                >
                                  Business Type
                                </label>
                                <select
                                  className="mt-1 w-full rounded-md border-0 bg-cultured"
                                  // name="business_type"
                                  id="business_type"
                                  disabled={stepOneDisable}
                                  {...register("business_type", {
                                    required: true,
                                  })}
                                >
                                  <option value="Private_Limited">
                                    Private Limited
                                  </option>
                                  <option value="Proprietorship">
                                    Proprietorship
                                  </option>
                                  <option value="Partnership">
                                    Partnership
                                  </option>
                                  <option value="Individual">Individual</option>
                                  <option value="Public_Limited">
                                    Public Limited
                                  </option>
                                  <option value="LLP">LLP</option>
                                  <option value="Trust">Trust</option>
                                  <option value="Society">Society</option>
                                  <option value="NGO">NGO</option>
                                </select>
                                {errors.business_type && (
                                  <p className="flex justify-end text-sm text-[#CC3300]">
                                    This field is required.
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <form onSubmit={onCreateBankDetailsTwo}>
                          <div className="md:pl-10">
                            <h2 className="pb-3 font-bold capitalize">
                              Bank Account Details
                            </h2>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="branch_code"
                              >
                                Branch IFCS Code
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="text"
                                id="branch_code"
                                placeholder="Branch IFCS Code"
                                disabled={stepTwoDisable}
                                {...register("branch_code", { required: true })}
                              />
                              {errors.branch_code && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="account_number"
                              >
                                Account Number
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="number"
                                id="account_number"
                                placeholder="Account Number"
                                disabled={stepTwoDisable}
                                {...register("account_number", {
                                  required: true,
                                })}
                              />
                              {errors.account_number && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="re_account_number"
                              >
                                Re-Account Number
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="number"
                                id="re_account_number"
                                placeholder="Re-Account Number"
                                disabled={stepTwoDisable}
                                {...register("re_account_number", {
                                  validate: (value: any) =>
                                    value === account_number.current ||
                                    "The account number do not match",
                                })}
                              />
                              {errors.re_account_number && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  {errors.re_account_number.message}
                                </p>
                              )}
                            </div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="beneficiary_name"
                              >
                                Beneficiary Name
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="text"
                                id="beneficiary_name"
                                placeholder="Beneficiary Name"
                                disabled={stepTwoDisable}
                                {...register("beneficiary_name", {
                                  required: true,
                                })}
                              />
                              {errors.beneficiary_name && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                  {stepOne ? (
                    <div className="flex justify-end">
                      <Button loading={isLoading} onClick={onCreateBankDetails}>
                        Save & Next
                      </Button>
                    </div>
                  ) : (
                    <div className="flex justify-end">
                      <Button
                        loading={isLoading}
                        disabled={bankStatus === 100 ? true : false}
                        onClick={onCreateBankDetailsTwo}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default BankDetail;
