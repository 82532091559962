import React from "react";
import axios from "axios";
import API from "config/Api";
import { AppCtx } from "data/context";
import CartView from "components/shops/shopView/CartView";
import CheckUser from "components/shops/shopView/CheckUser";
import TableSelection from "components/shops/tableSelection";
import BannerView from "components/shops/shopView/BannerView";
import BottomToggle from "components/shops/shopView/BottomToggle";
import ShopDetailBox from "components/shops/shopView/ShopDetailBox";
import ProductViewBox from "components/shops/shopView/ProductViewBox";
import ShopViewHeader from "components/shops/shopView/ShopViewHeader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  ProductObject,
  ShopCategorie,
  ShopOrderObject,
  ShopProduct,
  ShopType,
} from "types";
import SingleProductView from "components/shops/shopView/SingleProductView";
import BottomOrderTab from "components/shops/shopView/BottomOrderTab";
import { SearchIcon } from "@heroicons/react/outline";
import { ToggleButton } from "components/ui";

const ViewShop = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const ref = React.useRef<any>();
  const [searchParams, setSearchParams] = useSearchParams({
    view: "",
    tableNo: "",
  });
  const activeView = searchParams.get("view");
  const tableView = searchParams.get("tableNo");
  const { orderCartLocalCount, setOrderCartLocalCount } =
    React.useContext(AppCtx);
  const [shopDetails, setShopDetails] = React.useState<ShopType | null>(null);
  const [productsList, setProductsList] = React.useState<ShopProduct | []>([]);
  const [productSerList, setProductSerList] = React.useState<ShopProduct | []>(
    []
  );
  const [categorieList, setCategorieList] = React.useState<ShopCategorie | []>(
    []
  );
  const [ordersDetails, setOrdersDetails] =
    React.useState<ShopOrderObject | null>(null);
  //MODEL OPEN
  const [selectedProduct, setSelectedProduct] =
    React.useState<ProductObject | null>(null);
  const [isCartOpen, setIsCartOpen] = React.useState<Boolean | false>(false);
  const [isCheckUserOpen, setIsCheckUserOpen] = React.useState<Boolean | false>(
    false
  );
  const [isTableSelectionopen, setIsTableSelectionopen] =
    React.useState<boolean>(false);
  //LOADING
  const [isProductListLoading, setIsProductListLoading] = React.useState(false);
  const [isShopDetailsLoading, setIsShopDetailsLoading] = React.useState(false);
  let addons_array = [] as any[];
  //PRODUCT FILTER
  const [isVeg, setIsVeg] = React.useState(false);
  const [isNonVeg, setIsNonVeg] = React.useState(false);
  const [searchProduct, setSearchProduct] = React.useState("");

  //CHECK FOR USER AND STORE
  const checklocalStorage = React.useCallback(() => {
    const jode_cart = JSON.parse(
      localStorage.getItem("jod-e_shop_cart") as any
    );
    const jode_cart_user = JSON.parse(
      localStorage.getItem("jod-e_shop_cart_user") as any
    );
    if (jode_cart?.storeId !== id) {
      localStorage.removeItem("jod-e_shop_cart");
      // localStorage.removeItem("jod-e_table_selection");
    }
    if (jode_cart_user?.storeId !== id) {
      localStorage.removeItem("jod-e_shop_cart_user");
      // localStorage.removeItem("jod-e_table_selection");
    }
  }, [id]);

  //TABLE SELECTION
  const fetchTableSelection = React.useCallback(
    (tableView) => {
      const url = API + `table/join`;
      const body = {
        storeId: id,
        priority: tableView,
      };
      axios
        .post(url, body)
        .then((response) => {
          if (response?.data?.success) {
            if (
              response?.data?.message ===
              "The table is available for reservation."
            ) {
              const body = {
                tableNumber: response?.data?.data?.table_name,
                orderType: "dine_in",
                _id: response?.data?.data?._id,
                priority: response?.data?.data?.priority,
              };
              localStorage.setItem(
                "jod-e_table_selection",
                JSON.stringify(body)
              );
            } else {
              //OPEN TABLE SELECTION MODEL
              setIsTableSelectionopen(true);
            }
          }
        })
        .catch((err) => {
          console.log("error here", err);
        })
        .finally(() => {});
    },
    [id]
  );

  const paramsSearch = React.useCallback(() => {
    //CHECK FOR TABLE NUMBER FORM PARAMS AND ADD
    if (tableView !== "" || null || undefined) {
      fetchTableSelection(tableView);
    } else {
      //TABLE NO AND ORDER TYPE
      const jode_table = JSON.parse(
        localStorage.getItem("jod-e_table_selection") as any
      );
      if (jode_table === null) {
        setIsTableSelectionopen(true);
      }
    }
    //CHECK FOR OTHER FORM PARAMS AND ADD
    switch (activeView) {
      case "CART":
        setIsCartOpen(true);
        break;
      case "TABLE":
        setIsTableSelectionopen(true);
        break;
    }
  }, [activeView, tableView, fetchTableSelection]);

  const fetchShopDetails = React.useCallback(() => {
    setIsShopDetailsLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `store/${id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          if (response?.data?.message === "No store Found") {
            navigate(`/*`);
          }
          setShopDetails(response?.data?.data);
        } else {
          navigate(`/*`);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsShopDetailsLoading(false);
      });
  }, [id, navigate]);

  const fetchProductList = React.useCallback(() => {
    setIsProductListLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
      params: {
        page: "",
        limit: "",
      },
    };
    const url = API + `product/getallbystore/${id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setProductsList(response?.data?.data?.Products);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsProductListLoading(false);
      });
  }, [id]);
  //PRODUCT SEARCH FILTER
  const searchProductFilter = (value: string) => {
    ref.current.scrollIntoView();
    setSearchProduct(value);
    setProductSerList(
      productsList.filter((i) =>
        i.name?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      ) as []
    );
  };
  //PRODUCT VEG NONVEG FILTER
  const onVegNonVegFilter = (status: boolean, type: string) => {
    if (status) {
      setSearchProduct(`${status}`);
      setProductSerList(
        productsList.filter(
          (i) => i.isVeg === (type === "veg" ? true : false)
        ) as []
      );
    } else {
      setSearchProduct("");
      setProductSerList(productsList);
    }
  };

  const fetchShopCategorieList = React.useCallback(() => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `category/getallbystore/${id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setCategorieList(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  }, [id]);

  const fetchOrderDetails = React.useCallback(() => {
    const jode_cart_user = JSON.parse(
      localStorage.getItem("jod-e_shop_cart_user") as any
    );
    const config = {
      params: {
        userNumber: jode_cart_user?.userNumber,
      },
    };
    const url = API + `getorders`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setOrdersDetails(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  }, []);

  const onViewProduct = (list: ProductObject) => {
    setSelectedProduct(list);
  };

  const onAddToCart = (
    list: ProductObject,
    ing_radio: any,
    ing_checkbox: any,
    productQty: Number
  ) => {
    //GET LOCALSTORAGE
    const jode_cart = JSON.parse(
      localStorage.getItem("jod-e_shop_cart") as any
    );
    setOrderCartLocalCount(jode_cart?.product?.length || 0);
    //PUSH INGREDIENT IN ARRAY
    addons_array.push(...ing_checkbox);
    addons_array.push(...ing_radio);
    //SUM FOR INGREDIENT PRICE
    const totalAddonsPrice = addons_array.reduce(
      (total, currentValue) =>
        (total = total + parseInt(currentValue.ingredient_price)),
      0
    );
    //ORDER OBJECT BODY FIRST TIME
    const orderBody = {
      storeId: id,
      userNumber: "",
      tableNumber: "",
      totalOrder: 1,
      totalPrice: (list?.price + totalAddonsPrice) * Number(productQty),
      orderType: "dine_in",
      product: [
        {
          productId: list,
          productQty: productQty,
          productPrice: list?.price,
          adonsPrice: totalAddonsPrice,
          totalPrice: (list?.price + totalAddonsPrice) * Number(productQty),
          addonsList: addons_array,
          productNote: "",
        },
      ],
    };
    //PRODUCT INDEX IF IT'S EXIST
    const productExistId = jode_cart?.product?.find(
      (x: any) => x?.productId?._id === list?._id
    );
    //CHECK IF CART EXISTS THE UPDATE OR ADD
    if (jode_cart === null) {
      //SET OBJECT IN LOCALSTORAGE
      localStorage.setItem("jod-e_shop_cart", JSON.stringify(orderBody));
      setOrderCartLocalCount(orderBody?.product?.length || 0);
      setSelectedProduct(null);
    } else if (productExistId !== undefined) {
      //IF PRODUCT EXIST IN CART
      const addonsIndex = jode_cart?.product?.findIndex(
        (x: any) => x.productId._id === list._id
      );
      //ORDER OBJECT BODY
      const updateProduct = {
        productId: productExistId.productId,
        productQty: productExistId.productQty + 1,
        productPrice: productExistId.productPrice,
        adonsPrice: productExistId.adonsPrice,
        totalPrice:
          (productExistId.adonsPrice + productExistId.productPrice) *
          (productExistId.productQty + 1),
        addonsList: productExistId.addonsList,
        productNote: "",
      };
      //ADD ORDER BODY IN LOCALSTORAGE
      jode_cart.product[addonsIndex] = updateProduct;
      //SUM UP PRODUCT PRICE
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      const updatOrder = {
        storeId: id,
        userNumber: "",
        tableNumber: "",
        totalOrder: jode_cart?.totalOrder,
        totalPrice: totalPrice,
        orderType: "dine_in",
        product: [...jode_cart?.product],
      };
      //SET OBJECT IN LOCALSTORAGE WITH MULTIPLE PRODUCTS
      localStorage.setItem("jod-e_shop_cart", JSON.stringify(updatOrder));
      setSelectedProduct(null);
    } else {
      //PRODUCT INCREMENT OBJECT
      const addProduct = {
        productId: list,
        productQty: productQty,
        productPrice: list?.price,
        adonsPrice: totalAddonsPrice,
        totalPrice: (list?.price + totalAddonsPrice) * Number(productQty),
        addonsList: addons_array,
        productNote: "",
      };
      //ORDER BODU INCREMENT OBJECT
      const orderBody2 = {
        storeId: id,
        userNumber: "",
        tableNumber: "",
        totalOrder: jode_cart?.totalOrder + 1,
        totalPrice:
          jode_cart?.totalPrice +
          (list?.price + totalAddonsPrice) * Number(productQty),
        orderType: "dine_in",
        product: [...jode_cart?.product, addProduct],
      };
      //SET OBJECT IN LOCALSTORAGE WITH MULTIPLE PRODUCTS
      localStorage.setItem("jod-e_shop_cart", JSON.stringify(orderBody2));
      setOrderCartLocalCount(orderBody2?.product?.length || 0);
      setSelectedProduct(null);
    }
  };

  const onCloseViewProduct = () => {
    setSelectedProduct(null);
  };

  const onCartView = () => {
    setSearchParams({ view: "CART" });
    setIsCartOpen(true);
  };

  const onCartClose = () => {
    setSearchParams({ view: "" });
    setIsCartOpen(false);
  };

  const onTableSelectClose = () => {
    setIsTableSelectionopen(!isTableSelectionopen);
    setSearchParams({ view: "" });
  };

  const onCheckUser = () => {
    setIsCheckUserOpen(!isCheckUserOpen);
  };

  React.useEffect(() => {
    paramsSearch();
    //USER PRODUCTS
    const jode_cart = JSON.parse(
      localStorage.getItem("jod-e_shop_cart") as any
    );
    setOrderCartLocalCount(jode_cart?.product?.length || 0);
    checklocalStorage();
  }, [paramsSearch, checklocalStorage, setOrderCartLocalCount]);

  React.useEffect(() => {
    fetchShopDetails();
    fetchProductList();
    fetchOrderDetails();
    fetchShopCategorieList();
  }, [
    fetchShopDetails,
    fetchProductList,
    fetchOrderDetails,
    fetchShopCategorieList,
  ]);

  return (
    <>
      <section className="fixed inset-0 mx-auto max-w-xl md:max-w-xl lg:max-w-5xl 2xl:max-w-7xl">
        <div className="relative mx-auto h-full overflow-auto bg-white px-3 scrollbar-hide sm:max-w-3xl sm:shadow-2xl">
          <ShopViewHeader
            back={false}
            search={false}
            toggle={true}
            onOpenCheckUser={onCheckUser}
          />
          <ShopDetailBox
            shopDetails={shopDetails}
            onLoading={isShopDetailsLoading}
          />
          <BannerView shopDetails={shopDetails} />
          <ProductViewBox
            productsList={searchProduct ? productSerList : productsList}
            onViewProduct={onViewProduct}
            onLoading={isProductListLoading}
          >
            <div ref={ref}>
              <div
                className="relative mb-7 h-10 w-full xl:w-auto"
                onClick={() => ref.current.scrollIntoView()}
              >
                <SearchIcon className="absolute inset-y-0 left-3 my-auto h-4 w-4 text-gray" />
                <input
                  type="search"
                  className="w-full rounded-md border-none bg-cultured pl-9 font-light placeholder:font-light placeholder:text-gray focus:ring-0 xl:w-[300px]"
                  placeholder="Search For Dishes..."
                  onChange={(e) => searchProductFilter(e.target.value)}
                />
              </div>
              {shopDetails?.isFilterProvide && (
                <div className="flex gap-5 border-b-2 border-cultured pb-3">
                  <div className="flex gap-2">
                    <img
                      className="h-5 w-5"
                      src="/assets/shop/veg.png"
                      alt=""
                    />
                    <ToggleButton
                      checked={isVeg}
                      activeColor={"bg-green"}
                      onClick={() => {
                        setIsVeg(!isVeg);
                        onVegNonVegFilter(!isVeg, "veg");
                      }}
                    />
                  </div>
                  <div className="flex gap-2">
                    <img
                      className="h-5 w-5"
                      src="/assets/shop/non-veg.png"
                      alt=""
                    />
                    <ToggleButton
                      checked={isNonVeg}
                      activeColor={"bg-red"}
                      onClick={() => {
                        setIsNonVeg(!isNonVeg);
                        onVegNonVegFilter(!isNonVeg, "nonveg");
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </ProductViewBox>
        </div>
        <BottomToggle
          onCart={onCartView}
          count={orderCartLocalCount}
          categorieList={categorieList}
        />
        {selectedProduct !== null && (
          <SingleProductView
            addCart={onAddToCart}
            onClose={onCloseViewProduct}
            selectedProduct={selectedProduct}
          />
        )}
        {isCartOpen && (
          <CartView
            onClose={onCartClose}
            onStoreData={shopDetails}
            onCartCount={setOrderCartLocalCount}
          />
        )}
        {isCheckUserOpen && (
          <CheckUser onClose={onCheckUser} isOpen={isCheckUserOpen} />
        )}
        {ordersDetails !== null && (
          <BottomOrderTab ordersDetails={ordersDetails} />
        )}
        <TableSelection
          onStoreData={shopDetails}
          onOpen={isTableSelectionopen}
          onClose={() => onTableSelectClose()}
        />
      </section>
    </>
  );
};

export default ViewShop;
