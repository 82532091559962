import React from "react";
import { PlusIcon } from "@heroicons/react/outline";

interface Props {
  isOpen: boolean;
  onOpen?: any;
}

const AddCategorie: React.FC<Props> = ({ isOpen, onOpen }) => {
  const onInputOpenHandel = onOpen;

  return (
    <>
      <div className="flex h-24 w-40 rounded-2xl border-2 border-dashed border-gray md:w-48">
        <div
          className="m-auto text-center"
          onClick={() => onInputOpenHandel(!isOpen)}
        >
          <div className="m-auto mb-1 flex h-7 w-7 cursor-pointer rounded-full bg-gray">
            <PlusIcon className="m-auto h-4 w-4 text-white" />
          </div>
          <p className="text-xs">Add Category</p>
        </div>
      </div>
    </>
  );
};

export default AddCategorie;
