import React from "react";

const FileIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.4804 77.6973C47.7169 79.4865 30.9026 79.4865 14.1391 77.6973C7.45472 76.9839 2.17505 71.7042 1.46164 65.0193C-0.327547 48.2557 -0.327547 31.442 1.46164 14.6785C2.17505 7.99415 7.45472 2.71448 14.1391 2.00107C30.9026 0.211882 47.7163 0.211882 64.4798 2.00107C71.1648 2.71448 76.4445 7.99415 77.1579 14.6785C78.947 31.442 78.947 48.2557 77.1579 65.0193C76.4445 71.7042 71.1654 76.9839 64.4804 77.6973Z"
        fill="white"
      />
      <path
        d="M49.4625 39.12H30.7063C29.7169 39.12 28.9155 38.3239 28.9155 37.341C28.9155 36.3582 29.7169 35.562 30.7063 35.562H49.4604C50.4497 35.562 51.2511 36.3582 51.2511 37.341C51.2533 38.3217 50.4519 39.12 49.4625 39.12Z"
        fill="black"
      />
      <path
        d="M49.4625 53.8624H30.7063C29.7169 53.8624 28.9155 53.0663 28.9155 52.0834C28.9155 51.1006 29.7169 50.3044 30.7063 50.3044H49.4604C50.4497 50.3044 51.2511 51.1006 51.2511 52.0834C51.2533 53.0663 50.4519 53.8624 49.4625 53.8624Z"
        fill="black"
      />
      <path
        d="M45.8271 46.4916H30.7063C29.7169 46.4916 28.9155 45.6954 28.9155 44.7126C28.9155 43.7297 29.7169 42.9336 30.7063 42.9336H45.8271C46.8164 42.9336 47.6178 43.7297 47.6178 44.7126C47.62 45.6954 46.8164 46.4916 45.8271 46.4916Z"
        fill="black"
      />
      <path
        d="M46.6307 18.1899C45.7926 17.3572 44.6564 16.8894 43.4726 16.8894H29.272C24.9756 16.8894 21.4956 20.3487 21.4956 24.6149V57.7248C21.4956 61.7142 24.7487 64.946 28.7622 64.946H51.7113C55.4872 64.946 58.5481 61.9052 58.5481 58.154V31.057C58.5481 30.4004 58.2845 29.7694 57.818 29.3059L46.6307 18.1899ZM55.0919 58.154C55.0897 58.3794 55.066 59.5682 54.1004 60.5275C53.4869 61.1369 52.6423 61.5125 51.7113 61.5125H28.7644C26.6583 61.5125 24.9518 59.8171 24.9518 57.7248V24.6149C24.9518 22.2436 26.8851 20.3229 29.272 20.3229H42.5092C43.1788 20.3229 43.7254 20.8594 43.734 21.5247L43.8442 29.9819C43.8593 31.0399 44.7212 31.8918 45.7861 31.8982L53.7721 31.9476C54.5022 31.9519 55.0919 32.542 55.0919 33.2674V58.154Z"
        fill="black"
      />
    </svg>
  );
};

export default FileIcon;
