import React from "react";
import { ShopOrderObject } from "types";
import ShowMoreText from "react-show-more-text";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { IMAGE_URL } from "config/Api";

interface Props {
  ordersDetails: ShopOrderObject | null;
  onLoading: Boolean | false;
}

const OrderDisplayBox: React.FC<Props> = ({ onLoading, ordersDetails }) => {
  const [viewOrderIngredient, setViewOrderIngredient] =
    React.useState<Number | null>(null);
  const [imageLoading, setImageLoading] = React.useState(true);

  const onViewOrderIngredient = (index: any) => {
    if (viewOrderIngredient === index) {
      setViewOrderIngredient(null);
    } else {
      setViewOrderIngredient(index);
    }
  };

  if (onLoading) {
    return (
      <>
        <div className="animate-pulse pb-64">
          <div className="mb-2 rounded-md bg-cultured p-2">
            <div className="flex">
              <div className="mr-2 h-24 w-24 rounded-md bg-black/20 object-cover"></div>
              <div className="mt-4 flex-1 space-y-5">
                <div className="h-2 w-2/4 rounded bg-black/20"></div>
                <div className="space-y-2">
                  <div className="grid space-y-1">
                    <div className="h-2 w-11/12 rounded bg-black/20"></div>
                    <div className="h-2 rounded bg-black/20"></div>
                    <div className="h-2 w-11/12 rounded bg-black/20"></div>
                  </div>
                  <div className="flex">
                    <div className="h-2 w-10 rounded bg-black/20"></div>
                    <div className="ml-3 h-2 w-16 rounded bg-black/20"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 flex items-start justify-between pb-1">
              <div className="h-2 w-8 rounded bg-black/20"></div>
              <div className="h-2 w-14 rounded bg-black/20"></div>
            </div>
            <div className="flex items-center justify-between border-t-2 border-dashed border-gray/30 pt-1">
              <div className="h-2 w-12 rounded bg-black/20"></div>
              <div className="h-2 w-10 rounded bg-black/20"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="pb-64">
        {ordersDetails?.product.map((list: any, index: any) => {
          return (
            <div className="mb-5 rounded-md bg-cultured p-2" key={index}>
              <div className="flex">
                <div
                  className={`${
                    imageLoading
                      ? `mr-2 block h-24 w-24 animate-pulse rounded-md bg-black/20`
                      : `hidden`
                  }`}
                />
                <img
                  className={`${
                    imageLoading
                      ? `hidden`
                      : `mr-2 block h-24 w-24 rounded-md object-cover`
                  }`}
                  src={`${IMAGE_URL}${list?.productId?.image[0]}`}
                  alt={list?.productId?.name}
                  onLoad={() => setImageLoading(false)}
                />
                <div className="w-fit">
                  <h2 className="mb-1 text-lg font-bold">
                    <ShowMoreText
                      lines={1}
                      more=""
                      less=""
                      className="w-full"
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      {list?.productId?.name}
                    </ShowMoreText>
                  </h2>
                  <h2>
                    <ShowMoreText
                      lines={2}
                      more=""
                      less=""
                      className="text-sm"
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      {list?.productId?.description}
                    </ShowMoreText>
                  </h2>
                  <div className="flex items-center">
                    {list?.productId?.isVeg ? (
                      <img
                        className="h-6 w-6"
                        src="https://img.icons8.com/color/48/000000/vegetarian-food-symbol.png"
                        alt="non-veg"
                      />
                    ) : (
                      <img
                        className="h-6 w-6"
                        src="https://img.icons8.com/color/48/000000/non-vegetarian-food-symbol.png"
                        alt="veg"
                      />
                    )}
                    <p className="ml-2 text-lg font-bold">
                      ₹ {list?.productId?.price}
                    </p>
                  </div>
                </div>
              </div>
              {list?.addonsList.length !== 0 && (
                <div className="flex items-start justify-between">
                  {viewOrderIngredient === index ? (
                    <div className="w-full pb-1">
                      {list?.addonsList.map((ing: any, index2: any) => {
                        return (
                          <div
                            className="my-0.5 flex items-center justify-between text-sm font-medium"
                            key={index2}
                          >
                            <h2 className="text-gray">
                              {ing?.ingredient_name}
                            </h2>
                            <h3>₹ {ing?.ingredient_price}</h3>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="w-full pb-1">
                      <div className="my-0.5 flex items-center justify-between text-sm font-medium">
                        <h2 className="text-gray">Addons</h2>
                        <h3>
                          ₹{" "}
                          {parseFloat(list?.totalPrice) -
                            parseFloat(list?.productId?.price)}
                        </h3>
                      </div>
                    </div>
                  )}

                  {viewOrderIngredient === index ? (
                    <div className="pt-1">
                      <ChevronDownIcon
                        className="h-4 w-4 cursor-pointer"
                        onClick={() => onViewOrderIngredient(index)}
                      />
                    </div>
                  ) : (
                    <div className="pt-1">
                      <ChevronRightIcon
                        className="h-4 w-4 cursor-pointer"
                        onClick={() => onViewOrderIngredient(index)}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="flex items-center justify-between border-t-2 border-dashed border-gray/30 pt-0.5">
                <div className="h-fit w-fit">
                  <h2 className="text-sm font-medium">
                    Quantity: {list?.productQty}
                  </h2>
                </div>
                <div className="flex justify-end font-medium">
                  <h2 className="pr-3">₹ {list?.totalPrice}</h2>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OrderDisplayBox;
