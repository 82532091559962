import React from "react";
import axios from "axios";
import API from "config/Api";
import {
  XIcon,
  ViewListIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { ShopCategorie } from "types";
import { toast } from "react-toastify";
import { useDrag } from "@use-gesture/react";
import { WaiterIcon } from "components/icons";
import { HashLink } from "react-router-hash-link";
import { animated, useSpring } from "@react-spring/web";
import useOutsideClick from "lib/hooks/useOutsideClick";

interface Props {
  onCart?: any;
  count: number;
  categorieList: ShopCategorie | [];
}

const data = ["clean table", "extra tissue", "bring bill"];

const BottomToggle: React.FC<Props> = ({ count, onCart, categorieList }) => {
  const ref = React.useRef<any>();
  const [muneToggle, setMuneToggle] = React.useState(false);
  const [isWaiterNoteOpen, setIsWaiterNoteOpen] = React.useState(false);
  const [initialDrag, setinitialDrag] = React.useState({ x: 0, y: 0 });
  const cartOpenHandler = onCart;
  const jode_table = JSON.parse(
    localStorage.getItem("jod-e_table_selection") as any
  );

  //ANIMATION FOR DRAG
  const posLogo = useSpring(initialDrag);
  const bindLogo = useDrag((params) => {
    posLogo.x.set(params.offset[0]);
    posLogo.y.set(params.offset[1]);
    setinitialDrag({ x: params.offset[0], y: params.offset[1] });
  });

  const onWaiterCall = (note: string) => {
    const body = {
      tableId: jode_table._id,
      note: note,
    };
    const url = API + `waiter/call`;
    axios
      .post(url, body)
      .then((response) => {
        if (response?.data?.success === true) {
          setIsWaiterNoteOpen(false);
          toast.success(response?.data?.message);
        } else {
          toast.warn(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  };

  const onMunuToggle = () => {
    setMuneToggle(!muneToggle);
  };

  useOutsideClick(ref, () => {
    if (muneToggle) {
      setMuneToggle(false);
    }
    if (isWaiterNoteOpen) {
      setIsWaiterNoteOpen(false);
    }
  });

  return (
    <>
      <div className="">
        <animated.div
          {...bindLogo()}
          style={{
            x: posLogo.x,
            y: posLogo.y,
            touchAction: "none",
          }}
        >
          {isWaiterNoteOpen && (
            <div
              ref={ref}
              className="absolute bottom-44 right-5 mx-auto mb-1 grid h-auto w-fit overflow-auto rounded-lg bg-raisin-black text-right capitalize text-white"
            >
              <div className="select-none px-2 text-sm">
                {data.map((items, index) => {
                  return (
                    <h2
                      key={index}
                      onClick={() => onWaiterCall(items)}
                      className="border-b-2 border-white/20 px-3 py-2 text-sm"
                    >
                      {items}
                    </h2>
                  );
                })}
              </div>
            </div>
          )}
          <div
            onClick={() => setIsWaiterNoteOpen(true)}
            className="absolute bottom-32 right-5 mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-raisin-black shadow-lg"
          >
            <WaiterIcon className="h-9 w-9 text-gold/70" />
          </div>
        </animated.div>
      </div>

      <div className="absolute bottom-2 mx-auto w-full">
        {muneToggle ? (
          <div
            ref={ref}
            className="mx-auto grid h-96 w-72 overflow-auto rounded-lg bg-raisin-black text-center text-white scrollbar-hide"
          >
            <div className="select-none px-6">
              {categorieList
                ?.sort((a: any, b: any) => (a?.priority > b?.priority ? 1 : -1))
                .filter((active) => active.is_active)
                .map((items, index) => {
                  return (
                    <h2
                      className="flex border-b-2 border-white/20 p-2"
                      key={index}
                    >
                      <HashLink
                        smooth
                        className="w-full"
                        to={`#${items?.name}`}
                        onClick={onMunuToggle}
                      >
                        {items?.name}
                      </HashLink>
                    </h2>
                  );
                })}
            </div>
            <button
              className="flex w-full select-none justify-center rounded-lg bg-raisin-black p-3 text-white"
              onClick={onMunuToggle}
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>
        ) : (
          <div className="flex justify-center">
            <button
              className="mr-3 flex w-60 items-center justify-center rounded-lg bg-raisin-black p-3 text-white"
              onClick={onMunuToggle}
            >
              <ViewListIcon className="mr-2 h-5 w-5" />
              MENU
            </button>
            <button
              className="select-none rounded-lg bg-raisin-black p-3 text-white"
              onClick={cartOpenHandler}
            >
              <p className="absolute top-1 ml-2 flex h-5 w-5 items-center justify-center rounded-full bg-[#FF0000] text-sm">
                {count}
              </p>
              <ShoppingCartIcon className="h-6 w-6 select-none" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default BottomToggle;
