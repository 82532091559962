import React from "react";

const TelegramIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M65.1004 77.9004C48.3369 79.6896 31.5226 79.6896 14.7591 77.9004C8.07472 77.187 2.79504 71.9073 2.08164 65.2224C0.292448 48.4589 0.292448 31.6451 2.08164 14.8816C2.79504 8.19727 8.07472 2.9176 14.7591 2.2042C31.5226 0.415007 48.3363 0.415007 65.0998 2.2042C71.7848 2.9176 77.0644 8.19727 77.7778 14.8816C79.567 31.6451 79.567 48.4589 77.7778 65.2224C77.0644 71.9073 71.7854 77.187 65.1004 77.9004Z"
        fill="url(#paint0_linear_2307_937)"
      />
      <path
        d="M32.4779 59.3923C31.0672 59.3923 31.307 58.8596 30.8204 57.5164L26.6724 43.865L58.6029 24.9219"
        fill="#C8DAEA"
      />
      <path
        d="M32.4785 59.3921C33.5671 59.3921 34.0478 58.8942 34.6556 58.3035L40.4611 52.6584L33.2194 48.2915"
        fill="#A9C9DD"
      />
      <path
        d="M33.2181 48.2926L50.7654 61.2568C52.768 62.3617 54.2128 61.7895 54.7117 59.3979L61.8544 25.7392C62.5855 22.8074 60.7368 21.4772 58.821 22.3469L16.8795 38.5194C14.0167 39.6678 14.0337 41.265 16.3578 41.9766L27.1209 45.3362L52.0386 29.6158C53.215 28.9025 54.2948 29.2857 53.4087 30.0723"
        fill="url(#paint1_linear_2307_937)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2307_937"
          x1="53.0192"
          y1="13.9518"
          x2="33.4242"
          y2="59.6473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37AEE2" />
          <stop offset="1" stopColor="#1E96C8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2307_937"
          x1="45.9192"
          y1="39.439"
          x2="52.7144"
          y2="54.9213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFF7FC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TelegramIcon;
