import React from "react";
import axios from "axios";
import { ShopCategorie } from "types";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import API, { IMAGE_URL } from "config/Api";
import { Button, HeroIcon } from "components/ui";
import { useNavigate, useParams } from "react-router-dom";
import { PlusIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";

interface Props {}

const UpdateProduct: React.FC<Props> = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const shopData = JSON.parse(localStorage.getItem("jd-u-shop")!);
  const [categorieLists, setCategorieLists] = React.useState<
    ShopCategorie | []
  >([]);
  const [productAddons, setProductAddons] = React.useState<any[]>([
    {
      addons_name: "",
      is_multiple: true,
      ingredient: [{ ingredient_name: "", ingredient_price: "" }],
    },
  ]);
  const [imgPath, setImgPath] = React.useState<any>([]);
  const [selectedImage, setSelectedImage] = React.useState<any>([]);
  const [isAddonAdd, setIsAddonAdd] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const formData = new FormData();

  //API CALL HERE
  const fetchCategorieLists = React.useCallback(() => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `category/getallbystore/${authData?.storeId}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setCategorieLists(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  }, []);

  const fetchProductDetails = React.useCallback(() => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `product/${id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setProductAddons(response?.data?.data?.addons);
          setSelectedImage(`${IMAGE_URL}${response?.data?.data?.image}`);
          setValue("product_name", response?.data?.data?.name);
          setValue("product_image", response?.data?.data?.image[0]);
          setValue("product_categorie", response?.data?.data?.category_id?._id);
          setValue("product_price", response?.data?.data?.price);
          setValue("discount_price", response?.data?.data?.discount);
          setValue("shipping_price", response?.data?.data?.shippingCharge);
          setValue("is_veg", response?.data?.data?.isVeg);
          setValue("isInStock", response?.data?.data?.isInStock);
          setValue("product_description", response?.data?.data?.description);
          setValue(
            "product_short_description",
            response?.data?.data?.descriptionInShort
          );
          if (response?.data?.data?.addons[0].addons_name !== "") {
            setIsAddonAdd(false);
          }
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  }, [id, setValue]);

  const onCheckIngredient = (data: any[]) => {
    let array_adons = data.filter((addone) => {
      let ingredient = addone.ingredient;
      let isValidAddons = true;
      ingredient = ingredient.filter((ingr: any) => {
        let isValidIngredient = true;
        if (
          ingr.ingredient_name.trim() === "" ||
          ingr.ingredient_price.trim() === ""
        ) {
          isValidIngredient = false;
        }
        return isValidIngredient;
      });
      if (ingredient.length <= 0 || addone.addons_name.trim() === "") {
        isValidAddons = false;
      }
      addone.ingredient = ingredient;
      return isValidAddons;
    });
    return array_adons;
  };

  const onSubmit = handleSubmit(async (data: any) => {
    setIsLoading(true);
    let array_adons = onCheckIngredient(productAddons);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    //UPDATE PRODUCT WITHOUT NEW IMAGE
    if (imgPath.length === 0) {
      // PRODUCT UPDATE API
      const body = {
        store_id: shopData?._id,
        category_id: data?.product_categorie,
        name: data?.product_name,
        descriptionInShort: data?.product_short_description,
        description: data?.product_description,
        price: parseInt(data?.product_price),
        // discount: parseInt(data?.discount_price),
        // shippingCharge: parseInt(data?.shipping_price),
        image: [data?.product_image],
        addons: array_adons,
        isVeg: data?.is_veg,
        isInStock: data?.isInStock,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${authData?.token}`,
        },
      };
      const url = API + `editproduct/${id}`;
      axios
        .patch(url, body, config)
        .then((response) => {
          if (response?.data?.success) {
            navigate(`/shop/product`);
            toast.success(`Product Updated Successfully.`);
          }
        })
        .catch((err) => {
          console.log("error here", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      //UPDATE PRODUCT WITH NEW IMAGE

      imgPath.forEach((file: string | Blob) => {
        formData.append("image", file);
      });

      const config = {
        headers: {
          Authorization: `Bearer ${authData?.token}`,
        },
        params: {
          //IT'S CREATE INDIVIDUAL FOLDER IN AWS FOR SHOP TO ADD PHOTOS.
          directory: shopData?.shop_name,
        },
      };
      //IMAGE UPLOAD API
      let imgUrl = API + `common/image`;
      axios
        .post(imgUrl, formData, config)
        .then((response) => {
          if (response?.data?.success) {
            let image_path = response?.data?.data?.files[0]?.key;
            // PRODUCT UPDATE API
            const body = {
              store_id: shopData?._id,
              category_id: data?.product_categorie,
              name: data?.product_name,
              descriptionInShort: data?.product_short_description,
              description: data?.product_description,
              price: parseInt(data?.product_price),
              // discount: parseInt(data?.discount_price),
              // shippingCharge: parseInt(data?.shipping_price),
              image: [image_path],
              addons: array_adons,
              isVeg: data?.is_veg,
              isInStock: data?.isInStock,
            };

            const config = {
              headers: {
                Authorization: `Bearer ${authData?.token}`,
              },
            };
            const url = API + `editproduct/${id}`;
            axios
              .patch(url, body, config)
              .then((response) => {
                if (response?.data?.success) {
                  navigate(`/shop/product`);
                  toast.success(`Product Updated Successfully.`);
                }
              })
              .catch((err) => {
                console.log("error here", err);
              });
          } else {
            toast.warning("Image Uploading Fail Try Again.");
          }
        })
        .catch((err) => {
          console.log("error here", err.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  });
  //API CALL END HERE

  const onRemoveImage = () => {
    setImgPath([]);
    setSelectedImage([]);
  };

  const addImage = (event: any) => {
    const reader = new FileReader(),
      filesToDisplay = event.target.files;
    reader.onload = function () {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(filesToDisplay[0]);
    event.preventDefault();
    const files = event.target.files;
    const tempImages = [...imgPath] as any[];

    for (let index = 0; index < files.length; index++) {
      const file = files[index] as any[];
      const duplicate = false;
      if (!duplicate) tempImages.push(file);
    }
    setImgPath([...tempImages]);
  };

  const handleRemoveClick = (index: number) => {
    const list = [...productAddons];
    list.splice(index, 1);
    setProductAddons(list);
  };

  const handleAddClick = () => {
    setIsAddonAdd(false);
    setProductAddons([
      ...productAddons,
      {
        addons_name: "",
        is_multiple: true,
        ingredient: [{ ingredient_name: "", ingredient_price: "" }],
      },
    ]);
  };

  const handleInput = (e: EventTarget & HTMLInputElement, index: number) => {
    const { name, value } = e;
    const list = [...productAddons] as any;
    list[index][name] = value;
    setProductAddons(list);
  };

  const handleIngre = (
    e: EventTarget & HTMLInputElement,
    index: number,
    indexOfIndex: number
  ) => {
    const { name, value } = e;
    const list = [...productAddons] as any;
    list[index].ingredient[indexOfIndex][name] = value;
    setProductAddons(list);
  };

  const handleCheckbox = (e: EventTarget & HTMLInputElement, index: number) => {
    const { name, checked } = e;
    const list = [...productAddons] as any;
    list[index][name] = checked;
    setProductAddons(list);
  };

  const oningredentAdd = (i: number) => {
    const d1 = {
      ingredient_name: "",
      ingredient_price: "",
    };
    productAddons[i].ingredient.push(d1);
    setProductAddons([...productAddons]);
  };

  const onIngredentDelete = (index: number, indexOfIndex: number) => {
    const list = [...productAddons][index].ingredient;
    list.splice(indexOfIndex, 1);
    setProductAddons([...productAddons]);
  };

  const onCancel = () => {
    navigate(`/shop/product`);
  };

  React.useEffect(() => {
    fetchProductDetails();
    fetchCategorieLists();
  }, [fetchProductDetails, fetchCategorieLists]);

  return (
    <>
      <DashboardHeader title={"Update Product"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <h2 className="mb-3 hidden text-xl font-bold md:block">
          Update Product
        </h2>
        <div className="rounded-2xl bg-cultured p-2 md:p-5">
          <form className="space-y-4" onSubmit={onSubmit}>
            <div className="mt-7 lg:flex">
              <div className="mb-2 grid">
                {selectedImage.length === 0 ? (
                  <div className="border-sky-500 mb-7 flex h-40 w-32 items-center justify-center rounded-[12px] border-2 border-dashed border-gray">
                    <label
                      htmlFor="product_image"
                      className="cursor-pointer rounded-full bg-philippine-gray"
                    >
                      <HeroIcon
                        className="h-10 w-10 p-2 text-white"
                        icon="PlusIcon"
                      />
                      <input
                        className="hidden"
                        type="file"
                        id="product_image"
                        {...register("product_image", { required: true })}
                        accept="image/*"
                        onChange={addImage}
                      />
                    </label>
                  </div>
                ) : (
                  <div className="relative h-40 w-32">
                    <div
                      className="absolute right-[-15px] top-[-10px] flex h-6 w-6 items-center justify-center rounded-full bg-gray"
                      onClick={onRemoveImage}
                    >
                      <XIcon className="h-4 w-4 cursor-pointer" />
                    </div>
                    <img
                      className="h-full w-full rounded-[12px] object-cover"
                      src={selectedImage}
                      alt=""
                    />
                  </div>
                )}
                {errors.product_image && (
                  <p className="flex justify-end text-sm text-[#CC3300]">
                    This field is required.
                  </p>
                )}
              </div>

              <div className="w-full md:ml-5">
                <div className="md:flex">
                  <div className="mr-7 mb-7 grid w-80">
                    <label
                      className="text-md mb-1 font-bold"
                      htmlFor="product_name"
                    >
                      Product Name
                    </label>
                    <input
                      className="w-11/12 rounded-md border-0 md:w-full"
                      type="text"
                      id="product_name"
                      placeholder="Product Name"
                      {...register("product_name", { required: true })}
                    />
                    {errors.product_name && (
                      <p className="flex justify-end text-sm text-[#CC3300]">
                        This field is required.
                      </p>
                    )}
                  </div>

                  <div className="mr-7 mb-7 grid w-80">
                    <label
                      className="text-md mb-1 font-bold"
                      htmlFor="product_categorie"
                    >
                      Select Category
                    </label>
                    <select
                      className="w-11/12 rounded-md border-0 md:w-full"
                      id="product_categorie"
                      {...register("product_categorie", { required: true })}
                    >
                      {categorieLists
                        .filter((active) => active.is_active)
                        .map((list, index) => (
                          <option key={index} value={`${list._id}`}>
                            {list.name}
                          </option>
                        ))}
                    </select>
                    {errors.product_categorie && (
                      <p className="flex justify-end text-sm text-[#CC3300]">
                        This field is required.
                      </p>
                    )}
                  </div>
                </div>

                <div className="md:flex">
                  <div className="mb-7 mr-7 grid w-80">
                    <label
                      className="text-md mb-1 font-bold"
                      htmlFor="product_price"
                    >
                      Product Price
                    </label>
                    <input
                      className="w-11/12 rounded-md border-0 md:w-full"
                      type="number"
                      id="product_price"
                      placeholder="Product Price"
                      {...register("product_price", { required: true })}
                    />
                    {errors.product_price && (
                      <p className="flex justify-end text-sm text-[#CC3300]">
                        This field is required.
                      </p>
                    )}
                  </div>
                  {/* <div className="mr-7 mb-7 grid w-80">
                    <label
                      className="text-md mb-1 font-bold"
                      htmlFor="discount_price"
                    >
                      Discount Price
                    </label>
                    <input
                      className="w-11/12 rounded-md border-0 md:w-full"
                      type="number"
                      // name="discount_price"
                      id="discount_price"
                      placeholder="Discount Price"
                      {...register("discount_price", { required: true })}
                    />
                    {errors.discount_price && (
                      <p className="flex justify-end text-sm text-[#CC3300]">
                        This field is required.
                      </p>
                    )}
                  </div>

                  <div className="mr-7 mb-7 grid w-80">
                    <label
                      className="text-md mb-1 font-bold"
                      htmlFor="shipping_price"
                    >
                      Shipping Price
                    </label>
                    <input
                      className="w-11/12 rounded-md border-0 md:w-full"
                      type="number"
                      // name="shipping_price"
                      id="shipping_price"
                      placeholder="shipping Price"
                      {...register("shipping_price", { required: true })}
                    />
                    {errors.shipping_price && (
                      <p className="flex justify-end text-sm text-[#CC3300]">
                        This field is required.
                      </p>
                    )}
                  </div> */}

                  <div className="flex">
                    <div className="mr-7 mb-7 grid w-40">
                      <label className="text-md mb-1 font-bold">Non-Veg?</label>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input h-5 w-9 cursor-pointer appearance-none rounded-full bg-gold shadow-sm focus:outline-none"
                          type="checkbox"
                          id="is_veg"
                          {...register("is_veg")}
                        />
                      </div>
                      {errors.is_veg && (
                        <p className="flex justify-end text-sm text-[#CC3300]">
                          This field is required.
                        </p>
                      )}
                    </div>
                    <div className="mr-7 mb-7 grid w-40">
                      <label className="text-md mb-1 font-bold">
                        Stock Availability
                      </label>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input h-5 w-9 cursor-pointer appearance-none rounded-full bg-gold shadow-sm focus:outline-none"
                          type="checkbox"
                          id="isInStock"
                          {...register("isInStock")}
                        />
                      </div>
                      {errors.isInStock && (
                        <p className="flex justify-end text-sm text-[#CC3300]">
                          This field is required.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:flex md:w-10/12">
              <div className="mt-7 grid md:mr-7 md:w-2/4">
                <label
                  className="text-md mb-1 font-bold"
                  htmlFor="product_description"
                >
                  Description
                </label>
                <textarea
                  className="h-28 w-full rounded-md border-0"
                  id="product_description"
                  placeholder="Description"
                  {...register("product_description", { required: true })}
                ></textarea>
                {errors.product_description && (
                  <p className="flex justify-end text-sm text-[#CC3300]">
                    This field is required.
                  </p>
                )}
              </div>

              <div className="mt-7 grid md:w-2/4">
                <label
                  className="text-md mb-1 font-bold"
                  htmlFor="product_short_description"
                >
                  Short Description
                </label>
                <textarea
                  className="h-28 w-full rounded-md border-0"
                  id="product_short_description"
                  placeholder="Short Description"
                  {...register("product_short_description", {
                    required: true,
                  })}
                ></textarea>
                {errors.product_short_description && (
                  <p className="flex justify-end text-sm text-[#CC3300]">
                    This field is required.
                  </p>
                )}
              </div>
            </div>
          </form>

          {isAddonAdd && (
            <button
              className="my-5 h-10 w-40 rounded-md bg-gold font-bold"
              onClick={handleAddClick}
            >
              Add Addons
            </button>
          )}
          {productAddons.length > 0 &&
            productAddons.map((x, i) => {
              return (
                <div
                  className="mt-7 w-full rounded-xl bg-white p-4 md:w-10/12"
                  key={i}
                >
                  <div className="mb-3 flex justify-between">
                    <h2 className="text-md mb-1 font-bold">Add Ons</h2>
                    <div className="flex ">
                      {productAddons.length !== 1 && (
                        <div
                          className="mr-7 flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-cultured"
                          onClick={() => handleRemoveClick(i)}
                        >
                          <TrashIcon className="h-4 w-4 text-gray" />
                        </div>
                      )}
                      {productAddons.length - 1 === i && (
                        <div
                          className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-cultured"
                          onClick={handleAddClick}
                        >
                          <PlusIcon className="h-4 w-4 text-gray" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mr-7 w-full md:w-64">
                      <input
                        className="w-full rounded-md border-0 bg-cultured"
                        type="text"
                        name="addons_name"
                        placeholder="Add Ons Name"
                        value={x.addons_name}
                        onChange={(e) => handleInput(e.target, i)}
                      />
                    </div>
                    <div className="flex items-center">
                      <input
                        className="mr-2 border-0 bg-cultured"
                        type="checkbox"
                        name="is_multiple"
                        checked={x.is_multiple}
                        onChange={(e) => handleCheckbox(e.target, i)}
                      />
                      <label
                        className="text-sm text-gray"
                        htmlFor="is_multipel"
                      >
                        Multiple
                      </label>
                    </div>
                  </div>
                  {x.ingredient.map((a: any, b: number) => {
                    return (
                      <div className="mt-7 flex items-center" key={b}>
                        <div className="mr-7 w-48 md:w-64">
                          <input
                            className="w-full rounded-md border-0 bg-cultured"
                            type="text"
                            name="ingredient_name"
                            placeholder="Name"
                            value={a.ingredient_name}
                            onChange={(e) => handleIngre(e.target, i, b)}
                          />
                        </div>
                        <div className="mr-7 w-48 md:w-64">
                          <input
                            className="w-full rounded-md border-0 bg-cultured"
                            type="Number"
                            name="ingredient_price"
                            placeholder="Price"
                            value={a.ingredient_price}
                            onChange={(e) => handleIngre(e.target, i, b)}
                          />
                        </div>
                        <div
                          className="mr-7 flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-cultured"
                          onClick={() => oningredentAdd(i)}
                        >
                          <PlusIcon className="h-4 w-4 text-gray" />
                        </div>
                        <div
                          className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-cultured"
                          onClick={() => onIngredentDelete(i, b)}
                        >
                          <TrashIcon className="h-4 w-4 text-gray" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

          <div className="my-7 flex">
            <Button variant="outlined" className="mr-7" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={isLoading} onClick={onSubmit}>
              Save
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateProduct;
