import cn from "classnames";
import React, { InputHTMLAttributes } from "react";
import s from "./Input.module.css";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  type: string;
  variant?: "default" | "outlined";
  onChange?: (...args: any[]) => any;
  onKeyPress?: (...args: any[]) => any;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    className,
    type,
    variant = "default",
    children,
    onChange,
    onKeyPress,
    ...rest
  } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  const handleonKeyPress = (e: any) => {
    if (onKeyPress) {
      onKeyPress(e.key);
    }
    return null;
  };

  const rootClassName = cn(
    s.root,
    {
      [s.outlined]: variant === "outlined",
    },
    className
  );

  return (
    <input
      type={type}
      className={rootClassName}
      onChange={handleOnChange}
      onKeyPress={handleonKeyPress}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      {...rest}
    />
  );
};

export default Input;
