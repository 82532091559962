import React from "react";

const CustonlinkIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M65.1004 77.6973C48.3369 79.4865 31.5226 79.4865 14.7591 77.6973C8.07472 76.9839 2.79504 71.7042 2.08164 65.0193C0.292448 48.2557 0.292448 31.442 2.08164 14.6785C2.79504 7.99415 8.07472 2.71448 14.7591 2.00107C31.5226 0.211882 48.3363 0.211882 65.0998 2.00107C71.7848 2.71448 77.0644 7.99415 77.7778 14.6785C79.567 31.442 79.567 48.2557 77.7778 65.0193C77.0644 71.7042 71.7854 76.9839 65.1004 77.6973Z"
        fill="white"
      />
      <path
        d="M43.1417 51.8844C42.9621 52.113 42.8875 52.2319 42.7895 52.3276C40.3195 54.7859 37.9031 57.3003 35.3654 59.6863C31.7432 63.0963 26.4112 63.6211 22.4415 60.9738C16.825 57.228 16.5474 49.9228 20.5266 45.5262C23.5027 42.2398 26.8544 39.2963 29.9215 36.0893C31.6452 34.2863 33.6394 33.0991 36.0511 32.7142C39.4028 32.1801 42.4746 32.8635 44.9773 35.3172C46.2765 36.5907 46.1062 38.4777 44.6601 39.4596C43.7738 40.0614 42.3533 39.9354 41.3481 39.1191C40.3195 38.2841 39.2069 37.7849 37.8424 37.8432C36.4197 37.9039 35.1298 38.3004 34.1199 39.294C30.9711 42.3868 27.8317 45.4912 24.7552 48.654C23.5587 49.8855 22.9406 51.4529 23.2252 53.2022C23.8363 56.9714 28.0859 58.6857 31.1251 56.2997C32.963 54.8559 34.5864 53.1346 36.2587 51.4902C36.8208 50.9374 37.2337 50.7438 38.0384 51.1217C39.5778 51.8471 41.2618 52.015 43.1417 51.8844Z"
        fill="black"
      />
      <path
        d="M36.7913 29.2091C37.0875 28.8499 37.2134 28.6609 37.3721 28.5047C39.7674 26.1373 42.0929 23.6952 44.5839 21.4328C47.616 18.6759 51.2033 17.7219 55.1497 19.084C59.0262 20.4205 61.333 23.2614 61.9044 27.3058C62.3569 30.5082 61.4519 33.4657 59.2128 35.8215C56.0337 39.1638 52.745 42.4059 49.44 45.6293C45.7594 49.2189 39.6741 49.5454 35.9003 46.6952C35.1096 46.0981 34.2956 45.487 34.102 44.4351C33.8921 43.3109 34.2466 42.3662 35.2052 41.6968C35.9889 41.1464 37.2601 41.2187 38.1697 41.7645C39.0537 42.2963 39.9307 42.9703 40.901 43.2059C42.7576 43.6561 44.4836 43.087 45.8621 41.7621C48.4557 39.2711 51.0167 36.7451 53.545 34.1888C54.4314 33.2908 55.3363 32.3508 55.9684 31.2756C57.797 28.1641 56.1504 24.4952 52.682 23.8725C50.9864 23.5693 49.4516 24.1267 48.1781 25.2603C46.5548 26.7064 44.9664 28.1921 43.399 29.7012C42.9115 30.1724 42.5594 30.2866 41.8363 30.0581C40.4718 29.6289 39.0187 29.4726 37.6006 29.2161C37.414 29.1787 37.2158 29.2091 36.7913 29.2091Z"
        fill="black"
      />
    </svg>
  );
};

export default CustonlinkIcon;
