import React from "react";
import {
  BehanceIcon,
  CallIcon,
  ContactIcon,
  CustonlinkIcon,
  DribbleIcon,
  FacebookIcon,
  FileIcon,
  InstagramIcon,
  LinkedinIcon,
  MailIcon,
  MessageIcon,
  PaypalIcon,
  SkypeIcon,
  SnapchatIcon,
  SpotiifyIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsAppIcon,
  YoutubeIcon,
  MapIcon,
  EmergencyContactIcon,
  PersonalinformationIcon,
  BankDetailsIcon,
  EventIcon,
  ShopIcon,
} from "components/icons";
import icons from "types/icons";
import { ICON_V, IMAGE_URL } from "config/Api";

interface Props {
  icon: any;
  height?: number;
  width?: number;
}

const DisplayIcon: React.FC<Props> = (props) => {
  const { icon, height = 20, width = 20 } = props;
  const iconPath = ICON_V;

  const IconeSvg = {
    v1: {
      instagram: {
        icon: InstagramIcon,
      },
      call: {
        icon: CallIcon,
      },
      linkedin: {
        icon: LinkedinIcon,
      },
      googlemaps: {
        icon: MapIcon,
      },
      message: {
        icon: MessageIcon,
      },
      facebook: {
        icon: FacebookIcon,
      },
      behance: {
        icon: BehanceIcon,
      },
      createcontact: {
        icon: ContactIcon,
      },
      whatsapp: {
        icon: WhatsAppIcon,
      },
      spotify: {
        icon: SpotiifyIcon,
      },
      skype: {
        icon: SkypeIcon,
      },
      mail: {
        icon: MailIcon,
      },
      snapchat: {
        icon: SnapchatIcon,
      },
      twitter: {
        icon: TwitterIcon,
      },
      youtube: {
        icon: YoutubeIcon,
      },
      dribbble: {
        icon: DribbleIcon,
      },
      paypal: {
        icon: PaypalIcon,
      },
      telegram: {
        icon: TelegramIcon,
      },
      custom: {
        icon: CustonlinkIcon,
      },
      document: {
        icon: FileIcon,
      },
      emergencycontact: {
        icon: EmergencyContactIcon,
      },
      personalinformation: {
        icon: PersonalinformationIcon,
      },
      bankdetails: {
        icon: BankDetailsIcon,
      },
      event: {
        icon: EventIcon,
      },
      shop: {
        icon: ShopIcon,
      },
    },
  } as any;

  if (icons.includes(icon)) {
    const svg = `${icon}`.replace(".png", "").replace("-", "");
    const Icon = IconeSvg?.[iconPath]?.[svg]
      ? IconeSvg?.[iconPath]?.[svg]?.icon
      : CustonlinkIcon;
    return (
      <Icon className={`mx-auto mb-1 h-${height} w-${width} drop-shadow-xl`} />
    );
  } else {
    return (
      <img
        className={`mx-auto mb-2 h-${height} w-${width} rounded-[19px] shadow-lg shadow-black/40`}
        src={`${IMAGE_URL}${icon}`}
        alt={`${IMAGE_URL}${icon}`}
      />
    );
  }
};

export default DisplayIcon;
