import React from "react";

const MapIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.4804 77.9004C47.7169 79.6896 30.9026 79.6896 14.1391 77.9004C7.45472 77.187 2.17505 71.9073 1.46164 65.2224C-0.327547 48.4589 -0.327547 31.6451 1.46164 14.8816C2.17505 8.19727 7.45472 2.9176 14.1391 2.2042C30.9026 0.415007 47.7163 0.415007 64.4798 2.2042C71.1648 2.9176 76.4445 8.19727 77.1579 14.8816C78.947 31.6451 78.947 48.4589 77.1579 65.2224C76.4445 71.9073 71.1654 77.187 64.4804 77.9004Z"
        fill="white"
      />
      <path
        d="M44.9239 15.9894C43.1595 15.4319 41.2347 15.1133 39.2697 15.1133C33.6156 15.1133 28.5229 17.6621 25.1144 21.6844L33.8562 28.9724L44.9239 15.9894Z"
        fill="#1A73E8"
      />
      <path
        d="M25.114 21.6843C22.4273 24.8703 20.7832 29.0121 20.7832 33.4725C20.7832 36.9373 21.4649 39.7251 22.6278 42.2341L33.8559 28.9723L25.114 21.6843Z"
        fill="#EA4335"
      />
      <path
        d="M39.3102 26.4632C43.24 26.4632 46.4079 29.6094 46.4079 33.5123C46.4079 35.2248 45.7663 36.8178 44.7237 38.0523C44.7237 38.0523 50.2976 31.4414 55.7513 25.0295C53.5057 20.7284 49.6159 17.4628 44.9242 15.9893L33.8566 28.9722C35.1799 27.4589 37.1047 26.4632 39.3102 26.4632Z"
        fill="#4285F4"
      />
      <path
        d="M39.3097 40.5217C35.3799 40.5217 32.212 37.3755 32.212 33.4726C32.212 31.7602 32.8135 30.1672 33.8561 28.9724L22.6281 42.2342C24.5529 46.4556 27.7609 49.8806 31.0491 54.1418L44.7233 38.0127C43.3999 39.5659 41.4751 40.5217 39.3097 40.5217Z"
        fill="#FBBC04"
      />
      <path
        d="M44.4829 58.6021C50.6583 49.0042 57.8362 44.6633 57.8362 33.5123C57.8362 30.4457 57.0743 27.5783 55.751 25.0295L31.0493 54.1417C32.0919 55.4957 33.1746 57.0489 34.2172 58.6419C37.9866 64.4165 36.944 67.8415 39.3501 67.8415C41.7561 67.8415 40.7135 64.3767 44.4829 58.6021Z"
        fill="#34A853"
      />
    </svg>
  );
};

export default MapIcon;
