import React from "react";

const Waiter = ({...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path d="M2481 5108 c-53 -15 -120 -66 -149 -112 l-26 -42 -112 -22 c-515 -99 -966 -409 -1254 -862 -141 -223 -245 -522 -275 -797 l-7 -63 -48 0 c-27 0 -84 -11 -128 -25 -196 -62 -351 -229 -398 -431 -21 -91 -16 -194 11 -225 47 -52 -93 -49 2473 -49 l2389 0 34 23 c45 30 59 61 59 132 0 290 -236 551 -517 572 -82 7 -78 2 -88 103 -14 134 -61 315 -124 473 -159 399 -472 745 -871 964 -148 81 -400 167 -568 193 -57 9 -75 16 -83 33 -6 12 -30 41 -52 65 -68 69 -172 97 -266 70z m247 -389 c271 -29 505 -113 733 -261 404 -262 671 -678 743 -1155 7 -46 10 -85 8 -88 -11 -11 -3312 -5 -3312 6 0 28 24 163 41 234 49 199 142 401 261 569 92 130 275 313 402 401 334 232 734 337 1124 294z m1866 -1769 c74 -28 138 -87 176 -163 l31 -62 -2232 -3 c-1228 -1 -2234 0 -2237 3 -9 8 8 51 37 95 38 57 89 97 160 127 l56 23 1977 0 c1964 0 1978 0 2032 -20z" />
        <path d="M2035 4419 c-115 -60 -257 -164 -366 -267 -165 -157 -280 -306 -367 -477 -105 -206 -117 -259 -69 -301 60 -52 118 -22 162 83 135 326 403 627 708 797 76 43 97 67 97 112 0 39 -42 84 -79 84 -14 0 -53 -14 -86 -31z" />
        <path d="M1896 2380 c-27 -10 -67 -33 -88 -50 l-39 -32 -46 16 c-100 34 -264 9 -350 -53 -76 -55 -103 -160 -64 -246 10 -22 58 -85 107 -140 276 -309 470 -584 699 -990 168 -299 406 -615 472 -628 66 -12 129 50 116 115 -3 15 -31 57 -62 95 -139 166 -301 406 -381 563 -131 257 -443 695 -692 971 -43 48 -78 91 -78 97 0 12 21 21 81 32 80 15 107 1 209 -109 180 -195 621 -586 737 -652 163 -93 429 -127 543 -69 149 75 259 208 321 388 10 30 23 102 29 159 7 74 16 115 31 141 25 45 94 102 122 102 19 0 20 -4 14 -107 -4 -58 -19 -177 -33 -262 -31 -183 -29 -234 15 -403 34 -131 41 -221 27 -370 l-8 -97 30 -31 c41 -41 92 -42 131 -2 62 62 68 344 12 547 -36 129 -38 181 -11 330 30 173 45 375 30 432 -16 67 -53 111 -118 140 -44 21 -64 24 -103 20 -108 -13 -244 -120 -295 -231 -17 -38 -29 -90 -38 -173 -21 -183 -67 -274 -185 -371 l-55 -44 -105 5 c-71 3 -126 12 -167 26 -116 41 -108 29 -284 415 -172 378 -188 404 -285 453 -68 36 -170 41 -239 13z m163 -194 c21 -11 42 -47 94 -162 37 -80 66 -148 64 -150 -2 -2 -70 62 -153 142 l-149 145 24 20 c28 23 81 25 120 5z" />
        <path d="M3584 661 c-263 -71 -496 -189 -684 -346 -148 -124 -199 -190 -184 -239 16 -54 76 -86 127 -67 14 6 67 49 118 96 171 158 348 260 577 335 215 70 228 62 367 -214 109 -215 125 -234 190 -222 46 9 78 47 78 93 -1 53 -172 398 -231 464 -88 98 -224 136 -358 100z" />
      </g>
    </svg>
  );
};

export default Waiter;
