import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/solid";
import React from "react";

export interface Props {
  color?: string;
  qty: any | 1;
  small?: Boolean | false;
  onChange?: (...args: any[]) => any;
}

const QuantityButton: React.FC<Props> = ({ qty, small, onChange, color }) => {
  const [quantity, setQuantity] = React.useState<any | 1>(qty);

  const decreaseQuantitySmall = () => {
    if (qty > 0) {
      if (onChange) {
        onChange(qty - 1);
      }
      return null;
    } else {
      return null;
    }
  };

  const increaseQuantitySmall = () => {
    if (qty < 10) {
      if (onChange) {
        onChange(qty + 1);
      }
      return null;
    } else {
      return null;
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      if (onChange) {
        onChange(quantity - 1);
      }
      return null;
    } else {
      return null;
    }
  };

  const increaseQuantity = () => {
    if (quantity < 10) {
      setQuantity(quantity + 1);
      if (onChange) {
        onChange(quantity + 1);
      }
      return null;
    } else {
      return null;
    }
  };

  return (
    <>
      {small ? (
        <div className={`flex w-fit items-center justify-around rounded-md ${color} p-1 py-2`}>
          <div className="flex items-center justify-center px-1 text-xl">
            <MinusSmIcon className="h-4 w-4" onClick={decreaseQuantitySmall} />
          </div>
          <input
            type="number"
            value={qty}
            className="w-5 border-0 bg-transparent p-0 text-center text-sm font-bold"
            disabled
          />
          <div className="flex items-center justify-center px-1 text-xl">
            <PlusSmIcon className="h-4 w-4" onClick={increaseQuantitySmall} />
          </div>
        </div>
      ) : (
        <div className={`flex w-fit items-center justify-around rounded-md ${color} p-2`}>
          <div className="flex items-center justify-center px-3 text-2xl">
            <MinusSmIcon className="h-5 w-5" onClick={decreaseQuantity} />
          </div>
          <input
            type="number"
            value={quantity}
            className="w-6 border-0 bg-transparent p-0 text-center text-xl font-bold"
            disabled
          />
          <div className="flex items-center justify-center px-3 text-2xl">
            <PlusSmIcon className="h-5 w-5" onClick={increaseQuantity} />
          </div>
        </div>
      )}
    </>
  );
};

export default QuantityButton;
