import cn from "classnames";
import React, { ButtonHTMLAttributes, JSXElementConstructor } from "react";
import LoadingCircle from "../LoadingCircle";
import s from "./Button.module.css";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: "default" | "outlined";
  size?: "small" | "medium" | "large";
  type?: "submit" | "reset" | "button";
  Component?: string | JSXElementConstructor<any>;
  width?: string | number;
  loading?: Boolean;
  disabled?: boolean;
  color?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    className,
    variant = "default",
    size,
    width,
    children,
    color,
    loading,
    disabled,
    style = {},
    Component = "button",
    ...rest
  } = props;

  const rootClassName = cn(
    s.root,
    {
      [s.outlined]: variant === "outlined",
      [s.small]: size === "small",
      [s.medium]: size === "medium",
      [s.large]: size === "large",
      [s.loading]: loading,
      [s.disabled]: disabled,
    },
    className
  );

  return (
    <>
      <Component
        className={rootClassName}
        disabled={disabled}
        style={{
          width,
          ...style,
        }}
        {...rest}
      >
        {loading && <LoadingCircle />}
        {children}
      </Component>
    </>
  );
};

export default Button;
