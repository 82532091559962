import React from "react";
import moment from "moment";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

interface Props {
  onOpen: any;
  bankDetail: any;
  onLoading: Boolean;
}

const AccountStatus: React.FC<Props> = ({ onOpen, bankDetail, onLoading }) => {
  const openHandle = onOpen;

  if (onLoading) {
    return (
      <>
        <div className="w-full animate-pulse">
          <div className="mb-5 h-fit w-full rounded-2xl bg-cultured p-5">
            <div className="mb-2 flex w-full justify-between">
              <div className="h-5 w-40 rounded bg-black/20" />
              <div className="h-5 w-32 rounded bg-black/20" />
            </div>
            <div className="my-7 w-full">
              <div className="my-2 h-5 w-32 rounded bg-black/20" />
              <div className="w-full rounded-full bg-black/20 p-0.5">
                <div className="h-3 w-2/3 rounded-full bg-black/50" />
              </div>
            </div>
            <div className="flex w-full justify-between">
              <div className="mr-5 mb-5 h-32 w-full space-y-1 rounded-md bg-black/20 p-3 md:mb-0 md:w-80 "></div>
              <div className="mb-5 h-32 w-full space-y-1 rounded-md bg-black/20 p-3 md:mb-0"></div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="mb-5 rounded-2xl bg-cultured p-5">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-bold capitalize">
                Account Activation
              </h2>
            </div>
            <div className="flex items-center">
              <h2 className="mr-3 hidden text-gray/50 md:block">
                {moment(bankDetail?.createdAt).format("MMM Do YYYY")}
              </h2>
              <button
                className="w-fit select-none rounded-md border border-gold bg-gold py-2 px-5 font-medium"
                onClick={() => openHandle(!onOpen)}
              >
                Clarify Details
              </button>
            </div>
          </div>
          <div className="my-5 w-full">
            <div className="my-2">
              <h2 className="text-xl font-medium text-[#1190EE]">
                {bankDetail?.status}% Complete
              </h2>
            </div>
            <div className="w-full rounded-full bg-white p-0.5">
              <div
                className="h-3 rounded-full bg-gold"
                style={{ width: `${bankDetail?.status}%` }}
              ></div>
            </div>
          </div>
          <div className="justify-between md:flex">
            <div className="mr-5 mb-5 w-full space-y-1 rounded-md bg-white p-3 font-medium text-gray md:mb-0 md:w-80">
              <p>Activation Steps:</p>
              <div className="flex items-center">
                {bankDetail?.status === 40 ||
                bankDetail?.status === 80 ||
                bankDetail?.status === 100 ? (
                  <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                ) : (
                  <XIcon className="mr-1 h-4 w-4" />
                )}
                <p className="text-black">Bank details</p>
              </div>
              <div className="flex w-max items-center">
                {bankDetail?.status === 80 || bankDetail?.status === 100 ? (
                  <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                ) : (
                  <XIcon className="mr-1 h-4 w-4" />
                )}
                <p
                  className={
                    bankDetail?.status === 80 || bankDetail?.status === 100
                      ? "text-black"
                      : "text-gray"
                  }
                >
                  Bank Account Details
                </p>
              </div>
              <div className="flex items-center">
                {bankDetail?.status === 100 ? (
                  <CheckIcon className="mr-1 h-4 w-4 text-[#40AE2B]" />
                ) : (
                  <XIcon className="mr-1 h-4 w-4" />
                )}
                <p
                  className={
                    bankDetail?.status === 100 ? "text-black" : "text-gray"
                  }
                >
                  Admin Approval
                </p>
              </div>
            </div>
            {bankDetail?.status === 100 ? (
              <div className="w-full rounded-md bg-white p-3 text-gray">
                <h2 className="text-2xl font-normal text-[#40AE2B]">
                  Clarification completed
                </h2>
                <p className="">
                  Your bank details clarification was conducted, and you got a
                  mail with the Razorpay dashboard link. You can also visit by
                  clicking the below button.
                </p>
                <button
                  className="mt-3 w-fit select-none rounded-md border-0 bg-gold py-2 px-5 font-medium text-raisin-black"
                  onClick={() =>
                    window.open(
                      "https://dashboard.razorpay.com/signin?screen=sign_in"
                    )
                  }
                >
                  View Account
                </button>
              </div>
            ) : (
              <div className="w-full rounded-md bg-white p-3 text-gray">
                <h2 className="text-2xl font-normal text-[#C73130]">
                  Clarification required
                </h2>
                <p className="">
                  We need some clarification regarding your submitted details.
                  Please clarify these details at the earliest to get your
                  account activated.
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default AccountStatus;
