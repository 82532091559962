import React from "react";
import { StoreTableOblect } from "types";
import { useForm } from "react-hook-form";
import { Button, Separate } from "components/ui";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  onTable: any;
  isTable: boolean;
  onLoading: boolean;
  onUpdateDetails: any;
  selectedTable: StoreTableOblect | null;
}

const UpdateTable: React.FC<Props> = ({
  isTable,
  onTable,
  onLoading,
  selectedTable,
  onUpdateDetails,
}) => {
  const onCloseTable = onTable;
  const onUpdateHandel = onUpdateDetails;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit((data: any) => {
    onUpdateHandel(data);
  });

  React.useEffect(() => {
    if (selectedTable !== null) {
      setValue("table_name", selectedTable?.table_name);
      setValue("priority", selectedTable?.priority);
      setValue("table_location", selectedTable?.table_location);
      setValue("_id", selectedTable?._id);
    }
  }, [selectedTable, setValue]);

  return (
    <>
      <Transition appear show={isTable} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => onCloseTable(!isTable)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 pb-4 text-center text-xl font-medium leading-6"
                  >
                    Update Table Details
                  </Dialog.Title>
                  <Separate dark={true} />

                  <form className="p-5 md:px-10" onSubmit={onSubmit}>
                    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                      <div className="grid md:mx-5">
                        <label
                          className="text-md font-bold"
                          htmlFor="table_name"
                        >
                          Table Name
                        </label>
                        <input
                          className="mt-2 rounded-md border-0 bg-cultured"
                          type="text"
                          id="table_name"
                          placeholder="Table Name"
                          {...register("table_name", { required: true })}
                        />
                        {errors.table_name && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div>
                      <div className="grid md:mx-5">
                        <label className="text-md font-bold" htmlFor="priority">
                          Table sequence
                        </label>
                        <input
                          className="mt-2 rounded-md border-0 bg-cultured"
                          type="number"
                          id="priority"
                          placeholder="Table sequence"
                          disabled
                          {...register("priority", { required: true })}
                        />
                        {errors.priority && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div>
                      <div className="grid md:mx-5">
                        <label
                          className="text-md font-bold"
                          htmlFor="table_location"
                        >
                          Table Location
                        </label>
                        <input
                          className="mt-2 rounded-md border-0 bg-cultured uppercase"
                          type="text"
                          id="table_location"
                          placeholder="Table Location"
                          disabled
                          {...register("table_location", { required: true })}
                        />
                        {errors.table_location && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="mx-auto mt-10 grid max-w-xs grid-cols-2">
                      <Button
                        variant="outlined"
                        onClick={() => onCloseTable(!isTable)}
                      >
                        Cancel
                      </Button>
                      <Button loading={onLoading} type="submit">
                        Apply
                      </Button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default UpdateTable;
