import React from "react";
import {
  ChevronLeftIcon,
  MenuAlt2Icon,
  SearchIcon,
} from "@heroicons/react/solid";
import {
  ArchiveIcon,
  UserIcon,
  RefreshIcon,
  TemplateIcon,
  LogoutIcon,
} from "@heroicons/react/outline";
import useOutsideClick from "lib/hooks/useOutsideClick";
import { NavLink, useNavigate, useParams } from "react-router-dom";

interface Props {
  onOpenCheckUser?: any;
  search: boolean;
  back: boolean;
  toggle: boolean;
}

const ShopViewHeader: React.FC<Props> = ({
  onOpenCheckUser,
  search,
  back,
  toggle,
}) => {
  const [isToggleOpen, setIsToggleOpen] = React.useState(false);
  const [guestUserDetail, setGuestUserDetail] = React.useState<any>(null);
  const checkUserHandler = onOpenCheckUser;
  const navigate = useNavigate();
  let { id } = useParams();
  const ref = React.useRef<any>();

  const onToggle = () => {
    setIsToggleOpen(!isToggleOpen);
    const jode_cart_user = JSON.parse(
      localStorage.getItem("jod-e_shop_cart_user") as any
    );
    setGuestUserDetail(jode_cart_user);
  };

  useOutsideClick(ref, () => {
    if (isToggleOpen) {
      setIsToggleOpen(!isToggleOpen);
    }
  });

  const onLogout = () => {
    localStorage.removeItem("jod-e_shop_cart_user");
    localStorage.removeItem("jod-e_table_selection");
    setIsToggleOpen(false);
  };

  React.useEffect(() => {
    const jode_cart_user = JSON.parse(
      localStorage.getItem("jod-e_shop_cart_user") as any
    );
    setGuestUserDetail(jode_cart_user);
  }, []);

  return (
    <>
      <section className="my-3 border-b border-gray/20 p-3 text-gray">
        {isToggleOpen && (
          <div
            className="absolute top-0 left-0 z-20 h-screen w-48 border-r border-gray/50 bg-white text-raisin-black backdrop-blur-md"
            ref={ref}
          >
            <div className="px-4 pt-10">
              <div className="mb-3 flex items-center border-b-2 border-gray/30 p-4 pr-0">
                <UserIcon className="mr-2 h-5 w-5" />
                {guestUserDetail === null ? (
                  <h2
                    className="select-none font-medium"
                    onClick={() => checkUserHandler()}
                  >
                    Guest User
                  </h2>
                ) : (
                  <h2 className="select-none font-medium">
                    {guestUserDetail?.userName === ""
                      ? guestUserDetail?.userNumber
                      : guestUserDetail?.userName}
                  </h2>
                )}
              </div>
              <NavLink
                to={`/shop/view_order`}
                className={({ isActive }) =>
                  isActive
                    ? "mb-3 flex items-center border-b-2 border-gold/30 p-4 pr-0 text-gold"
                    : "mb-3 flex items-center border-b-2 border-gray/30 p-4 pr-0"
                }
              >
                <ArchiveIcon className="mr-2 h-5 w-5" />
                <h2 className="font-medium">Current Orders</h2>
              </NavLink>
              <NavLink
                to={`/shop/${id}?view=TABLE`}
                onClick={() => setIsToggleOpen(false)}
                className="mb-3 flex items-center border-b-2 border-gray/30 p-4 pr-0"
              >
                <TemplateIcon className="mr-2 h-5 w-5" />
                <h2 className="font-medium">Table</h2>
              </NavLink>
              <div className="mb-3 flex items-center border-b-2 border-gray/30 p-4 pr-0">
                <RefreshIcon className="mr-2 h-5 w-5" />
                <h2 className="font-medium">Past Orders</h2>
              </div>
              {guestUserDetail !== null && (
                <div className="mb-3 flex items-center p-4" onClick={onLogout}>
                  <LogoutIcon className="mr-2 h-5 w-5" />
                  <h2 className="font-medium">Log Out</h2>
                </div>
              )}
              <div className="absolute bottom-20">
                <img
                  className="h-fit w-fit object-cover"
                  src="/assets/shop/shop_sidebar.svg"
                  alt="shop_sidebar"
                />
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-between">
          {back && (
            <ChevronLeftIcon
              className="h-7 w-7 cursor-pointer"
              onClick={() => navigate(-1)}
            />
          )}
          {toggle && (
            <MenuAlt2Icon
              className="h-7 w-7 cursor-pointer"
              onClick={onToggle}
            />
          )}
          {search && (
            <div
              className="flex cursor-pointer select-none items-center"
              onClick={() => navigate(`/shop/search/${id}`)}
            >
              <SearchIcon className="mr-1 h-7 w-7" />
              <p>Search</p>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ShopViewHeader;
