import React, { ButtonHTMLAttributes, JSXElementConstructor } from "react";
import cn from "classnames";
import s from "./ToggleButton.module.css";

export interface ToggleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  Component?: string | JSXElementConstructor<any>;
  className?: string;
  text?: string;
  activeColor?: string;
  checked: boolean;
  onChange?: (...args: any[]) => any;
}

const ToggleButton: React.FC<ToggleProps> = (props) => {
  const {
    className,
    text,
    activeColor = "bg-gold",
    checked,
    onChange,
    Component = "button",
    ...rest
  } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.checked);
    }
    return null;
  };

  const switchWrapper = cn(s.switchWrapper, className);

  const switchSide = cn(s.switch, className);

  const switchSideDisabled = cn(s.switch_disable, className);

  const switchHandle = cn(s.switchHandle, className);

  return (
    <>
      <Component {...rest}>
        <span className={switchWrapper}>
          <input type="checkbox" checked={checked} onChange={handleOnChange} />
          <span
            className={
              checked ? `${switchSide} ${activeColor}` : switchSideDisabled
            }
          >
            <span className={switchHandle} />
          </span>
        </span>
        <span className="switch-label">{text}</span>
      </Component>
    </>
  );
};

export default ToggleButton;
