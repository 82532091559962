import React from "react";

const BehanceIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.7204 77.9375C47.9569 79.7267 31.1426 79.7267 14.3791 77.9375C7.69471 77.2241 2.41504 71.9444 1.70164 65.2595C-0.0875567 48.496 -0.0875567 31.6822 1.70164 14.9187C2.41504 8.23438 7.69471 2.95471 14.3791 2.24131C31.1426 0.452116 47.9563 0.452116 64.7198 2.24131C71.4048 2.95471 76.6844 8.23438 77.3978 14.9187C79.187 31.6822 79.187 48.496 77.3978 65.2595C76.6844 71.9444 71.4054 77.2241 64.7204 77.9375Z"
        fill="#005CFF"
      />
      <path
        d="M37.5707 33.5987C37.5707 28.5331 33.4511 24.4136 28.3855 24.4136H14.6078V56.5616H28.3855C33.4511 56.5616 37.5707 52.4421 37.5707 47.3765C37.5707 44.6197 36.3257 42.1721 34.3971 40.4876C36.3257 38.8031 37.5707 36.3555 37.5707 33.5987ZM32.9781 47.3765C32.9781 49.9084 30.9174 51.969 28.3855 51.969H19.2004V42.7839H28.3855C30.9174 42.7839 32.9781 44.8446 32.9781 47.3765ZM19.2004 38.1913V29.0062H28.3855C30.9174 29.0062 32.9781 31.0668 32.9781 33.5987C32.9781 36.1306 30.9174 38.1913 28.3855 38.1913H19.2004Z"
        fill="white"
      />
      <path
        d="M65.1273 45.0791C65.1273 38.7488 59.9762 33.5977 53.6459 33.5977C47.3155 33.5977 42.1644 38.7488 42.1644 45.0791C42.1644 51.4094 47.3155 56.5605 53.6459 56.5605C57.7314 56.5605 61.5418 54.3605 63.5887 50.8222L59.615 48.5211C58.3844 50.647 56.097 51.968 53.6459 51.968C50.6523 51.968 48.0983 50.0484 47.1493 47.3754H65.1273V45.0791ZM47.1493 42.7828C48.0977 40.1098 50.6523 38.1902 53.6459 38.1902C56.6394 38.1902 59.1934 40.1098 60.1425 42.7828H47.1493Z"
        fill="white"
      />
      <path
        d="M44.4613 24.4126H62.8316V29.0052H44.4613V24.4126Z"
        fill="white"
      />
    </svg>
  );
};

export default BehanceIcon;
