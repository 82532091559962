import React from "react";

export interface CategorieInputProps {
  value: string;
  onChange?: (...args: any[]) => any;
}

const InputCategorie: React.FC<CategorieInputProps> = (props) => {
  const { children, value, onChange, ...rest } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  return (
    <>
      <div className="h-24 w-40 rounded-xl bg-cultured p-2 md:w-48">
        <div className="mb-1 flex h-14 w-full items-center	justify-center rounded-2xl bg-gold pr-2 pl-2">
          <input
            className="w-full rounded-xl border-0"
            type="text"
            name="Category name"
            placeholder="Category Name"
            defaultValue={value}
            onChange={handleOnChange}
            {...rest}
          />
        </div>
        {children}
      </div>
    </>
  );
};

export default InputCategorie;
