import React from "react";

const WhatsAppIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.7204 77.9004C47.9569 79.6896 31.1426 79.6896 14.3791 77.9004C7.69471 77.187 2.41504 71.9073 1.70164 65.2224C-0.0875567 48.4589 -0.0875567 31.6451 1.70164 14.8816C2.41504 8.19727 7.69471 2.9176 14.3791 2.2042C31.1426 0.415007 47.9563 0.415007 64.7198 2.2042C71.4048 2.9176 76.6844 8.19727 77.3978 14.8816C79.187 31.6451 79.187 48.4589 77.3978 65.2224C76.6844 71.9073 71.4054 77.187 64.7204 77.9004Z"
        fill="url(#paint0_linear_2307_926)"
      />
      <path
        d="M16.7455 62.8543L20.0026 50.9618C17.9937 47.4816 16.9368 43.5331 16.9381 39.4882C16.9433 26.8339 27.2413 16.5388 39.8961 16.5388C46.0371 16.5418 51.801 18.9317 56.1361 23.2697C60.4699 27.6078 62.8563 33.3739 62.8541 39.5067C62.8485 52.1602 52.5487 62.457 39.8961 62.457C39.8952 62.457 39.8971 62.457 39.8961 62.457H39.8862C36.0443 62.4554 32.2691 61.4917 28.9157 59.6629L16.7455 62.8543Z"
        fill="white"
      />
      <path
        d="M39.9029 20.416C29.3774 20.416 20.8177 28.9726 20.8138 39.4899C20.8123 43.094 21.8209 46.6044 23.7309 49.6418L24.185 50.3634L22.2568 57.4021L29.4789 55.5085L30.1763 55.9217C33.1053 57.6601 36.4634 58.5794 39.8883 58.5811H39.8955C50.4129 58.5811 58.9725 50.0236 58.9768 39.5054C58.9787 34.4084 56.996 29.6154 53.3932 26.01C49.7904 22.4046 44.9999 20.4182 39.9029 20.416Z"
        fill="url(#paint1_linear_2307_926)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1586 29.8931C33.7286 28.9381 33.2762 28.9188 32.8678 28.902C32.5332 28.8878 32.1505 28.8887 31.7683 28.8887C31.3856 28.8887 30.7643 29.0323 30.2388 29.6063C29.713 30.1803 28.2308 31.5679 28.2308 34.3903C28.2308 37.2127 30.2866 39.9396 30.5729 40.3227C30.8597 40.7054 34.5412 46.6822 40.3722 48.9813C45.2177 50.8921 46.2036 50.512 47.2558 50.4166C48.3075 50.3211 50.6496 49.029 51.1273 47.6896C51.6055 46.3502 51.6055 45.2026 51.4623 44.9627C51.3187 44.7232 50.9364 44.58 50.3624 44.2932C49.7888 44.0064 46.9686 42.6184 46.4427 42.4271C45.9168 42.2357 45.5346 42.1403 45.1519 42.7143C44.7696 43.2883 43.671 44.58 43.3361 44.9627C43.0016 45.3458 42.667 45.3935 42.093 45.1067C41.5194 44.8191 39.6718 44.2141 37.4802 42.2598C35.7749 40.7394 34.6238 38.8617 34.2888 38.2876C33.9543 37.7136 34.2532 37.4032 34.5408 37.1172C34.7984 36.8601 35.1144 36.4473 35.4016 36.1124C35.6876 35.7774 35.7835 35.5383 35.9744 35.1561C36.1657 34.773 36.0703 34.438 35.9266 34.1512C35.7835 33.8644 34.6685 31.0274 34.1586 29.8931Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2307_926"
          x1="38.7519"
          y1="5.56595"
          x2="39.1493"
          y2="72.5462"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57D163" />
          <stop offset="1" stopColor="#23B33A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2307_926"
          x1="39.5069"
          y1="22.7063"
          x2="39.7004"
          y2="55.3206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57D163" />
          <stop offset="1" stopColor="#23B33A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WhatsAppIcon;
