import React from "react";

const CallIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M64.7204 77.7979C47.9569 79.5871 31.1426 79.5871 14.3791 77.7979C7.69471 77.0845 2.41504 71.8048 1.70164 65.1198C-0.0875567 48.3563 -0.0875567 31.5426 1.70164 14.7791C2.41504 8.09473 7.69471 2.81506 14.3791 2.10166C31.1426 0.312468 47.9563 0.312468 64.7198 2.10166C71.4048 2.81506 76.6844 8.09473 77.3978 14.7791C79.187 31.5426 79.187 48.3563 77.3978 65.1198C76.6844 71.8048 71.4054 77.0845 64.7204 77.7979Z"
        fill="url(#paint0_linear_2307_1533)"
      />
      <path
        d="M29.1143 35.8341C30.6764 38.652 32.4768 41.3564 34.8143 43.8225C37.1594 46.3037 40.0756 48.5618 43.8504 50.4908C44.1303 50.6269 44.395 50.6269 44.6333 50.5324C44.9927 50.3962 45.3595 50.0974 45.7189 49.7381C45.9988 49.4582 46.3467 49.0119 46.7098 48.5239C48.1623 46.6139 49.9589 44.2423 52.4969 45.43C52.5536 45.4564 52.5952 45.4867 52.6519 45.5094L61.1169 50.3773C61.1433 50.3924 61.1736 50.4189 61.1963 50.434C62.3121 51.2019 62.7735 52.3857 62.7849 53.7285C62.7849 55.0939 62.2818 56.6295 61.5443 57.9269C60.5684 59.6403 59.1311 60.775 57.4744 61.5239C55.8972 62.2501 54.1422 62.6397 52.4552 62.8893C49.8076 63.2789 47.3264 63.0293 44.7884 62.2501C42.3072 61.4823 39.807 60.2152 37.0762 58.5283L36.8757 58.3997C35.6238 57.6167 34.2697 56.7808 32.9421 55.7936C28.0855 52.1247 23.1344 46.8257 19.9081 40.9933C17.2037 36.0989 15.7285 30.8149 16.5342 25.7806C16.9805 23.0195 18.1644 20.508 20.2296 18.8513C22.03 17.3989 24.4544 16.6046 27.5938 16.8845C27.9531 16.911 28.2746 17.119 28.4448 17.4292L33.8725 26.6052C34.6668 27.634 34.7652 28.6552 34.3302 29.6764C33.9709 30.5124 33.2446 31.284 32.2537 32.0026C31.9624 32.2522 31.6145 32.5057 31.2476 32.7704C30.0334 33.6517 28.6529 34.6692 29.1257 35.8644L29.1143 35.8341Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2307_1533"
          x1="40.1718"
          y1="73.9368"
          x2="39.5497"
          y2="0.759766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0AD318" />
          <stop offset="1" stopColor="#8DFF74" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CallIcon;
