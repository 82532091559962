import React from "react";
import axios from "axios";
import API from "config/Api";
import { ShopType } from "types";
import { AppCtx } from "data/context";
import { toast } from "react-toastify";
import { Loading } from "components/ui";
import { CheckIcon, TrashIcon, UploadIcon } from "@heroicons/react/outline";

interface Props {
  userShopDetails: ShopType;
}

const AddBanner: React.FC<Props> = ({ userShopDetails }) => {
  const { setShopUserDataGet } = React.useContext(AppCtx);
  const [imgPath, setImgPath] = React.useState<any>([]);
  const [selectedImage, setSelectedImage] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const formData = new FormData();

  const addImage = (event: any) => {
    const reader = new FileReader(),
      filesToDisplay = event.target.files;
    reader.onload = function () {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(filesToDisplay[0]);
    event.preventDefault();
    const files = event.target.files;
    const tempImages = [...imgPath] as any[];

    for (let index = 0; index < files.length; index++) {
      const file = files[index] as any[];
      const duplicate = false;
      if (!duplicate) tempImages.push(file);
    }
    setImgPath([...tempImages]);
  };

  const OnRemoveImage = () => {
    setImgPath([]);
    setSelectedImage([]);
  };

  const onAddBannerImage = () => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    setIsLoading(true);
    imgPath.forEach((file: string | Blob) => {
      formData.append("image", file);
    });

    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
      params: {
        //IT'S CREATE INDIVIDUAL FOLDER FOR SHOP TO ADD PHOTOS.
        directory: userShopDetails?.shop_name,
      },
    };

    //IMAGE UPLOAD API
    let imgUrl = API + `common/image`;
    axios
      .post(imgUrl, formData, config)
      .then((response) => {
        if (response?.data?.success) {
          let image_path = response?.data?.data?.files[0]?.key;
          // PRODUCT UPLOAD API
          const body = {
            storeId: userShopDetails?._id,
            bannerImage: {
              image: image_path,
            },
          };

          const config = {
            headers: {
              Authorization: `Bearer ${authData?.token}`,
            },
          };
          const url = API + `addBanner`;
          axios
            .post(url, body, config)
            .then((response) => {
              if (response?.data?.success) {
                OnRemoveImage();
                setShopUserDataGet(true);
                toast.success(`Banner added Successfully.`);
              }
            })
            .catch((err) => {
              console.log("error here", err);
            });
        } else {
          toast.warning("Image Uploading Fail Try Again.");
        }
      })
      .catch((err) => {
        console.log("error here", err.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {selectedImage.length === 0 ? (
        <div className="h-48 w-80 rounded-2xl border-2 border-dashed border-gray md:w-80 lg:w-64 xl:w-80 2xl:h-48 2xl:w-96">
          <div className="flex h-48 w-80 md:w-80 lg:w-64 xl:w-80 2xl:h-48 2xl:w-96">
            <div className="m-auto w-fit">
              <label
                htmlFor="product_image"
                className="m-auto mb-1 flex h-7 w-7 cursor-pointer rounded-full bg-gray"
              >
                <UploadIcon className="m-auto h-4 w-4 text-white" />
                <input
                  className="hidden"
                  type="file"
                  id="product_image"
                  accept="image/*"
                  onChange={addImage}
                />
              </label>
              <p className="text-xs">Upload Banner</p>
            </div>
          </div>
        </div>
      ) : (
        <div className=" relative mx-auto h-48 w-80 rounded-2xl md:w-80 lg:w-64 xl:w-80 2xl:h-48 2xl:w-96">
          <div
            className="absolute right-2 top-2 h-fit w-fit cursor-pointer rounded-md bg-gold/70 p-3"
            onClick={onAddBannerImage}
          >
            <CheckIcon className="h-4 w-4" />
          </div>
          <div
            className="absolute right-2 top-14 h-fit w-fit cursor-pointer rounded-md bg-gold/70 p-3"
            onClick={OnRemoveImage}
          >
            <TrashIcon className="h-4 w-4" />
          </div>
          <div className="mx-auto h-48 w-80 md:w-80 lg:w-64 xl:w-80 2xl:h-48 2xl:w-96">
            <img
              src={selectedImage}
              alt="banner content"
              className="h-full w-full rounded-lg object-cover"
            />
            {isLoading && <Loading />}
          </div>
        </div>
      )}
    </>
  );
};

export default AddBanner;
