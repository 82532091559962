import React from "react";
import axios from "axios";
import API from "config/Api";
import { AppCtx } from "data/context";
import { useParams } from "react-router-dom";
import { ProductObject, ShopProduct } from "types";
import { SearchIcon } from "@heroicons/react/solid";
import SearchCart from "components/shops/shopView/SearchCart";
import ProductViewBox from "components/shops/shopView/ProductViewBox";
import ShopViewHeader from "components/shops/shopView/ShopViewHeader";
import SingleProductView from "components/shops/shopView/SingleProductView";

const SearchProduct = () => {
  let { id } = useParams();
  const { setOrderCartLocalCount } = React.useContext(AppCtx);
  const [isCheckUserOpen, setIsCheckUserOpen] = React.useState<boolean>(false);
  const [productsList, setProductsList] = React.useState<ShopProduct | []>([]);
  const [isProductListLoading, setIsProductListLoading] = React.useState(false);
  const [selectedProduct, setSelectedProduct] =
    React.useState<ProductObject | null>(null);
  const [searchProduct, setSearchProduct] = React.useState("");
  let addons_array = [] as any[];
  //GET LOCALSTORAGE
  const jode_cart = JSON.parse(localStorage.getItem("jod-e_shop_cart") as any);

  const fetchProductList = React.useCallback(() => {
    setIsProductListLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
      params: {
        page: "",
        limit: "",
        search: searchProduct,
      },
    };
    const url = API + `product/getallbystore/${id}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setProductsList(response?.data?.data?.Products);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setIsProductListLoading(false);
      });
  }, [id, searchProduct]);

  const onViewProduct = (list: ProductObject) => {
    setSelectedProduct(list);
  };

  const onCheckUser = () => {
    setIsCheckUserOpen(!isCheckUserOpen);
  };

  const onAddToCart = (
    list: ProductObject,
    ing_radio: any,
    ing_checkbox: any,
    productQty: Number
  ) => {
    setOrderCartLocalCount(jode_cart?.product?.length || 0);
    addons_array.push(...ing_checkbox);
    addons_array.push(...ing_radio);
    const totalAddonsPrice = addons_array.reduce(
      (total, currentValue) =>
        (total = total + parseInt(currentValue.ingredient_price)),
      0
    );
    //ORDER OBJECT BODY FIRST TIME
    const orderBody = {
      storeId: id,
      userNumber: "",
      tableNumber: "07",
      totalOrder: 1,
      totalPrice: (list?.price + totalAddonsPrice) * Number(productQty),
      orderType: "dine_in",
      product: [
        {
          productId: list,
          productQty: productQty,
          productPrice: list?.price,
          adonsPrice: totalAddonsPrice,
          totalPrice: (list?.price + totalAddonsPrice) * Number(productQty),
          addonsList: addons_array,
        },
      ],
    };
    const productExistId = jode_cart?.product?.find(
      (x: any) => x?.productId?._id === list?._id
    );

    //CHECK IF CART EXISTS THE UPDATE OR ADD
    if (jode_cart === null) {
      //SET OBJECT IN LOCALSTORAGE
      localStorage.setItem("jod-e_shop_cart", JSON.stringify(orderBody));
      setOrderCartLocalCount(orderBody?.product?.length || 0);
      setSelectedProduct(null);
    } else if (productExistId !== undefined) {
      const addonsIndex = jode_cart?.product?.findIndex(
        (x: any) => x.productId._id === list._id
      );

      const updateProduct = {
        productId: productExistId.productId,
        productQty: productExistId.productQty + 1,
        productPrice: productExistId.productPrice,
        adonsPrice: productExistId.adonsPrice,
        totalPrice:
          (productExistId.adonsPrice + productExistId.productPrice) *
          (productExistId.productQty + 1),
        addonsList: productExistId.addonsList,
      };

      jode_cart.product[addonsIndex] = updateProduct;

      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );

      const updatOrder = {
        storeId: id,
        userNumber: "",
        tableNumber: "07",
        totalOrder: jode_cart?.totalOrder,
        totalPrice: totalPrice,
        orderType: "dine_in",
        product: [...jode_cart?.product],
      };

      //SET OBJECT IN LOCALSTORAGE WITH MULTIPLE PRODUCTS
      localStorage.setItem("jod-e_shop_cart", JSON.stringify(updatOrder));
      setSelectedProduct(null);
    } else {
      //PRODUCT INCREMENT OBJECT
      const addProduct = {
        productId: list,
        productQty: productQty,
        productPrice: list?.price,
        adonsPrice: totalAddonsPrice,
        totalPrice: (list?.price + totalAddonsPrice) * Number(productQty),
        addonsList: addons_array,
      };
      //ORDER BODU INCREMENT OBJECT
      const orderBody2 = {
        storeId: id,
        userNumber: "",
        tableNumber: "07",
        totalOrder: jode_cart?.totalOrder + 1,
        totalPrice:
          jode_cart?.totalPrice +
          (list?.price + totalAddonsPrice) * Number(productQty),
        orderType: "dine_in",
        product: [...jode_cart?.product, addProduct],
      };
      //SET OBJECT IN LOCALSTORAGE WITH MULTIPLE PRODUCTS
      localStorage.setItem("jod-e_shop_cart", JSON.stringify(orderBody2));
      setOrderCartLocalCount(orderBody2?.product?.length || 0);
      setSelectedProduct(null);
    }
  };

  const onCloseViewProduct = () => {
    setSelectedProduct(null);
  };

  React.useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  return (
    <>
      <section className="fixed inset-0 mx-auto max-w-xl md:max-w-xl lg:max-w-5xl 2xl:max-w-7xl">
        <div className="relative mx-auto h-full overflow-auto bg-white px-3 scrollbar-hide sm:max-w-3xl sm:shadow-2xl">
          <ShopViewHeader
            onOpenCheckUser={onCheckUser}
            search={false}
            back={true}
            toggle={true}
          />
          <div className="p-3">
            <div className="relative mb-7 h-10 w-full xl:w-auto">
              <SearchIcon className="absolute inset-y-0 left-3 my-auto h-4 w-4 text-gray" />
              <input
                type="search"
                className="w-full rounded-md border-none bg-cultured pl-9 font-light placeholder:font-light placeholder:text-gray focus:ring-0 xl:w-[300px]"
                placeholder="Search..."
                onChange={(e) => setSearchProduct(e.target.value)}
              />
            </div>
            {productsList.length === 0 && (
              <div className="my-10">
                <img
                  className="mx-auto"
                  src="/assets/shop/search_product.png"
                  alt=""
                />
                <h2 className="my-3 text-center text-raisin-black">
                  We don't have this type of product.
                </h2>
              </div>
            )}
            <ProductViewBox
              productsList={productsList}
              onViewProduct={onViewProduct}
              onLoading={isProductListLoading}
            > </ProductViewBox>
            {selectedProduct !== null && (
              <SingleProductView
                selectedProduct={selectedProduct}
                addCart={onAddToCart}
                onClose={onCloseViewProduct}
              />
            )}
          </div>
        </div>
        {jode_cart === null ||
          (jode_cart?.product?.length !== 0 && (
            <SearchCart product={jode_cart} />
          ))}
      </section>
    </>
  );
};

export default SearchProduct;
