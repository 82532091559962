import React from "react";
import { ProductObject } from "types";
import { XIcon } from "@heroicons/react/outline";
import { Button, Separate } from "components/ui";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  onOpen: boolean;
  onClose: any;
  radioButton: any;
  checkboxButton: any;
  updateWithAddons: any;
  product: ProductObject | null;
}

const ProductAddonModel: React.FC<Props> = ({
  onOpen,
  onClose,
  product,
  radioButton,
  checkboxButton,
  updateWithAddons,
}) => {
  const closeHandler = onClose;
  const radioButtonHandler = radioButton;
  const checkboxButtonHandler = checkboxButton;
  const updateWithAddonsHandler = updateWithAddons;

  const onAdd = () => {
    updateWithAddonsHandler();
    closeHandler();
  };

  return (
    <Transition appear show={onOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => closeHandler()}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-gray-900 flex justify-between pb-4 text-xl font-medium leading-6"
                >
                  {product?.name} Addons
                  <XIcon
                    className="h-5 w-5 cursor-pointer"
                    onClick={() => closeHandler()}
                  />
                </Dialog.Title>
                <Separate dark={true} />
                <div className="my-10">
                  {product?.addons
                    ?.filter((filt) => filt?.is_multiple === false)
                    .map((list, index) => {
                      return (
                        <div
                          key={index}
                          className="border-b-2 border-gray/20 pb-4"
                        >
                          <h2 className="my-3 text-xl font-bold">
                            {list?.addons_name}
                          </h2>
                          <>
                            {list?.ingredient?.map((ing, index2) => {
                              return (
                                <div
                                  key={index2}
                                  className="flex items-center justify-between"
                                >
                                  <label
                                    className="my-1 font-medium"
                                    htmlFor={`${ing?._id}`}
                                  >
                                    {ing?.ingredient_name}
                                  </label>
                                  <div className="flex items-center">
                                    <h3 className="my-1 font-medium">
                                      ₹ {ing?.ingredient_price}
                                    </h3>
                                    <input
                                      className="ml-3"
                                      type="radio"
                                      //   checked={ing?.ingredient_price === "0"}
                                      name={`ingredient_${index}`}
                                      id={`${ing?._id}`}
                                      value={`${ing?._id}`}
                                      onChange={(e) =>
                                        radioButtonHandler(e, ing, list._id)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        </div>
                      );
                    })}
                  {product?.addons
                    ?.filter((filt) => filt.is_multiple === true)
                    .map((list, index) => {
                      return (
                        <div
                          key={index}
                          className="border-b-2 border-gray/20 pb-4"
                        >
                          <h2 className="my-3 text-xl font-bold">
                            {list?.addons_name}
                          </h2>
                          <>
                            {list?.ingredient?.map((ing, index2) => {
                              return (
                                <div
                                  key={index2}
                                  className="flex items-center justify-between"
                                >
                                  <label
                                    className="my-1 font-medium"
                                    htmlFor={`${ing?._id}`}
                                  >
                                    {ing?.ingredient_name}
                                  </label>
                                  <div className="flex items-center">
                                    <h3 className="my-1 font-medium">
                                      ₹ {ing?.ingredient_price}
                                    </h3>
                                    <input
                                      className="ml-3"
                                      type="checkbox"
                                      name={`ingredient_${index}`}
                                      id={`${ing?._id}`}
                                      onChange={(e) =>
                                        checkboxButtonHandler(e, ing)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        </div>
                      );
                    })}
                </div>
                <div className="flex justify-end gap-5">
                  <Button loading={false} onClick={() => onAdd()}>
                    Add
                  </Button>
                  <Button loading={false} onClick={() => closeHandler()}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ProductAddonModel;
