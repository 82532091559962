import React from "react";
import axios from "axios";
import {
  ShopOrders,
  ShopProduct,
  StoreTableList,
  StoreTableOblect,
} from "types";
import API from "config/Api";
import { AppCtx } from "data/context";
import { toast } from "react-toastify";
import { socket } from "routes/ShopProtectedRoutes";
import { SearchIcon } from "@heroicons/react/outline";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import KotRequest from "components/shops/orderCreate/KotRequest";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";
import StaffViewOrder from "components/shops/orderCreate/StaffViewOrder";
import TableSelectionForOrder from "components/shops/orderCreate/TableSelectionForOrder";
import ProductSelectionForOrder from "components/shops/orderCreate/ProductSelectionForOrder";

const CreateOrder = () => {
  const { isStaffOrderView, setIsStaffOrderView } = React.useContext(AppCtx);
  const [tableList, setTableList] = React.useState<StoreTableList | []>([]);
  const [productsList, setProductsList] = React.useState<ShopProduct | []>([]);
  const [ordersList, setOrdersList] = React.useState<ShopOrders | []>([]);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [SelectedTable, setSelectedTable] =
    React.useState<StoreTableOblect | null>(null);

  //AUDIO PLAY WHEN EVER NEW ORDER RECEVED
  const playVideo = () => {
    const audio = new Audio("/assets/shop/notification.mp3");
    audio.loop = false;
    audio.play();
  };

  //FETCH TABLE LIST BY STORE
  const fetchTableList = React.useCallback(() => {
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };

    const url = API + `table/list/${authData?.storeId}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setTableList(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  }, []);

  //FETCH PRODUCT LIST BY STORE
  const fetchProductList = React.useCallback(() => {
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
      params: {
        page: "",
        limit: "",
        search: searchProduct,
      },
    };

    const url = API + `product/getallbystore/${authData?.storeId}`;
    if (productsList.length === 0) {
      axios
        .get(url, config)
        .then((response) => {
          if (response?.data?.success) {
            setProductsList(response?.data?.data?.Products);
          }
        })
        .catch((err) => {
          console.log("error here", err);
        })
        .finally(() => {});
    }
  }, [searchProduct, productsList.length]);

  //FETCH ORDER LIST WHICH ARE NOT ACCEPTED
  const fetchOrderList = React.useCallback(() => {
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
      params: {
        page: 1,
        limit: 100,
        tableNo: "",
        userNo: "",
      },
    };

    const url = API + `getorders/${authData?.storeId}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setOrdersList(response?.data?.data?.Orders);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  }, []);

  //SET SELECTED TABLE DETAIL IN STATE
  const onTableSelection = (list: StoreTableOblect) => {
    fetchProductList();
    setSelectedTable(list);
  };

  //AFTER COMPLETING ORDER FETCH TABLE LIST AND REDIRECT TO MAIN TABLE PAGE
  const onOrderComplete = (status: any) => {
    fetchTableList();
    toast.success(status?.message);
    setIsStaffOrderView(false);
    setSelectedTable(null);
  };

  const onFetchOrderList = () => {
    fetchTableList();
  };

  //GET NEW ORDER LIST FROM SOCKET
  React.useEffect(() => {
    socket?.on("receiveNewOrder", (data) => {
      setOrdersList(data?.Orders);
      // toast.info("You Have New Order.");
      playVideo();
      // fetchTableList();
    });
  });

  //INITIAL VALUE
  React.useEffect(() => {
    fetchTableList();
    fetchOrderList();
  }, [fetchTableList, fetchOrderList]);

  return (
    <>
      <DashboardHeader title={"Create Order"} />
      <section className="3xl:mx-w-[110rem] relative mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        {/* KOT REQUEST BY CUSTOMERS */}
        {ordersList?.filter((active) => !active?.orderAcceptStatus).length >
          0 && (
          <KotRequest
            inDetail={false}
            ordersList={ordersList}
            selectOrder={onFetchOrderList}
          />
        )}
        <h2 className="mb-4 hidden text-xl font-bold md:block">Create Order</h2>
        {/* TOP NAVIGATION BAR */}
        {SelectedTable !== null && (
          <div className="sticky top-5 z-50 flex h-12 w-full items-center justify-between gap-2 rounded-lg bg-gold px-4 shadow-xl">
            {isStaffOrderView ? (
              <div className="flex items-center">
                <ArrowSmLeftIcon
                  className="h-7 w-7 cursor-pointer text-black"
                  onClick={() => setIsStaffOrderView(false)}
                />
                <h2 className="text-lg font-bold">
                  Verify (Table No. {SelectedTable?.table_name})
                </h2>
              </div>
            ) : (
              <div className="flex items-center">
                <ArrowSmLeftIcon
                  className="h-7 w-7 cursor-pointer text-black"
                  onClick={() => setSelectedTable(null)}
                />
                <h2 className="text-lg font-bold">
                  Order (Table No. {SelectedTable?.table_name})
                </h2>
              </div>
            )}
            {!isStaffOrderView && (
              <div className="relative">
                <SearchIcon className="absolute inset-y-0 left-3 my-auto h-4 w-4 text-gray" />
                <input
                  type="search"
                  className="rounded-md border-none bg-cultured pl-9 font-light placeholder:font-light placeholder:text-gray focus:ring-0 xl:w-[300px]"
                  placeholder="Search..."
                  onChange={(e) => setSearchProduct(e.target.value)}
                />
              </div>
            )}
          </div>
        )}
        {/* COMPONENTS FOR CREATE ORDER */}
        {isStaffOrderView ? (
          <StaffViewOrder
            tableDeatils={SelectedTable}
            orderStatus={onOrderComplete}
          />
        ) : (
          <>
            {SelectedTable === null ? (
              <TableSelectionForOrder
                tableList={tableList}
                tableData={onTableSelection}
              />
            ) : (
              <ProductSelectionForOrder
                productsList={productsList}
                tableDeatils={SelectedTable}
              />
            )}
          </>
        )}
      </section>
    </>
  );
};

export default CreateOrder;
