import React from "react";
import axios from "axios";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  XIcon,
} from "@heroicons/react/outline";
import CheckUser from "./CheckUser";
import { AppCtx } from "data/context";
import { toast } from "react-toastify";
import io, { Socket } from "socket.io-client";
import ShowMoreText from "react-show-more-text";
import { Button, QuantityButton } from "components/ui";
import API, { IMAGE_URL, SOCKET_URL } from "config/Api";
import useOutsideClick from "lib/hooks/useOutsideClick";
import { useNavigate, useParams } from "react-router-dom";
import { DefaultEventsMap } from "@socket.io/component-emitter";

let socket: Socket<DefaultEventsMap, DefaultEventsMap>;

interface Props {
  onClose?: any;
  onStoreData?: any;
  onCartCount?: any;
}

const CartView: React.FC<Props> = ({ onClose, onStoreData, onCartCount }) => {
  let { id } = useParams();
  let navigate = useNavigate();
  const ref = React.useRef<any>();
  const { setOrderCartLocalCount } = React.useContext(AppCtx);
  const [orderCartLocal, setOrderCartLocal] = React.useState<any>(null);
  const [isNoteView, setIsNoteView] = React.useState<boolean>(false);
  const [noteContent, setNoteContent] = React.useState<string>("");
  const [isOrderCreateLoading, setIsOrderCreateLoading] =
    React.useState<boolean>(false);
  const [viewOrderIngredient, setViewOrderIngredient] =
    React.useState<Number | null>(null);
  const closeHandler = onClose;
  const cartCountHandler = onCartCount;
  const [isCartOpen, setIsCartOpen] = React.useState<boolean>(false);
  const [isCheckUserOpen, setIsCheckUserOpen] = React.useState<Boolean>(false);

  useOutsideClick(ref, () => {
    if (isCartOpen && !isCheckUserOpen) {
      // closeHandler();
    }
  });

  const fetchProductLocal = React.useCallback(() => {
    const jode_cart = JSON.parse(
      localStorage.getItem("jod-e_shop_cart") as any
    );
    if (id === jode_cart?.storeId) {
      setOrderCartLocal(jode_cart);
    } else {
      setOrderCartLocal(null);
    }
  }, [id]);

  const onProductUpdateInCart = async (e: any, index: any) => {
    //GET CART PRODUCT FROM LOCALSTORAGE
    const jode_cart = JSON.parse(
      localStorage.getItem("jod-e_shop_cart") as any
    );
    if (e === 0) {
      jode_cart.product.splice(index, 1);
      //CALCUTATE TOTAL PRICE OF PRODUCT AFTER REMOVE
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      //UPDATE IN MAIN BODY
      const updateOrder = {
        storeId: jode_cart?.storeId,
        userNumber: "",
        tableNumber: jode_cart?.tableNumber,
        totalOrder: jode_cart?.totalOrder - 1,
        totalPrice: totalPrice,
        orderType: "dine_in",
        product: [...jode_cart?.product],
      };
      //SET IN LOCALSTORAGE
      localStorage.setItem("jod-e_shop_cart", JSON.stringify(updateOrder));
      cartCountHandler(updateOrder?.product?.length);
      //SET IN USESTATE FOR VIEW UPDATE
      setOrderCartLocal(updateOrder);
    } else {
      //FIND PRODUCT IN LOCALSTORAGE WITH INDEX
      const addonsIndex = jode_cart.product.findIndex(
        (x: any) => x.productId?._id === jode_cart.product[index].productId?._id
      );
      //CRATE UPDATED PRODUCT BODY WITH INDEX
      const addProduct = {
        productId: jode_cart.product[index].productId,
        productQty: e,
        productPrice: jode_cart.product[index].productPrice,
        adonsPrice: jode_cart.product[index].adonsPrice,
        totalPrice:
          (jode_cart.product[index].productPrice +
            jode_cart.product[index].adonsPrice) *
          e,
        addonsList: jode_cart.product[index].addonsList,
        productNote: jode_cart.product[index].productNote,
      };
      //SET UPDATED PRODUCT ON SAME INDEX
      jode_cart.product[addonsIndex] = addProduct;
      //CALCUTATE TOTAL PRICE OF PRODUCT
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      //UPDATE IN MAIN BODY
      const updateOrder = {
        storeId: jode_cart?.storeId,
        userNumber: "",
        tableNumber: jode_cart?.tableNumber,
        totalOrder: jode_cart?.totalOrder,
        totalPrice: totalPrice,
        orderType: "dine_in",
        product: [...jode_cart?.product],
      };
      //SET IN LOCALSTORAGE
      localStorage.setItem("jod-e_shop_cart", JSON.stringify(updateOrder));
      //SET IN USESTATE FOR VIEW UPDATE
      setOrderCartLocal(updateOrder);
    }
  };

  const onViewOrderIngredient = (index: any) => {
    if (viewOrderIngredient === index) {
      setViewOrderIngredient(null);
    } else {
      setViewOrderIngredient(index);
    }
  };

  const onOrderFromCart = () => {
    setIsOrderCreateLoading(true);
    //CHECK FOR STORE RAZORPAY ACCOUNT DETAILS
    if (onStoreData?.razorpay_acc === null) {
      toast.warn("This Store Is Under Review You Can't Order Now.");
    } else {
      //TABLE NO AND ORDER TYPE
      const jode_table = JSON.parse(
        localStorage.getItem("jod-e_table_selection") as any
      );
      //USER DETAILS FOR ORDERING PRODUCTS
      const jode_cart_user = JSON.parse(
        localStorage.getItem("jod-e_shop_cart_user") as any
      );
      //USER PRODUCTS
      const jode_cart = JSON.parse(
        localStorage.getItem("jod-e_shop_cart") as any
      );
      //CHECK FOR USER EXIST
      if (jode_cart_user === null || undefined) {
        setIsCheckUserOpen(true);
        setIsOrderCreateLoading(false);
      } else if (
        jode_cart?.product?.length === 0 ||
        jode_cart?.product?.length === undefined
      ) {
        toast.warn("Add Some Product For Order.");
        setIsOrderCreateLoading(false);
      } else {
        //CONNECT SOCKET WITH STORE ROOM
        onConnectSocket();
        let dummy_ary = [] as any[];
        for (let i = 0; i < jode_cart?.product.length; i++) {
          const element = jode_cart?.product[i];
          const body = {
            productId: element?.productId?._id,
            productQty: element?.productQty,
            productPrice: element?.productId?.price,
            adonsPrice: element?.adonsPrice,
            totalPrice: element?.totalPrice,
            addonsList: element?.addonsList,
            productNote: element?.productNote,
          };
          dummy_ary.push(body);
        }

        const orderBody = {
          storeId: id,
          userName: jode_cart_user?.userName,
          userNumber: jode_cart_user?.userNumber,
          tableNumber: jode_table.tableNumber,
          tableId: jode_table._id,
          orderType: jode_table?.orderType,
          totalOrder: dummy_ary.length,
          totalPrice: jode_cart?.totalPrice,
          product: [...dummy_ary],
          orderNote: noteContent,
          orderAcceptStatus: false,
        };
        console.log(orderBody, "Order body");
        const authData = JSON.parse(localStorage.getItem("jd-u")!);
        const config = {
          headers: {
            Authorization: `Bearer ${authData?.token}`,
          },
        };
        const url = API + `orderCreate`;
        axios
          .post(url, orderBody, config)
          .then((response) => {
            if (response?.data?.success) {
              //EMIT DATA IN SOCKET
              socket?.emit("sendNewOrder", onStoreData?._id);
              localStorage.removeItem("jod-e_shop_cart");
              setOrderCartLocalCount(0);
              toast.success(`Ordered placed Successfully.`);
              navigate(`/shop/view_order`);
              closeHandler();
            } else {
              toast.warn(`${response?.data?.data}`);
            }
          })
          .catch((err) => {
            console.log("error here", err);
          })
          .finally(() => {
            setIsOrderCreateLoading(false);
          });
      }
    }
  };

  const onUpdateNote = (note: string, productId: string) => {
    //FETCH DETAILS FROM LOCALSTORAGE
    const jode_cart = JSON.parse(
      localStorage.getItem("jod-e_shop_cart") as any
    );
    //FIND PRODUCT INDEX
    const productIndex = jode_cart.product.findIndex(
      (x: any) => x.productId?._id === productId
    );
    //UPDATE PRODUCT NOTE WITH EXISTING DETAILS
    jode_cart.product[productIndex].productNote = note;
    //UPDATE DETAIL IN LOCALSTORAGE
    localStorage.setItem("jod-e_shop_cart", JSON.stringify(jode_cart));
    //FETCH LIST AGAIN
    fetchProductLocal();
  };

  const onCloseCheckUser = () => {
    setIsCheckUserOpen(!isCheckUserOpen);
    //USER DETAILS FOR ORDERING PRODUCTS
    const jode_cart_user = JSON.parse(
      localStorage.getItem("jod-e_shop_cart_user")!
    );
    if (jode_cart_user !== null) {
      onOrderFromCart();
    }
  };

  //SOCKET CONNECTION
  const onConnectSocket = () => {
    socket = io(SOCKET_URL);
  };

  React.useEffect(() => {
    fetchProductLocal();
    setTimeout(() => {
      setIsCartOpen(true);
    }, 200);
  }, [fetchProductLocal]);

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-20 h-full w-full bg-[#322f2fa6]">
        <div className="absolute bottom-0 right-0 h-5/6 w-full overflow-auto scrollbar-hide">
          <div className="mx-auto h-full w-full">
            <div
              ref={ref}
              id="cart"
              className="relative mx-auto h-full w-full rounded-tr-2xl rounded-tl-2xl bg-white scrollbar-hide sm:shadow-2xl md:w-[36rem] lg:w-[48rem]"
            >
              <div
                className="absolute right-2 top-2 z-50 cursor-pointer rounded-full bg-black/20 p-2 shadow-lg"
                onClick={() => closeHandler()}
              >
                <XIcon className="h-4 w-4 font-bold" />
              </div>
              <div className="h-full overflow-x-hidden overflow-y-scroll scrollbar-hide">
                <div className="mt-8 p-4 pb-32">
                  {orderCartLocal?.product?.length === 0 && (
                    <div>
                      <img
                        className="mx-auto"
                        src="/assets/shop/order_empty.png"
                        alt="order_empty"
                      />
                      <div className="text-center capitalize">
                        <h2 className="mb-2 text-2xl font-bold">
                          No Orders Yet
                        </h2>
                        <p>
                          Your cart is empty. <br /> Add something from the menu
                        </p>
                      </div>
                    </div>
                  )}
                  {orderCartLocal?.product.map((list: any, index: any) => {
                    return (
                      <span key={index}>
                        <div className="relative mb-1 rounded-md bg-cultured p-2">
                          <div className="flex">
                            <div className="mr-2">
                              <img
                                className="h-24 w-24 rounded-md object-cover"
                                src={`${IMAGE_URL}${list?.productId?.image[0]}`}
                                alt={list?.productId?.name}
                              />
                            </div>
                            <div className="w-fit">
                              <h2 className="mb-1 text-lg font-bold">
                                <ShowMoreText
                                  lines={1}
                                  more=""
                                  less=""
                                  className="w-full"
                                  expanded={false}
                                  truncatedEndingComponent={"... "}
                                >
                                  {list?.productId?.name}
                                </ShowMoreText>
                              </h2>
                              <h2>
                                <ShowMoreText
                                  lines={2}
                                  more=""
                                  less=""
                                  className="text-sm"
                                  expanded={false}
                                  truncatedEndingComponent={"... "}
                                >
                                  {list?.productId?.description}
                                </ShowMoreText>
                              </h2>
                              <div className="flex items-center">
                                {list?.productId?.isVeg ? (
                                  <img
                                    className="h-6 w-6"
                                    src="https://img.icons8.com/color/48/000000/vegetarian-food-symbol.png"
                                    alt="non-veg"
                                  />
                                ) : (
                                  <img
                                    className="h-6 w-6"
                                    src="https://img.icons8.com/color/48/000000/non-vegetarian-food-symbol.png"
                                    alt="veg"
                                  />
                                )}
                                <p className="ml-2 text-lg font-bold">
                                  ₹ {list?.productId?.price}
                                </p>
                              </div>
                            </div>
                          </div>
                          {list?.addonsList.length !== 0 && (
                            <div className="flex items-start justify-between">
                              {viewOrderIngredient === index ? (
                                <div className="w-full pb-1">
                                  {list?.addonsList.map(
                                    (ing: any, index2: any) => {
                                      return (
                                        <div
                                          className="my-0.5 flex items-center justify-between text-sm font-medium"
                                          key={index2}
                                        >
                                          <h2 className="text-gray">
                                            {ing?.ingredient_name}
                                          </h2>
                                          <h3>₹ {ing?.ingredient_price}</h3>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                <div className="w-full pb-1">
                                  <div className="my-0.5 flex items-center justify-between text-sm font-medium">
                                    <h2 className="text-gray">Addons</h2>
                                    <h3>
                                      ₹{" "}
                                      {orderCartLocal.product[index].adonsPrice}
                                    </h3>
                                  </div>
                                </div>
                              )}

                              {viewOrderIngredient === index ? (
                                <div className="pt-1">
                                  <ChevronDownIcon
                                    className="h-4 w-4 cursor-pointer"
                                    onClick={() => onViewOrderIngredient(index)}
                                  />
                                </div>
                              ) : (
                                <div className="pt-1">
                                  <ChevronRightIcon
                                    className="h-4 w-4 cursor-pointer"
                                    onClick={() => onViewOrderIngredient(index)}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          <div className="flex items-center justify-between border-t-2 border-dashed border-gray/30">
                            <div className="mt-2 h-fit w-fit">
                              <QuantityButton
                                small={true}
                                color="bg-white"
                                qty={list?.productQty}
                                onChange={(e) =>
                                  onProductUpdateInCart(e, index)
                                }
                              />
                            </div>
                            <div className="flex justify-end font-medium">
                              <h2 className="pr-3">₹ {list?.totalPrice}</h2>
                            </div>
                          </div>
                        </div>
                        <div className="relative mb-5 rounded-md bg-cultured">
                          <input
                            id="note"
                            type="text"
                            value={list?.productNote}
                            className="w-full border-0 bg-transparent text-gray"
                            placeholder="Write instruction for your dish."
                            onChange={(e) =>
                              onUpdateNote(e.target.value, list?.productId?._id)
                            }
                          />
                        </div>
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="absolute bottom-24 w-full border-t-2 border-gray/20 bg-white px-5 py-2 sm:px-6 lg:px-8">
                <button
                  type="button"
                  className={`${
                    isNoteView ? "mb-2" : ""
                  } inline-flex items-center text-xs font-bold`}
                  onClick={() => setIsNoteView(!isNoteView)}
                >
                  Add Special Requests{" "}
                  {isNoteView ? (
                    <ChevronUpIcon className="ml-1.5 h-4 w-4" />
                  ) : (
                    <ChevronDownIcon className="ml-1.5 h-4 w-4" />
                  )}
                </button>
                {isNoteView && (
                  <textarea
                    rows={3}
                    className="w-full resize-none rounded-md border border-gray/30 focus:border-gray/30 focus:ring-0"
                    onChange={(e) => setNoteContent(e.target.value)}
                  />
                )}
              </div>
              <div className="absolute bottom-0 w-full border-t-2 border-gray/20 bg-white">
                <div className="mx-auto my-2 w-11/12 justify-between">
                  <div className="mb-2 flex items-center justify-between text-xl font-bold">
                    <h2>SubTotal</h2>
                    <h2>₹ {orderCartLocal?.totalPrice || "00.00"}</h2>
                  </div>

                  <Button
                    width={"100%"}
                    onClick={onOrderFromCart}
                    loading={isOrderCreateLoading}
                  >
                    Place Order
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isCheckUserOpen && (
        <CheckUser
          isOpen={isCheckUserOpen}
          onClose={() => onCloseCheckUser()}
        />
      )}
    </>
  );
};

export default CartView;
