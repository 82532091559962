import React from "react";
import axios from "axios";
import API, { IMAGE_URL } from "config/Api";
import { toast } from "react-toastify";
import ShowMoreText from "react-show-more-text";
import { ShopOrderObject, ShopOrders } from "types";
import { Button, QuantityButton } from "components/ui";
import KotRequest from "components/shops/orderCreate/KotRequest";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";

const OrderRequest = () => {
  const [ordersList, setOrdersList] = React.useState<ShopOrders | []>([]);
  const [selectedOrder, setSelectedOrder] = React.useState<any>(null);
  const [viewOrderIngredient, setViewOrderIngredient] =
    React.useState<Number | null>(null);

  //FETCH ORDER LIST WHICH ARE NOT ACCEPTED
  const fetchOrderList = React.useCallback(() => {
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
      params: {
        page: 1,
        limit: 100,
        tableNo: "",
        userNo: "",
      },
    };

    const url = API + `getorders/${authData?.storeId}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setOrdersList(response?.data?.data?.Orders);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  }, []);

  //SELECT ORDER LIST FOR UPDATE IN MODEL
  const onSelectedOrder = (list: ShopOrderObject) => {
    const updateOrder = {
      storeId: list?.storeId?._id,
      userNumber: list?.userNumber,
      tableNumber: list?.tableNumber,
      totalOrder: list?.totalOrder,
      totalPrice: list?.totalPrice,
      orderType: list?.orderType,
      isPaymentDone: list?.isPaymentDone,
      orderAcceptStatus: list?.orderAcceptStatus,
      paymentMode: list?.paymentMode,
      userName: list?.userName,
      _id: list?._id,
      product: [...list?.product],
    };
    setSelectedOrder(updateOrder);
    localStorage.setItem(
      "jode_staff_caption_reorder",
      JSON.stringify(updateOrder)
    );
  };

  //FOR CLOSE ORDER UPDATE MODEL
  const onCancel = () => {
    setSelectedOrder(null);
    localStorage.removeItem("jode_staff_caption_reorder");
  };

  //VIEW ADDED ADDONS IN PRODUCT
  const onViewOrderIngredient = (index: any) => {
    if (viewOrderIngredient === index) {
      setViewOrderIngredient(null);
    } else {
      setViewOrderIngredient(index);
    }
  };

  //UPDATE / DELETE PRODUCT QUANTITY
  const onProductUpdateInCart = async (quantity: any, index: any) => {
    //GET CART PRODUCT FROM LOCALSTORAGE
    const jode_cart = JSON.parse(
      localStorage.getItem("jode_staff_caption_reorder") as any
    );
    if (quantity === 0) {
      jode_cart.product.splice(index, 1);
      //CALCUTATE TOTAL PRICE OF PRODUCT AFTER REMOVE
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      //UPDATE IN MAIN BODY
      const updateOrder = {
        storeId: jode_cart?.storeId,
        userNumber: jode_cart?.userNumber,
        tableNumber: selectedOrder?.tableNumber,
        totalOrder: parseInt(jode_cart?.totalOrder) - 1,
        totalPrice: totalPrice,
        orderType: jode_cart?.orderType,
        isPaymentDone: jode_cart?.isPaymentDone,
        orderAcceptStatus: jode_cart?.orderAcceptStatus,
        paymentMode: jode_cart?.paymentMode,
        userName: jode_cart?.userName,
        _id: jode_cart?._id,
        product: [...jode_cart?.product],
      };
      //SET IN LOCALSTORAGE
      localStorage.setItem(
        "jode_staff_caption_reorder",
        JSON.stringify(updateOrder)
      );
      //setOrderCartLocalCount(updateOrder?.product?.length);
      //SET IN USESTATE FOR VIEW UPDATE
      //SEND FINAL ORDER IN STATE->
      setSelectedOrder(updateOrder);
      //   setTotalPayAmount(Number(updateOrder?.totalPrice));
    } else {
      //FIND PRODUCT IN LOCALSTORAGE WITH INDEX
      const addonsIndex = jode_cart.product.findIndex(
        (x: any) => x.productId?._id === jode_cart.product[index].productId?._id
      );
      //CRATE UPDATED PRODUCT BODY WITH INDEX

      const addProduct = {
        productId: jode_cart.product[index].productId,
        productQty: quantity,
        productPrice: parseInt(jode_cart.product[index].productPrice),
        adonsPrice: parseInt(jode_cart.product[index].adonsPrice),
        totalPrice:
          (parseInt(jode_cart.product[index].productPrice) +
            parseInt(jode_cart.product[index].adonsPrice)) *
          quantity,
        addonsList: jode_cart.product[index].addonsList,
      };
      //SET UPDATED PRODUCT ON SAME INDEX
      jode_cart.product[addonsIndex] = addProduct;
      //CALCUTATE TOTAL PRICE OF PRODUCT
      const totalPrice = jode_cart.product.reduce(
        (total: any, currentValue: any) =>
          (total = total + parseInt(currentValue.totalPrice)),
        0
      );
      //UPDATE IN MAIN BODY
      const updateOrder = {
        storeId: jode_cart?.storeId,
        userNumber: jode_cart?.userNumber,
        tableNumber: selectedOrder?.tableNumber,
        totalOrder: jode_cart?.totalOrder,
        totalPrice: totalPrice,
        orderType: jode_cart?.orderType,
        isPaymentDone: jode_cart?.isPaymentDone,
        orderAcceptStatus: jode_cart?.orderAcceptStatus,
        paymentMode: jode_cart?.paymentMode,
        userName: jode_cart?.userName,
        _id: jode_cart?._id,
        product: [...jode_cart?.product],
      };
      //SET IN LOCALSTORAGE
      localStorage.setItem(
        "jode_staff_caption_reorder",
        JSON.stringify(updateOrder)
      );
      //SET IN USESTATE FOR VIEW UPDATE
      setSelectedOrder(updateOrder);
    }
  };

  //CREATE UPDATED ORDER WITH API
  const onCreateFinalOrder = () => {
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };

    const url = API + `order/update`;
    axios
      .patch(url, { ...selectedOrder, orderAcceptStatus: true }, config)
      .then((response) => {
        if (response?.data?.success) {
          //FETCH ORDER LIST AND CLOSE MODEL AFTER SUCCESSFULLY UPDATE ORDER
          fetchOrderList();
          onCancel();
          toast.success(response?.data?.message);
        } else {
          //IF NOT SHOW ERROR MESSAGE
          toast.warn(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {});
  };

  //INITIAL VALUE
  React.useEffect(() => {
    fetchOrderList();
  }, [fetchOrderList]);

  return (
    <>
      <DashboardHeader title={"Order Request"} />
      <section className="3xl:mx-w-[110rem] relative mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <KotRequest
          inDetail={true}
          ordersList={ordersList}
          selectOrder={onSelectedOrder}
        />
        {selectedOrder !== null && (
          <div className="sticky bottom-5 mx-auto h-fit w-full rounded-xl border border-black/10 bg-cultured p-3 shadow-xl md:w-full lg:w-[48rem]">
            {selectedOrder?.product.map((list: any, index: any) => {
              return (
                <div
                  className="mb-5 border-b border-gray/50 bg-cultured p-2 pb-3"
                  key={index}
                >
                  <div className="flex">
                    <div className="mr-2">
                      <img
                        className="h-24 w-24 rounded-md object-cover"
                        src={`${IMAGE_URL}${list?.productId?.image[0]}`}
                        alt={list?.productId?.name}
                      />
                    </div>
                    <div className="w-fit">
                      <h2 className="mb-1 text-lg font-bold">
                        <ShowMoreText
                          lines={1}
                          more=""
                          less=""
                          className="w-full"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          {list?.productId?.name}
                        </ShowMoreText>
                      </h2>
                      <h2>
                        <ShowMoreText
                          lines={2}
                          more=""
                          less=""
                          className="text-sm"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          {list?.productId?.description}
                        </ShowMoreText>
                      </h2>
                      <div className="flex items-center">
                        {list?.productId?.isVeg ? (
                          <img
                            className="h-6 w-6"
                            src="https://img.icons8.com/color/48/000000/vegetarian-food-symbol.png"
                            alt="non-veg"
                          />
                        ) : (
                          <img
                            className="h-6 w-6"
                            src="https://img.icons8.com/color/48/000000/non-vegetarian-food-symbol.png"
                            alt="veg"
                          />
                        )}
                        <p className="ml-2 text-lg font-bold">
                          ₹ {list?.productId?.price}
                        </p>
                      </div>
                    </div>
                  </div>
                  {list?.addonsList.length !== 0 && (
                    <div className="flex items-start justify-between">
                      {viewOrderIngredient === index ? (
                        <div className="w-full pb-1">
                          {list?.addonsList.map((ing: any, index2: any) => {
                            return (
                              <div
                                className="my-0.5 flex items-center justify-between text-sm font-medium"
                                key={index2}
                              >
                                <h2 className="text-gray">
                                  {ing?.ingredient_name}
                                </h2>
                                <h3>₹ {ing?.ingredient_price}</h3>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="w-full pb-1">
                          <div className="my-0.5 flex items-center justify-between text-sm font-medium">
                            <h2 className="text-gray">Addons</h2>
                            <h3>₹ {selectedOrder.product[index].adonsPrice}</h3>
                          </div>
                        </div>
                      )}

                      {viewOrderIngredient === index ? (
                        <div className="pt-1">
                          <ChevronDownIcon
                            className="h-4 w-4 cursor-pointer"
                            onClick={() => onViewOrderIngredient(index)}
                          />
                        </div>
                      ) : (
                        <div className="pt-1">
                          <ChevronRightIcon
                            className="h-4 w-4 cursor-pointer"
                            onClick={() => onViewOrderIngredient(index)}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex items-center justify-between border-t-2 border-dashed border-gray/30">
                    <div className="mt-2 h-fit w-fit">
                      <QuantityButton
                        small={true}
                        color="bg-white"
                        qty={parseInt(list?.productQty)}
                        onChange={(e) =>
                          onProductUpdateInCart(parseInt(e), index)
                        }
                      />
                    </div>
                    <div className="flex justify-end font-medium">
                      <h2 className="pr-3">₹ {list?.totalPrice}</h2>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="my-3 flex items-center justify-between pt-3 font-medium text-gray">
              <div className="space-y-1">
                <h2>Subtotal</h2>
                <h2 className="text-black">To Pay</h2>
              </div>
              <div className="space-y-1 text-right">
                <h2>₹ {selectedOrder?.totalPrice || "00.00"}</h2>
                <h2 className="text-black">
                  ₹ {selectedOrder?.totalPrice || "00.00"}
                </h2>
              </div>
            </div>
            <div className="mt-4 flex justify-center space-x-3">
              <Button loading={false} onClick={() => onCreateFinalOrder()}>
                Update & Checkout
              </Button>
              <Button onClick={() => onCancel()}>Cancel</Button>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default OrderRequest;
