import React, { InputHTMLAttributes } from "react";
import HeroIcon from "../HeroIcon";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  type: string;
  title: string;
  placeholder: string;
  value: string;
  disable?: Boolean;
  white?: Boolean;
  onChange?: (...args: any[]) => any;
}

const InputUpload: React.FC<InputProps> = (props) => {
  const {
    className,
    type,
    children,
    title,
    placeholder,
    value,
    onChange,
    disable,
    white,
    ...rest
  } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e);
    }
    return null;
  };

  return (
    <div
      className={`${
        white
          ? "w-full flex items-center justify-between py-2.5 px-4 rounded-md bg-white mb-4"
          : "w-full flex items-center justify-between py-2.5 px-4 rounded-md bg-cultured mb-4"
      }`}
    >
      <p className="capitalize text-philippine-gray truncate">{title}</p>
      {value === "" || null || undefined ? (
        <label
          htmlFor="uploadFileLink"
          className="bg-philippine-gray rounded-full cursor-pointer"
        >
          <HeroIcon className="h-9 w-9 text-white p-2" icon="PlusIcon" />
          <input
            className="hidden"
            type="file"
            name="uploadFileLink"
            id="uploadFileLink"
            onChange={handleOnChange}
            {...rest}
          />
        </label>
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};

export default InputUpload;
