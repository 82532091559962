const icons = [
  "amazon.png",
  "amazonpay.png",
  "app-links.png",
  "apple-music.png",
  "bank-details.png",
  "behance.png",
  "calendy.png",
  "call.png",
  "create-contact.png",
  "custom.png",
  "direct.png",
  "doc.png",
  "document.png",
  "dribbble.png",
  "emergency-contact.png",
  "event.png",
  "facebook.png",
  "google-maps.png",
  "google-reviews.png",
  "googlepay.png",
  "instagram.png",
  "linkedin.png",
  "mail.png",
  "message.png",
  "paypal.png",
  "paytm.png",
  "personal-information.png",
  "phonepe.png",
  "pinterest.png",
  "skype.png",
  "snapchat.png",
  "spotify.png",
  "telegram.png",
  "twitter.png",
  "website.png",
  "whatsapp.png",
  "youtube.png",
  "shop.png",
];

export default icons;