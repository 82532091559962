import React from "react";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  product: any;
}

const SearchCart: React.FC<Props> = ({ product }) => {
  const { id } = useParams();
  let navigate = useNavigate();

  const onViewCart = () => {
    navigate(`/shop/${id}?view=CART`);
  };

  return (
    <>
      <div className="absolute bottom-2 mx-auto w-full">
        <div className="flex justify-center">
          <div className="mr-3 flex w-80 items-center justify-between rounded-lg bg-raisin-black p-3 text-white shadow-2xl">
            <div className="text-xs">
              <p>
                {product?.product?.length} Item{" "}
                <span className="mx-1 text-sm font-semibold">|</span> ₹{" "}
                {product?.totalPrice}
              </p>
              <p className="font-thin capitalize">extra charges may apply</p>
            </div>
            <div className="select-none cursor-pointer" onClick={onViewCart}>
              <h2>View Cart</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCart;
