import * as SolidIcons from "@heroicons/react/solid";
import * as OutlineIcons from "@heroicons/react/outline";

interface Props {
  icon: string;
  className?: string;
  outline?: boolean;
}

const HeroIcon = (props: Props): JSX.Element => {
  const { icon, className, outline = false } = props;

  const { ...icons } = outline ? OutlineIcons : SolidIcons;

  // @ts-ignore
  const Icon: JSX.Element = icons[icon];

  return (
    // @ts-ignore
    <Icon className={className} />
  );
};

export default HeroIcon;
