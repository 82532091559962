import _ from "lodash";
import React from "react";
import { ShopProduct } from "types";
import { IMAGE_URL } from "config/Api";
import ShowMoreText from "react-show-more-text";
import { ShoppingCartIcon } from "@heroicons/react/outline";

interface Props {
  onViewProduct?: any;
  onLoading: Boolean | true;
  productsList: ShopProduct | [];
  children: any;
}

const ProductViewBox: React.FC<Props> = ({
  productsList,
  onViewProduct,
  onLoading,
  children,
}) => {
  const [imageLoading, setImageLoading] = React.useState(true);
  const linksDisplay = _.groupBy(
    productsList
      ?.sort((a: any, b: any) =>
        a?.category_id?.priority > b?.category_id?.priority ? 1 : -1
      )
      ?.filter((active: any) => active?.category_id?.is_active),
    "category_id.name"
  );

  const viewProductHandler = onViewProduct;

  if (onLoading) {
    return (
      <>
        <div className="mb-20">
          <h3 className="my-3 text-xl font-bold capitalize">Products</h3>
          <div className="grid w-2/4 rounded-xl border border-cultured p-2 shadow">
            <div className="animate-pulse">
              <div className="h-28 w-full rounded-tr-xl rounded-tl-xl bg-black/20 object-cover"></div>
              <div className="mt-4 flex-1 space-y-6">
                <div className="h-2 w-2/4 rounded bg-black/20"></div>
                <div className="space-y-3">
                  <div className="grid space-y-1">
                    <div className="h-2 rounded bg-black/20"></div>
                    <div className="h-2 w-11/12 rounded bg-black/20"></div>
                    <div className="h-2 rounded bg-black/20"></div>
                    <div className="h-2 w-11/12 rounded bg-black/20"></div>
                    <div className="h-2 w-2/3 rounded bg-black/20"></div>
                  </div>
                </div>
                <div className="grid grid-cols-2 space-x-2">
                  <div className="h-2 rounded bg-black/20"></div>
                  <div className="h-2 rounded bg-black/20"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="mb-20">
          {children}
          {Object.keys(linksDisplay).map((key, index) => {
            return (
              <div key={index}>
                <h3 id={`${key}`} className="my-4 text-xl font-bold capitalize">
                  {key}
                  <span className="text-sm">
                    {" "}
                    ({linksDisplay[key]?.length})
                  </span>
                </h3>
                <ul className="grid grid-cols-2 gap-4">
                  {linksDisplay[key]
                    ?.sort((a: any, b: any) => a?.name.localeCompare(b?.name))
                    ?.filter((item) => item.isActive)
                    ?.map((items, index) => {
                      return (
                        <div
                          key={index}
                          className="grid w-full rounded-xl bg-cultured p-2"
                        >
                          <div
                            className={`${
                              imageLoading
                                ? `block animate-pulse`
                                : `hidden animate-pulse`
                            }`}
                          >
                            <div className="h-28 w-full cursor-pointer rounded-tr-xl rounded-tl-xl bg-black/20 object-cover"></div>
                          </div>
                          <div
                            className={`${imageLoading ? `hidden` : `block`}`}
                          >
                            <img
                              className="h-28 w-full cursor-pointer rounded-tr-xl rounded-tl-xl object-cover"
                              src={`${IMAGE_URL}${items?.image[0]}`}
                              alt={`${items?.name}`}
                              onLoad={() => setImageLoading(false)}
                              onClick={() => viewProductHandler(items)}
                            />
                          </div>
                          <div>
                            <h2
                              className="cursor-pointer text-lg font-bold"
                              onClick={() => viewProductHandler(items)}
                            >
                              <ShowMoreText
                                lines={1}
                                more=""
                                less=""
                                className=""
                                expanded={false}
                                truncatedEndingComponent={"... "}
                              >
                                {items?.name}
                              </ShowMoreText>
                            </h2>
                            <h2>
                              <ShowMoreText
                                lines={3}
                                more=""
                                less=""
                                className="text-sm"
                                expanded={false}
                                truncatedEndingComponent={"... "}
                              >
                                {items?.description}
                              </ShowMoreText>
                            </h2>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              {items?.isVeg ? (
                                <img
                                  className="h-6 w-6"
                                  src="https://img.icons8.com/color/48/000000/vegetarian-food-symbol.png"
                                  alt="non-veg"
                                />
                              ) : (
                                <img
                                  className="h-6 w-6"
                                  src="https://img.icons8.com/color/48/000000/non-vegetarian-food-symbol.png"
                                  alt="veg"
                                />
                              )}
                              <p className="ml-2 text-xl font-bold">
                                ₹ {items?.price}
                              </p>
                            </div>
                            <div>
                              <button
                                className="rounded-lg bg-black p-2 text-white"
                                onClick={() => viewProductHandler(items)}
                              >
                                <ShoppingCartIcon className="h-4 w-4" />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </ul>
              </div>
            );
          })}
        </section>
      </>
    );
  }
};

export default ProductViewBox;
