import React from "react";
import { Loading } from "components/ui";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="flex flex-col-reverse items-center justify-center gap-16 px-4 py-24 md:gap-28 md:py-20 md:px-44 lg:flex-row lg:px-24 lg:py-24">
          <div className="relative w-full pb-12 lg:pb-0 xl:w-1/2 xl:pt-24">
            <div className="relative">
              <div className="absolute">
                <div className="">
                  <h1 className="text-gray-800 my-2 text-2xl font-bold">
                    Looks like you've found the doorway to the great nothing
                  </h1>
                  <p className="text-gray-800 my-2">
                    Sorry about that! Please visit our hompage to get where you
                    need to go.
                  </p>
                  <button
                    className="md focus:ring-indigo-700 my-2 rounded border bg-gold/40 py-4 px-8 text-center text-raisin-black backdrop-blur-md hover:bg-gold focus:outline-none focus:ring-2 focus:ring-opacity-50 sm:w-full lg:w-auto"
                    onClick={() => navigate(`/profile`)}
                  >
                    Take me there!
                  </button>
                </div>
              </div>
              <div>
                <img src="https://i.ibb.co/G9DC8S0/404-2.png" alt="000" />
              </div>
            </div>
          </div>
          <div>
            <img src="https://i.ibb.co/ck1SGFJ/Group.png" alt="000" />
          </div>
        </div>
      </>
    );
  }
};

export default PageNotFound;
