import React from "react";
import moment from "moment";
import { ShopOpenHour, ShopType } from "types";
import { IMAGE_URL } from "config/Api";

interface Props {
  shopDetails: ShopType | any;
  onLoading: Boolean | true;
}

const ShopDetailBox: React.FC<Props> = ({ shopDetails, onLoading }) => {
  const [dateDisplay, setDateDisplay] = React.useState<ShopOpenHour | null>(
    null
  );
  const jode_table = JSON.parse(
    localStorage.getItem("jod-e_table_selection") as any
  );

  const onOrderType = (type: string) => {
    switch (type) {
      case "dine_in":
        return "Dine In";
      case "pickup":
        return "Pick Up";
      case "delivery":
        return "Delivery";
      default:
        break;
    }
  };

  React.useEffect(() => {
    let weekDay = moment(new Date()).format("dddd");
    for (let i = 0; i < shopDetails?.open_hours?.length; i++) {
      const element = shopDetails?.open_hours[i];
      if (element.weekDay === weekDay) {
        setDateDisplay(element);
      }
    }
  }, [shopDetails?.open_hours]);

  if (onLoading) {
    return (
      <>
        <div className="w-full rounded-xl border border-cultured p-2 shadow">
          <div className="flex animate-pulse">
            <div className="h-32 w-32 rounded-lg bg-black/20 object-cover"></div>
            <div className="ml-4 flex-1 space-y-4 pt-3">
              <div className="h-2 w-2/5 rounded bg-black/20"></div>
              <div className="space-y-3">
                <div className="grid space-y-1">
                  <div className="h-2 rounded bg-black/20"></div>
                  <div className="h-2 w-11/12 rounded bg-black/20"></div>
                  <div className="h-2 rounded bg-black/20"></div>
                  <div className="h-2 w-11/12 rounded bg-black/20"></div>
                  <div className="h-2 w-2/3 rounded bg-black/20"></div>
                </div>
              </div>
              <div className="grid grid-cols-2 space-x-2">
                <div className="h-2 w-16 rounded bg-black/20"></div>
                <div className="h-2 w-16 rounded bg-black/20"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="flex h-fit gap-5 rounded-xl bg-cultured p-3">
          <div className="mr-2">
            <img
              className="h-32 w-32 rounded-lg object-cover"
              src={`${IMAGE_URL}${shopDetails?.image}`}
              alt={`${shopDetails?.shop_name}`}
            />
          </div>
          <div className="w-fit">
            <h2 className="text-xl font-bold">{shopDetails?.shop_name}</h2>
            <div className="my-2 grid grid-cols-2 items-center space-x-2 text-sm font-medium">
              <div className="flex items-center">
                <h2>Table&nbsp;</h2>
                <p>{jode_table?.tableNumber}</p>
              </div>
              <div>
                <h2>{onOrderType(jode_table?.orderType)}</h2>
              </div>
            </div>
            <div className="mt-2 flex items-center rounded-md bg-white p-1 text-xs">
              <h2 className="w-2/4 text-center">
                {dateDisplay?.isOpen ? "Open Now" : "Close Now"}
              </h2>
              <h2 className="w-2/4 rounded-md bg-cultured p-1 text-center">
                {dateDisplay?.startTime} - {dateDisplay?.endTime}
              </h2>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ShopDetailBox;
