import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

export const useTableProps = ({ columns, data }: any) => {
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } as any },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state,
  } = tableInstance as any;
  const { pageIndex, pageSize, globalFilter } = state as any;

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    pageIndex,
    pageSize,
    globalFilter,
  };
};
