import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Button, Separate } from "components/ui";
import React from "react";
import { useForm } from "react-hook-form";

interface Props {
  onOpen: boolean;
  onClose: any;
  orderPlaced: any;
}

interface UserDetails {
  userName?: string;
  userNumber?: string;
}

const CustomerDetailsMode: React.FC<Props> = ({
  onOpen,
  onClose,
  orderPlaced,
}) => {
  const closeHandler = onClose;
  const orderPlacedHandler = orderPlaced;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit((data: UserDetails) => {
    orderPlacedHandler(data);
    closeHandler();
  });

  return (
    <Transition appear show={onOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => closeHandler()}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-gray-900 flex justify-between pb-4 text-xl font-medium leading-6"
                >
                  Customer Details
                  <XIcon
                    className="h-5 w-5 cursor-pointer"
                    onClick={() => closeHandler()}
                  />
                </Dialog.Title>
                <Separate dark={true} />
                <div className="p-5 md:px-10">
                  <form onSubmit={onSubmit}>
                    <div className="grid grid-cols-1 space-y-4 md:grid-cols-2">
                      <div className="grid md:mx-5">
                        <label className="text-md font-bold" htmlFor="userName">
                          Customer Name
                        </label>
                        <input
                          className="mt-2 rounded-md border-0 bg-cultured"
                          type="text"
                          id="userName"
                          placeholder="Customer Name"
                          {...register("userName", { required: true })}
                        />
                        {errors.userName && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div>
                      <div className="grid md:mx-5">
                        <label
                          className="text-md font-bold"
                          htmlFor="userNumber"
                        >
                          Customer Number
                        </label>
                        <input
                          className="mt-2 rounded-md border-0 bg-cultured"
                          type="number"
                          id="userNumber"
                          placeholder="Customer Number"
                          {...register("userNumber", { required: true })}
                        />
                        {errors.userNumber && (
                          <p className="flex justify-end text-sm text-[#CC3300]">
                            This field is required.
                          </p>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="flex justify-end gap-5">
                  <Button loading={false} onClick={() => onSubmit()}>
                    Add
                  </Button>
                  <Button loading={false} onClick={() => closeHandler()}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CustomerDetailsMode;
