import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import axios from "axios";
import { Button, Separate } from "components/ui";
import API from "config/Api";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface Props {
  onOpen: any;
  onClose: any;
}

const PetpoojaIntegrationModel: React.FC<Props> = ({ onOpen, onClose }) => {
  const closeHandler = onClose;
  const [isLoading, setIsLoading] = React.useState(false);
  const [petpoojaPos, setPetpoojaPos] = React.useState(false);
  const [squarePos, setSquarePos] = React.useState(false);
  const [posAddedName, setPosAddedName] = React.useState<null | string>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const fetchPosDetails = React.useCallback(() => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    let URL = API + `pos`;
    axios
      .get(URL, config)
      .then((response) => {
        if (response?.data?.success) {
          //CHECK FOR POS
          if (response?.data?.data?.petpooja_key?.access_token !== null) {
            setPosAddedName("Petpooja");
          } else if (response?.data?.data?.square_key?.access_token !== null) {
            setPosAddedName("Square");
          }

          //SET VALUE FOR PETPOOJA
          const petpooja_key = response?.data?.data?.petpooja_key;
          setValue("access_token", petpooja_key?.access_token);
          setValue("app_key", petpooja_key?.app_key);
          setValue("app_secret", petpooja_key?.app_secret);
          setValue("restID", petpooja_key?.restID);
          //SET VALUE FOR SQUARE
          const square_key = response?.data?.data?.square_key;
          setValue("application_iD", square_key?.application_iD);
          setValue("access_token_square", square_key?.access_token);
          setValue("api_version", square_key?.api_version);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setValue]);

  const onCreatePetpoojaDetails = handleSubmit((data: any) => {
    setIsLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };

    const body = {
      app_key: data.app_key,
      app_secret: data.app_secret,
      access_token: data.access_token,
      restID: data.restID,
      storeId: authData?.storeId,
    };
    let URL = API + `pos/create`;
    axios
      .post(URL, body, config)
      .then((response) => {
        if (response?.data?.success) {
          toast.success("successfully integrate with petpooja account.");
          closeHandler();
        } else {
          toast.warn(`Error while integrate with petpooja account.`);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  const onCreateSquareDetails = handleSubmit((data: any) => {
    setIsLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const body = {
      application_iD: data.application_iD,
      access_token: data.access_token_square,
      api_version: data.api_version,
      storeId: authData?.storeId,
    };
    let URL = API + `pos/squareCreate`;
    axios
      .post(URL, body, config)
      .then((response) => {
        if (response?.data?.success) {
          toast.success("successfully integrate with square account.");
          closeHandler();
        } else {
          toast.warn(`Error while integrate with square account.`);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  React.useEffect(() => {
    fetchPosDetails();
    switch (posAddedName) {
      case "Petpooja":
        setPetpoojaPos(true);
        setSquarePos(false);
        break;
      case "Square":
        setSquarePos(true);
        setPetpoojaPos(false);
        break;
      case null:
        setPetpoojaPos(true);
        break;
      default:
        break;
    }
  }, [fetchPosDetails, posAddedName]);

  return (
    <>
      <Transition appear show={onOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeHandler}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 flex justify-between pb-4 text-xl font-medium leading-6"
                  >
                    Activate POS
                    <XIcon
                      className="h-5 w-5 cursor-pointer"
                      onClick={closeHandler}
                    />
                  </Dialog.Title>
                  <Separate dark={true} />
                  <div className="my-10 grid md:flex">
                    {posAddedName === null ? (
                      <div className="mb-5 w-full border-b-2 border-gray/60 md:mb-0 md:w-40 md:border-b-0 md:border-r-2 md:pr-3 ">
                        <h2
                          className={
                            petpoojaPos
                              ? "mb-3 cursor-pointer rounded-md bg-gold p-2 font-bold capitalize"
                              : "mb-3 cursor-pointer font-bold capitalize"
                          }
                          onClick={() => {
                            setPetpoojaPos(true);
                            setSquarePos(false);
                          }}
                        >
                          Petpooja
                        </h2>
                        <h2
                          className={
                            petpoojaPos
                              ? "mb-3 cursor-pointer font-bold capitalize"
                              : "mb-3 cursor-pointer rounded-md bg-gold p-2 font-bold capitalize"
                          }
                          onClick={() => {
                            setPetpoojaPos(false);
                            setSquarePos(true);
                          }}
                        >
                          Square
                        </h2>
                      </div>
                    ) : (
                      <div className="mb-5 w-full border-b-2 border-gray/60 md:mb-0 md:w-40 md:border-b-0 md:border-r-2 md:pr-3 ">
                        <h2 className="mb-3 cursor-pointer rounded-md bg-gold p-2 font-bold capitalize">
                          {posAddedName}
                        </h2>
                      </div>
                    )}
                    {petpoojaPos && (
                      <form className="ml-5" onSubmit={onCreatePetpoojaDetails}>
                        <div className="mb-2">
                          <h2 className="text-xl font-bold">
                            Petpooja POS Deatils
                          </h2>
                        </div>
                        <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
                          <div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="account_app_key"
                              >
                                App Key
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="text"
                                id="account_app_key"
                                placeholder="App Key"
                                {...register("app_key", {
                                  required: true,
                                })}
                              />
                              {errors.app_key && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="account_app_secret"
                              >
                                App Secret
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="text"
                                id="account_app_secret"
                                placeholder="App Secret"
                                {...register("app_secret", {
                                  required: true,
                                })}
                              />
                              {errors.app_secret && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="account_access_token"
                              >
                                Access Token
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="text"
                                id="account_access_token"
                                placeholder="Access Token"
                                {...register("access_token", {
                                  required: true,
                                })}
                              />
                              {errors.access_token && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="restID"
                              >
                                Restaurant Id
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="text"
                                id="restID"
                                placeholder="Restaurant Id"
                                {...register("restID", {
                                  required: true,
                                })}
                              />
                              {errors.restID && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                    {squarePos && (
                      <form className="ml-5" onSubmit={onCreateSquareDetails}>
                        <div className="mb-2">
                          <h2 className="text-xl font-bold">
                            Square POS Deatils
                          </h2>
                        </div>
                        <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
                          <div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="application_iD"
                              >
                                Application ID
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="text"
                                id="application_iD"
                                placeholder="Application ID"
                                {...register("application_iD", {
                                  required: true,
                                })}
                              />
                              {errors.application_iD && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="access_token_square"
                              >
                                Access Token
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="text"
                                id="access_token_square"
                                placeholder="Access Token"
                                {...register("access_token_square", {
                                  required: true,
                                })}
                              />
                              {errors.access_token_square && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="my-5">
                              <label
                                className="text-md font-medium"
                                htmlFor="api_version"
                              >
                                API Version
                              </label>
                              <input
                                className="mt-1 w-full rounded-md border-0 bg-cultured"
                                type="text"
                                id="api_version"
                                placeholder="API Version"
                                {...register("api_version", {
                                  required: true,
                                })}
                              />
                              {errors.api_version && (
                                <p className="flex justify-end text-sm text-[#CC3300]">
                                  This field is required.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                  {petpoojaPos && (
                    <div className="flex justify-end">
                      <Button
                        loading={isLoading}
                        onClick={onCreatePetpoojaDetails}
                      >
                        Create POS 1
                      </Button>
                    </div>
                  )}
                  {squarePos && (
                    <div className="flex justify-end">
                      <Button
                        loading={isLoading}
                        onClick={onCreateSquareDetails}
                      >
                        Create POS 2
                      </Button>
                    </div>
                  )}
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PetpoojaIntegrationModel;
