import React from "react";
import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BankDetail from "components/shops/profile/BankDetail";
import ShopTiming from "components/shops/profile/ShopTiming";
import AccountStatus from "components/shops/profile/AccountStatus";
import PaymentQrModel from "components/shops/profile/PaymentQrModel";
import ProfileDetails from "components/shops/profile/ProfileDetails";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";
import PetpoojaIntegration from "components/shops/profile/PetpoojaIntegration";
import PetpoojaIntegrationModel from "components/shops/profile/PetpoojaIntegrationModel";

interface Props {}

const ShopProfile: React.FC<Props> = () => {
  let navigate = useNavigate();
  const [storeData, setStoreData] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPetpoojaIntegration, setIsPetpoojaIntegration] =
    React.useState<boolean>(false);
  const [isOpenBank, setIsOpenBank] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPaymentQrModel, setIsPaymentQrModel] = React.useState(false);
  const [accountStatusLoading, setAccountStatusLoading] = React.useState(false);
  const [storeStatusLoading, setStoreStatusLoading] = React.useState(false);
  const [bankDetail, setBankDetail] = React.useState(null);
  const formData = new FormData();

  //FETCH STORE DETAILS
  const fetchStoreDetails = React.useCallback(() => {
    setStoreStatusLoading(true);
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `store/${authData?.storeId}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setStoreData(response?.data?.data);
          localStorage.setItem("jd-u-shop", JSON.stringify(response.data.data));
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setStoreStatusLoading(false);
      });
  }, []);

  //FETCH STORE BANK DETAILS
  const fetchStoreBankDetails = React.useCallback(() => {
    setAccountStatusLoading(true);
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `bankDetails/${authData?.storeId}`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          if (response?.data?.data !== null) {
            setBankDetail(response?.data?.data || null);
          } else {
            setBankDetail(null);
          }
        }
      })
      .catch((err) => {
        console.log("error here", err);
      })
      .finally(() => {
        setAccountStatusLoading(false);
      });
  }, []);

  //UPDATE USER LINK WITH STORE IMAGE
  const onLinkUpdate = (name: string, image: string) => {
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    //API body
    const body = {
      social_icon: image,
      social_media_name: name,
      is_custom: true,
    };

    const linkUpdateUrl = API + `links/${storeData?.link_id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };

    axios
      .patch(linkUpdateUrl, body, config)
      .then((response) => {
        if (response.data.success) {
          toast.success("The link will reflect right away on your profile.");
        } else {
          toast.warning(`Server Eror.`);
        }
      })
      .catch((err) => {
        console.log("error here", err.response);
      });
  };

  const onUpdateStore = (storeUpdateData: any, newImg: any) => {
    setIsLoading(true);
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    let URL = API + `edit/${storeData?._id}`;
    //CHECK FOR NEW IMAGE ADDED
    if (newImg.length === 0) {
      //UPDATE STORE PROFILE WITHOUT IMAGE
      axios
        .patch(URL, storeUpdateData, config)
        .then((response) => {
          if (response?.data?.success) {
            toast.success(`Your Store Profile Updated Successfully.`);
            fetchStoreDetails();
            //UPDATE STORE IMAGE IN LINK ALSO
            onLinkUpdate(storeUpdateData?.shop_name, storeUpdateData?.image);
          } else {
            toast.warn(`Error while updating store profile.`);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      //UPDATE STORE PROFILE WITH IMAGE
      newImg.forEach((file: string | Blob) => {
        formData.append("image", file);
      });
      //CONFIG AND CREATE AWS S3 FOLDER FOR IMAGE UPLOAD
      const config2 = {
        headers: {
          Authorization: `Bearer ${authData?.token}`,
        },
        params: {
          //IT'S CREATE INDIVIDUAL FOLDER IN AWS FOR SHOP TO ADD PHOTOS.
          directory: storeData?.shop_name,
        },
      };
      let imgUrl = API + `common/image`;
      axios
        .post(imgUrl, formData, config2)
        .then(async (response) => {
          if (response?.data?.success) {
            let image_path = response?.data?.data?.files[0]?.key;
            // STORE PROFILE UPDATE API
            const body = {
              shop_name: storeUpdateData?.shop_name,
              phoneNumber: storeUpdateData?.phoneNumber,
              description: storeUpdateData?.description,
              address: storeUpdateData?.address,
              image: image_path,
            };
            await axios
              .patch(URL, body, config)
              .then((response) => {
                if (response?.data?.success) {
                  toast.success(`Your Store Profile Updated Successfully.`);
                  fetchStoreDetails();
                  onLinkUpdate(storeUpdateData?.shop_name, image_path);
                } else {
                  toast.warn(`Error while updating store profile.`);
                }
              })
              .catch((error) => {
                console.log(error);
              });
            // STORE PROFILE UPDATE API END
          } else {
            toast.warning("Image Uploading Fail Try Again.");
          }
        })
        .catch((err) => {
          console.log("error here", err.response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  //ADD OR UPDATE STORE PAYMENT QR IMAGE
  const onUpdatePaymentQr = (link: string) => {
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    //PAYMENT QR IMAGE BODY
    const body = {
      storeId: authData?.storeId,
      paymentQrImage: link,
    };
    //URL
    let url = API + `store/paymentQr`;
    axios
      .patch(url, body, config)
      .then(async (response) => {
        if (response?.data?.success) {
          toast.success(response?.data?.message);
          fetchStoreDetails();
        } else {
          toast.warn(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log("error here", err.response);
      })
      .finally(() => {});
  };

  //OPEN BANK DETAILS MODAL
  const openBankDetailModal = (status?: any) => {
    setIsOpenBank(!status || true);
  };

  //CLOSE BANK DETAIL MODEL
  const onCloseBankModel = () => {
    setIsOpenBank(false);
    fetchStoreBankDetails();
  };

  //CLOSE PETPOOJA DETAIL MODEL
  const onClosePetpoojaModel = () => {
    setIsPetpoojaIntegration(false);
    fetchStoreDetails();
  };

  //OPEN STORE TIME UPDATE / ADD MODEL
  const onOpenTimeModel = (status?: any) => {
    setIsOpen(!status || true);
  };

  //DELETE STORE
  const onDeleteStore = (id: any) => {
    //AUTH TOKEN
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };

    let URL = API + `deleteStore/${id}`;
    axios
      .delete(URL, config)
      .then((response) => {
        if (response?.data?.success) {
          //IF STORE DELETE SUCC REMOVE STORE ID FORM LOCATSTORAGE
          authData.storeId = null;
          localStorage.setItem("jd-u", JSON.stringify(authData));
          navigate(`/profile`);
        } else {
          toast.warn(`Error while deleteing store.`);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  //OPEN / CLOSE PAYMENT QR MODEL
  const onPaymentQR = (status: boolean) => {
    setIsPaymentQrModel(status);
  };

  React.useEffect(() => {
    fetchStoreDetails();
    fetchStoreBankDetails();
  }, [fetchStoreDetails, fetchStoreBankDetails]);

  return (
    <>
      <DashboardHeader title={"Profile"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <h2 className="mb-3 hidden text-xl font-bold md:block">Profile</h2>
        <div className="flex gap-10">
          {storeData?.razorpay_acc === null && (
            <div className="mb-4 flex justify-end">
              <button
                className="w-fit select-none rounded-md border border-gold bg-gold py-2 px-5 font-medium capitalize text-raisin-black"
                onClick={() => openBankDetailModal()}
              >
                Activate your Bank details
              </button>
            </div>
          )}
          {storeData?.posIntegration_id === null && (
            <div className="mb-4 flex justify-end">
              <button
                className="w-fit select-none rounded-md border border-gold bg-gold py-2 px-5 font-medium capitalize text-raisin-black"
                onClick={() => setIsPetpoojaIntegration(true)}
              >
                Activate POS
              </button>
            </div>
          )}
        </div>

        {bankDetail !== null && (
          <AccountStatus
            onOpen={openBankDetailModal}
            bankDetail={bankDetail}
            onLoading={accountStatusLoading}
          />
        )}

        {storeData?.posIntegration_id !== null && (
          <PetpoojaIntegration
            storeDetails={storeData}
            onLoading={storeStatusLoading}
            onModalOpen={() => setIsPetpoojaIntegration(true)}
          />
        )}

        <ProfileDetails
          userShopDetails={storeData}
          onOpenTiming={onOpenTimeModel}
          onUpdateStore={onUpdateStore}
          storeDetails={storeData}
          dataLoading={isLoading}
          onLoading={storeStatusLoading}
          onDeleteStore={onDeleteStore}
          openPaymentQr={onPaymentQR}
        />
      </section>
      <ShopTiming
        onOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onStoreData={storeData}
      />
      <BankDetail
        onOpen={isOpenBank}
        onClose={onCloseBankModel}
        onStoreData={storeData}
      />
      <PetpoojaIntegrationModel
        onOpen={isPetpoojaIntegration}
        onClose={onClosePetpoojaModel}
      />

      <PaymentQrModel
        onOpen={isPaymentQrModel}
        storeData={storeData}
        updatePaymrntQr={onUpdatePaymentQr}
        onClose={() => setIsPaymentQrModel(false)}
      />
    </>
  );
};

export default ShopProfile;
