import React from "react";

const TwitterIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M65.1097 77.9873C48.3462 79.7765 31.5319 79.7765 14.7683 77.9873C8.08399 77.2739 2.80432 71.9942 2.09092 65.3093C0.301725 48.5458 0.301725 31.732 2.09092 14.9685C2.80432 8.28419 8.08399 3.00452 14.7683 2.29111C31.5319 0.501921 48.3456 0.501921 65.1091 2.29111C71.7941 3.00452 77.0737 8.28419 77.7871 14.9685C79.5763 31.732 79.5763 48.5458 77.7871 65.3093C77.0737 71.9942 71.7946 77.2739 65.1097 77.9873Z"
        fill="#2AA9E0"
      />
      <path
        d="M65.5903 26.3367C63.7815 27.138 61.8373 27.6794 59.7971 27.9229C61.8795 26.6762 63.4791 24.7022 64.2321 22.3498C62.2829 23.5044 60.1243 24.3425 57.8266 24.7942C55.9868 22.8364 53.3653 21.6133 50.464 21.6133C44.8935 21.6133 40.3769 26.1234 40.3769 31.6864C40.3769 32.476 40.4662 33.2449 40.6382 33.9821C32.255 33.5621 24.8226 29.5516 19.8475 23.457C18.9793 24.9447 18.4817 26.675 18.4817 28.5212C18.4817 32.0162 20.2625 35.0994 22.969 36.9058C21.3156 36.8535 19.7602 36.4003 18.4003 35.6459C18.3992 35.6879 18.3992 35.7301 18.3992 35.7726C18.3992 40.6531 21.8762 44.7243 26.4906 45.6503C25.6442 45.8806 24.7531 46.0036 23.8332 46.0036C23.1832 46.0036 22.5515 45.9402 21.9354 45.8229C23.2191 49.8248 26.9442 52.7372 31.3583 52.8182C27.9061 55.5202 23.5569 57.1307 18.8309 57.1307C18.0167 57.1307 17.2138 57.0828 16.4247 56.9898C20.8887 59.8481 26.1907 61.5158 31.8871 61.5158C50.4405 61.5158 60.5866 46.1663 60.5866 32.8543C60.5866 32.4175 60.5767 31.9832 60.5573 31.5509C62.5279 30.1309 64.2379 28.3569 65.5902 26.3367H65.5903Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterIcon;
