import React from "react";
import { ShopType } from "types";
import { IMAGE_URL } from "config/Api";
import StarRatings from "react-star-ratings";
import { PencilIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { Button, HeroIcon, ToggleButton } from "components/ui";

interface Props {
  userShopDetails: ShopType;
  onOpenTiming: any;
  onUpdateStore: any;
  storeDetails: any;
  onLoading: Boolean;
  dataLoading: Boolean;
  onDeleteStore: any;
  openPaymentQr: any;
}

const ProfileDetails: React.FC<Props> = ({
  userShopDetails,
  onOpenTiming,
  onUpdateStore,
  storeDetails,
  onLoading,
  dataLoading,
  onDeleteStore,
  openPaymentQr,
}) => {
  const openTimingHandle = onOpenTiming;
  const openPaymentQrHandle = openPaymentQr;
  const onUpdateStoreHandle = onUpdateStore;
  const onDeleteHandle = onDeleteStore;
  const [imgPath, setImgPath] = React.useState<any>([]);
  const [selectedImage, setSelectedImage] = React.useState<any>([]);
  const [storeData, setStoreData] = React.useState<any>(null);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);

  //ADD IMAGE ARRAY FORM
  const addImage = (event: any) => {
    const reader = new FileReader(),
      filesToDisplay = event.target.files;
    reader.onload = function () {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(filesToDisplay[0]);
    event.preventDefault();
    const files = event.target.files;
    const tempImages = [...imgPath] as any[];

    for (let index = 0; index < files.length; index++) {
      const file = files[index] as any[];
      const duplicate = false;
      if (!duplicate) tempImages.push(file);
    }
    setImgPath([...tempImages]);
  };

  //CLEAR SELECT IMAGE
  const onClearImage = () => {
    setSelectedImage([]);
    setImgPath([]);
  };

  React.useEffect(() => {
    setStoreData(storeDetails);
    setSelectedImage(`${IMAGE_URL}${storeDetails?.image}`);
  }, [storeDetails]);

  if (onLoading) {
    return (
      <>
        <div className="w-full animate-pulse">
          <div className="rounded-2xl bg-cultured p-5 md:p-12">
            <div className="items-center lg:flex">
              <div className="mr-2 h-40 w-40 rounded-full bg-black/20" />
              <div className="mt-5 w-full max-w-lg space-y-2 lg:mt-0 lg:ml-5">
                <div className="h-5 w-40 rounded bg-black/20" />
                <div className="h-2 w-full rounded bg-black/20" />
                <div className="h-2 w-5/6 rounded bg-black/20" />
                <div className="h-2 w-4/5 rounded bg-black/20" />
                <div className="h-2 w-2/3 rounded bg-black/20" />
              </div>
            </div>

            <div className="my-5 grid w-full grid-cols-1 space-y-4 md:grid-cols-3 md:space-x-10 md:space-y-0">
              <div className="h-8 rounded-md bg-black/20" />
              <div className="h-8 rounded-md bg-black/20" />
              <div className="h-8 rounded-md bg-black/20" />
            </div>

            <div className="my-5 grid grid-cols-1 space-y-4 md:grid-cols-2 md:space-x-10 md:space-y-0">
              <div className="h-28 rounded-md bg-black/20" />
              <div className="h-28 rounded-md bg-black/20" />
            </div>

            <div className="my-5 grid w-full grid-cols-1 space-y-4 md:grid-cols-3 md:space-x-10 md:space-y-0">
              <div className="h-8 rounded-md bg-black/20" />
              <div className="h-8 rounded-md bg-black/20" />
              <div className="h-8 rounded-md bg-black/20" />
            </div>

            <div className="mt-10 h-10 w-40 rounded-md bg-black/20" />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="rounded-2xl bg-cultured p-5 md:p-12">
          <div className="items-center lg:flex">
            {selectedImage.length === 0 ? (
              <div className="border-sky-500 mb-7 flex h-40 w-40 items-center justify-center rounded-full border-2 border-dashed border-gray/40">
                <label
                  htmlFor="product_image"
                  className="cursor-pointer rounded-full bg-white"
                >
                  <HeroIcon
                    className="h-10 w-10 p-2 text-philippine-gray"
                    icon="PlusIcon"
                  />
                  <input
                    className="hidden"
                    type="file"
                    id="product_image"
                    accept="image/*"
                    onChange={addImage}
                  />
                </label>
              </div>
            ) : (
              <div className="relative h-40 w-40">
                <div
                  className="absolute bottom-2 right-2 cursor-pointer rounded-full bg-white p-2"
                  onClick={onClearImage}
                >
                  <PencilIcon className="h-4 w-4 text-gray" />
                </div>
                {selectedImage === "shop.png" ? (
                  <img
                    src={`/assets/icons/social/${`v1`}/${`default`}/${
                      userShopDetails?.image
                    }`.replace(".png", ".svg")}
                    alt={`${userShopDetails?.shop_name}`}
                    className="mr-2 h-full w-full object-cover"
                  />
                ) : (
                  <img
                    className="h-full w-full rounded-full object-cover"
                    src={selectedImage}
                    alt=""
                  />
                )}
              </div>
            )}
            <div className="mt-5 max-w-lg lg:mt-0 lg:ml-5">
              <h2 className="mb-1 text-2xl font-medium">
                {storeData?.shop_name}
              </h2>
              <p className="text-sm font-normal text-gray">
                {storeData?.description}
              </p>
            </div>
          </div>

          <div className="my-5 grid grid-cols-1 space-y-4 md:grid-cols-3 md:space-x-10 md:space-y-0">
            <div>
              <label className="text-md font-bold" htmlFor="shop_name">
                Shop Name
              </label>
              <input
                className="mt-2 w-full rounded-md border-0"
                type="text"
                id="shop_name"
                placeholder="Shop Name"
                value={storeData?.shop_name}
                onChange={(e) => {
                  setStoreData({
                    ...storeData,
                    shop_name: e.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label className="text-md font-bold" htmlFor="shop_number">
                Phone Number
              </label>
              <input
                className="mt-2 w-full rounded-md border-0"
                type="number"
                id="phone_number"
                placeholder="phone Number"
                value={storeData?.phoneNumber}
                onChange={(e) => {
                  setStoreData({
                    ...storeData,
                    phoneNumber: e.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label className="text-md font-bold" htmlFor="razorpay_account">
                Razorpay Account
              </label>
              <input
                className="mt-2 w-full rounded-md border-0 text-gray"
                type="text"
                id="razorpay_account"
                placeholder="Your Acount Deatil"
                defaultValue={storeData?.razorpay_acc}
                disabled
              />
            </div>
          </div>

          <div className="my-5 grid grid-cols-1 space-y-4 md:grid-cols-2 md:space-x-10 md:space-y-0">
            <div>
              <label className="text-md font-bold" htmlFor="shop_description">
                Shop Description
              </label>
              <textarea
                className="mt-2 h-28 w-full rounded-md border-0"
                id="shop_description"
                placeholder="Shop Description"
                value={storeData?.description}
                onChange={(e) => {
                  setStoreData({
                    ...storeData,
                    description: e.target.value,
                  });
                }}
              ></textarea>
            </div>

            <div>
              <label className="text-md font-bold" htmlFor="shop_address">
                Shop Address
              </label>
              <textarea
                className="mt-2 h-28 w-full rounded-md border-0"
                id="shop_address"
                placeholder="Shop Address"
                value={storeData?.address}
                onChange={(e) => {
                  setStoreData({
                    ...storeData,
                    address: e.target.value,
                  });
                }}
              ></textarea>
            </div>
          </div>

          <div className="my-5 grid grid-cols-1 space-y-4 md:grid-cols-4 md:space-x-10 md:space-y-0">
            <div>
              <label className="text-md font-bold" htmlFor="shop_name">
                Ratings
              </label>
              <div className="mt-2 flex w-full justify-between rounded-md border-0 bg-white p-2">
                <StarRatings
                  rating={storeData?.rating}
                  starDimension="20px"
                  starRatedColor="rgb(255, 215, 0)"
                  svgIconViewBox="0 0 576 512"
                  svgIconPath="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                  numberOfStars={5}
                  name="rating"
                />
                <p className="cursor-pointer font-medium text-gray">
                  {storeData?.rating}
                </p>
              </div>
            </div>

            <div>
              <label className="text-md font-bold" htmlFor="commission_value">
                Product Filter Option
              </label>
              <div className="mt-2 flex w-full justify-between rounded-md border-0 bg-transparent p-2">
                <ToggleButton
                  checked={storeData?.isFilterProvide}
                  onClick={() => {
                    setStoreData({
                      ...storeData,
                      isFilterProvide: !storeData?.isFilterProvide,
                    });
                  }}
                />
              </div>
            </div>

            <div>
              <label className="text-md font-bold" htmlFor="razorpay_account">
                &nbsp;
              </label>
              <div className="mt-2 flex w-full justify-between rounded-md border-0 bg-white p-2">
                <h2 className="font-bold ">Opening Hours</h2>
                <p
                  className="cursor-pointer font-medium text-[#007DE5]"
                  onClick={() => openTimingHandle(!onOpenTiming)}
                >
                  Edit
                </p>
              </div>
            </div>

            <div>
              <label className="text-md font-bold" htmlFor="payment_qr">
                &nbsp;
              </label>
              <div className="mt-2 flex w-full justify-between rounded-md border-0 bg-white p-2">
                <h2 className="font-bold ">Payment QR</h2>
                <p
                  className="cursor-pointer font-medium text-[#007DE5]"
                  onClick={() => openPaymentQrHandle(true)}
                >
                  Edit
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-10">
            <Button
              loading={dataLoading}
              onClick={() => onUpdateStoreHandle(storeData, imgPath)}
            >
              Save
            </Button>
            <Button
              variant={"outlined"}
              // loading={dataLoading}
              onClick={() => setIsDeleteModal(true)}
            >
              Delete Shop
            </Button>
          </div>
        </div>

        <Transition appear show={isDeleteModal} as={React.Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setIsDeleteModal(false)}
          >
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-gray-900 text-lg font-medium leading-6"
                    >
                      Confirm Delete
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-gray-500 text-sm">
                        Are you sure you want to Delete?
                      </p>
                    </div>

                    <div className="mt-4 flex space-x-3">
                      <button
                        type="button"
                        className="inline-flex w-24 select-none items-center justify-center rounded-md border border-gold bg-gold py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                        onClick={() => onDeleteHandle(userShopDetails?._id)}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-24 select-none items-center justify-center rounded-md border border-raisin-black bg-transparent py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                        onClick={() => setIsDeleteModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  }
};

export default ProfileDetails;
