import React from "react";
import axios from "axios";
import API from "config/Api";
import { ShopType } from "types";
import { AppCtx } from "data/context";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import AddBanner from "components/shops/banner/AddBanner";
import BannerBox from "components/shops/banner/BannerBox";
import DashboardHeader from "components/common/Sidebar/DashboardHeader";

interface Props {}

const Banner: React.FC<Props> = () => {
  const { setShopUserDataGet } = React.useContext(AppCtx);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [productDeleteId, setProductDeleteId] = React.useState("");
  const [shopDetails, setShopDetails] = React.useState<ShopType>({});

  function openDeleteModal(id: any) {
    setIsDeleteModal(true);
    setProductDeleteId(id);
  }

  function closeDeleteModal() {
    setIsDeleteModal(false);
    setProductDeleteId("");
  }

  const onFetchLatestShopDetails = () => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const url = API + `getShop`;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.success) {
          setShopDetails(response.data.data);
          localStorage.setItem("jd-u-shop", JSON.stringify(response.data.data));
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  const onDeleteBanner = () => {
    const authData = JSON.parse(localStorage.getItem("jd-u")!);

    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    const body = {
      bannerId: shopDetails?.banner_id?._id,
      image_id: productDeleteId,
    };

    const url = API + `deleteBanner`;
    axios
      .post(url, body, config)
      .then((response) => {
        if (response?.data?.success) {
          onFetchLatestShopDetails();
          setShopUserDataGet(true);
          closeDeleteModal();
          toast.success(`Banner Deleted Successfully.`);
        }
      })
      .catch((err) => {
        console.log("error here", err);
      });
  };

  React.useEffect(() => {
    const shopData = JSON.parse(localStorage.getItem("jd-u-shop")!);
    setShopDetails(shopData);
  }, []);

  return (
    <>
      <DashboardHeader title={"Banner"} />
      <section className="3xl:mx-w-[110rem] mx-auto max-w-2xl py-10 px-5 md:max-w-4xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-[100rem]">
        <h2 className="mb-3 hidden text-xl font-bold md:block">Banners</h2>
        <div className="mb-10 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <AddBanner userShopDetails={shopDetails} />
          {shopDetails?.banner_id?.bannerImage.map((items, index) => {
            return (
              <BannerBox
                banner={items}
                key={index}
                onDelete={openDeleteModal}
                icon={true}
              />
            );
          })}
        </div>
      </section>

      <Transition appear show={isDeleteModal} as={React.Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeDeleteModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 text-lg font-medium leading-6"
                  >
                    Confirm Delete
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-gray-500 text-sm">
                      Are you sure you want to Delete?
                    </p>
                  </div>

                  <div className="mt-4 flex space-x-3">
                    <button
                      type="button"
                      className="inline-flex w-24 select-none items-center justify-center rounded-md border border-gold bg-gold py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                      onClick={onDeleteBanner}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-24 select-none items-center justify-center rounded-md border border-raisin-black bg-transparent py-2 text-base font-medium leading-6 text-raisin-black shadow transition duration-150 ease-in-out"
                      onClick={closeDeleteModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Banner;
