import React from "react";

const LinkedinIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    >
      <path
        d="M65.1097 77.7432C48.3462 79.5324 31.5319 79.5324 14.7683 77.7432C8.08399 77.0298 2.80432 71.7501 2.09092 65.0652C0.301725 48.3016 0.301725 31.4879 2.09092 14.7244C2.80432 8.04005 8.08399 2.76038 14.7683 2.04697C31.5319 0.25778 48.3456 0.25778 65.1091 2.04697C71.7941 2.76038 77.0737 8.04005 77.7871 14.7244C79.5763 31.4879 79.5763 48.3016 77.7871 65.0652C77.0737 71.7501 71.7946 77.0298 65.1097 77.7432Z"
        fill="#0A66C2"
      />
      <path
        d="M27.3345 64.5269C27.3345 65.4838 26.5646 66.2593 25.6148 66.2593H18.2944C17.3446 66.2593 16.5746 65.4838 16.5746 64.5269V33.6139C16.5746 32.6571 17.3446 31.8815 18.2944 31.8815H25.6148C26.5646 31.8815 27.3345 32.6571 27.3345 33.6139V64.5269ZM21.9546 28.9675C18.1138 28.9675 15.0001 25.8309 15.0001 21.9618C15.0001 18.0927 18.1138 14.9561 21.9546 14.9561C25.7954 14.9561 28.909 18.0927 28.909 21.9618C28.909 25.8309 25.7956 28.9675 21.9546 28.9675ZM65.5908 64.6665C65.5908 65.5461 64.8828 66.2593 64.0096 66.2593H56.1543C55.2811 66.2593 54.5731 65.5461 54.5731 64.6665V50.1665C54.5731 48.0034 55.2029 40.6878 48.9616 40.6878C44.1204 40.6878 43.1384 45.6951 42.9412 47.9423V64.6665C42.9412 65.5461 42.2334 66.2593 41.36 66.2593H33.7626C32.8894 66.2593 32.1814 65.5461 32.1814 64.6665V33.4743C32.1814 32.5947 32.8894 31.8815 33.7626 31.8815H41.36C42.2332 31.8815 42.9412 32.5947 42.9412 33.4743V36.1713C44.7364 33.4575 47.4042 31.3628 53.0844 31.3628C65.6628 31.3628 65.5908 43.2008 65.5908 49.7052V64.6665Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedinIcon;
