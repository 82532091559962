import React from "react";
import { useNavigate } from "react-router-dom";
import { ShopOrderObject } from "types";

interface Props {
  ordersDetails: ShopOrderObject | null;
}

const BottomOrderTab: React.FC<Props> = ({ ordersDetails }) => {
  const navigate = useNavigate();
  return (
    <div className="absolute bottom-16 mx-auto w-full text-sm">
      <div className="flex justify-center">
        <div className="flex w-5/6 items-center justify-between rounded-lg border border-white/40 bg-gold p-2 px-4 font-bold text-raisin-black shadow-xl">
          <div>
            <h2>
              {ordersDetails?.totalOrder} Item{" "}
              <span className="text-lg font-extrabold">|</span> ₹{" "}
              {ordersDetails?.totalPrice}
            </h2>
            <p className="text-xs">Extra charges may apply</p>
          </div>
          <div
            className="cursor-pointer capitalize"
            onClick={() => navigate(`/shop/view_order`)}
          >
            View Orders
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomOrderTab;
