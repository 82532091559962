import axios from "axios";
import API from "config/Api";
import { toast } from "react-toastify";
import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button, Separate, ToggleButton } from "components/ui";

interface Props {
  onOpen: any;
  onClose: any;
  onStoreData: any;
}

const ShopTiming: React.FC<Props> = ({ onOpen, onClose, onStoreData }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const closeHandler = onClose;
  const [shopTimes, setShopTimes] = React.useState<any[]>([]);

  const onStartTimeChange = (e: any, index: number) => {
    shopTimes[index].startTime = e;
    setShopTimes([...shopTimes]);
  };

  const onEndTimeChange = (e: any, index: number) => {
    shopTimes[index].endTime = e;
    setShopTimes([...shopTimes]);
  };

  const onIsOpen = (index: number) => {
    shopTimes[index].isOpen = !shopTimes[index].isOpen;
    setShopTimes([...shopTimes]);
  };

  const onSubmit = () => {
    setIsLoading(true);
    const authData = JSON.parse(localStorage.getItem("jd-u")!);
    const config = {
      headers: {
        Authorization: `Bearer ${authData?.token}`,
      },
    };
    let URL = API + `edit/${onStoreData?._id}`;
    const body = {
      open_hours: shopTimes,
    };
    axios
      .patch(URL, body, config)
      .then((response) => {
        if (response?.data?.success) {
          toast.success(`Your Store Profile Updated Successfully.`);
          closeHandler();
        } else {
          toast.warn(`Error while updating store profile.`);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (onStoreData?.open_hours?.length > 0) {
      setShopTimes([...onStoreData?.open_hours]);
    } else {
      setShopTimes([
        {
          weekDay: "Sunday",
          isOpen: false,
          startTime: "09:00",
          endTime: "23:00",
        },
        {
          weekDay: "Monday",
          isOpen: true,
          startTime: "09:00",
          endTime: "23:00",
        },
        {
          weekDay: "Tuesday",
          isOpen: true,
          startTime: "09:00",
          endTime: "23:00",
        },
        {
          weekDay: "Wednesday",
          isOpen: true,
          startTime: "09:00",
          endTime: "23:00",
        },
        {
          weekDay: "Thursday",
          isOpen: true,
          startTime: "09:00",
          endTime: "23:00",
        },
        {
          weekDay: "Friday",
          isOpen: true,
          startTime: "09:00",
          endTime: "23:00",
        },
        {
          weekDay: "Saturday",
          isOpen: true,
          startTime: "09:00",
          endTime: "23:00",
        },
      ]);
    }
  }, [onStoreData?.open_hours?.length, onStoreData?.open_hours]);

  return (
    <>
      <Transition appear show={onOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeHandler}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-gray-900 pb-4 text-center text-xl font-medium leading-6"
                  >
                    Shop Opening Hours
                  </Dialog.Title>
                  <Separate dark={true} />

                  {shopTimes.map((list, index) => {
                    return (
                      <div className="my-5 px-5" key={index}>
                        <div className="grid grid-cols-2 space-y-2 space-x-5 md:grid-cols-4 md:space-y-0 md:space-x-3">
                          <div className="flex items-center">
                            <h2 className="text-lg font-bold">
                              {list?.weekDay}
                            </h2>
                          </div>
                          <div className="flex items-center">
                            <ToggleButton
                              checked={list?.isOpen}
                              onClick={() => onIsOpen(index)}
                            />
                            <h3 className="ml-2 font-medium">
                              {list?.isOpen ? "Open" : "Close"}
                            </h3>
                          </div>
                          <div>
                            <input
                              className={
                                list.isOpen
                                  ? "w-full rounded-md border-0 bg-cultured"
                                  : "w-full rounded-md border-0 bg-cultured opacity-20"
                              }
                              disabled={!list.isOpen}
                              type="time"
                              name="starting_time"
                              min="09:00"
                              max="18:00"
                              defaultValue={list?.startTime}
                              onChange={(e) =>
                                onStartTimeChange(e.target.value, index)
                              }
                            />
                          </div>
                          <div>
                            <input
                              className={
                                list.isOpen
                                  ? "w-full rounded-md border-0 bg-cultured"
                                  : "w-full rounded-md border-0 bg-cultured opacity-20"
                              }
                              disabled={!list.isOpen}
                              type="time"
                              name="ending_time"
                              defaultValue={list?.endTime}
                              onChange={(e) =>
                                onEndTimeChange(e.target.value, index)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="mx-auto mt-10 grid max-w-xs grid-cols-2">
                    <Button variant="outlined" onClick={closeHandler}>
                      Cancel
                    </Button>
                    <Button loading={isLoading} onClick={onSubmit}>
                      Submit
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ShopTiming;
